import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import { colors } from "../../../app/theme/colors";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { getCreatedCompaniesCountStatistic, selectCountStatistic } from "../../../slice/companySlice";
import { useEffect, useState } from "react";
import { CountStatistic } from "../../../models/companies/countStatistic";

const monthNames = [
  'Januar',
  'Februar',
  'Mart',
  'April',
  'Maj',
  'Jun',
  'Jul',
  'Avgust',
  'Septembar',
  'Oktobar',
  'Novembar',
  'Decembar',
];

export default function ChartMemberAgeStatistic() {
  const dispatch = useAppDispatch();
  const countStatistic = useAppSelector(selectCountStatistic);
  const [chart, setChart] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getCreatedCompaniesCountStatistic());
  }, []);

  useEffect(() => {
    if (!countStatistic) {
      return;
    }

    const mappedCountStatistics = countStatistic.map((x) => ({
      ...x,
      monthName: monthNames[x.monthNumber - 1],
    }));

    setChart([...mappedCountStatistics]);
  }, [countStatistic]);

  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          width={500}
          height={300}
          data={chart}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="monthName" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="createdCompanies"
            stroke={colors.green}
            activeDot={{ r: 8 }}
            name="Kreiranih firmi"
          />
          {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}
