import { Container, Grid } from "@mui/material";
import LoginForm from "../../features/loginForm/loginForm";

export default function LoginPage() {
  return (
    <Container
      maxWidth="xl"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}>
      <Grid item md={12} xs={12}>
        <LoginForm />
      </Grid>
    </Container>
  );
}
