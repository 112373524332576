import { BrowserRouter } from "react-router-dom";
import AppRouter from "./app/router/appRouter";
import Layout from "./app/theme/layout";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <AppRouter />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
