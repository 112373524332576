import { User } from "../../models/users/user";
import { EmptyGuid } from "../stepper/initialData";

export const INIT_USER: User = {
    id: EmptyGuid,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    roleName: "Administrator",
    rolePriority: 3,
    isMidenas: null,
    isActive: true,
    isDeleted: false,
    assosiationId: EmptyGuid
};