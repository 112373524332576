import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import RemoveCircleOutlineTwoToneIcon from "@mui/icons-material/RemoveCircleOutlineTwoTone";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from "@mui/material";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { routes } from "../../../app/router/routes";
import { colors } from "../../../app/theme/colors";
import { IsEmailValid } from "../../../common/validations/commonValidations";
import { IsPasswordValid, IsResetPasswordValid } from "../../../common/validations/userValidations";
import AppButton from "../../../components/appButton/appButton";
import BasicInput from "../../../components/basicInput/basicInput";
import { ApiStatus } from "../../../models/app/apiStatus";
import { LoginRequest } from "../../../models/auth/loginRequest";
import { ResetPasswordModel } from "../../../models/users/resetPasswordModel";
import { login } from "../../../slice/authSlice";
import {
    resetPassword as resetPasswordRequest,
    selectResetPasswordRequestStatus
} from "../../../slice/userSlice";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    user: LoginRequest;
};

interface PasswordValidation {
    greaterThanOrEqualTo8: boolean;
    atLeastOneUpperCase: boolean;
    atLeastOneNumber: boolean;
    atLeastOneSpecialCharacter: boolean;
    passwordsAreSame: boolean;
}

const INIT_RESET_PASSWORD: ResetPasswordModel = {
    oldPassword: "",
    newPassword: "",
    email: ""
};
const INIT_VALIDATE: boolean = false;
const INIT_REPEATED_PASSWORD: string = "";

export default function ResetPasswordModal({ isOpen, onClose, user }: Props) {
    const dispatch = useAppDispatch();
    const resetPasswordRequestStatus = useAppSelector(selectResetPasswordRequestStatus);
    const navigate = useNavigate();
    const [resetPassword, setResetPassword] = useState<ResetPasswordModel>({
        ...INIT_RESET_PASSWORD,
        email: user.email,
        oldPassword: user.password
    });
    const [validate, setValidate] = useState<boolean>(INIT_VALIDATE);
    const [repeatedPassword, setRepeatedPassword] = useState<string>(INIT_REPEATED_PASSWORD);

    const passwordValidation: PasswordValidation = {
        greaterThanOrEqualTo8: resetPassword.newPassword.length >= 8,
        atLeastOneUpperCase: /[A-Z]/.test(resetPassword.newPassword),
        atLeastOneNumber: /\d/.test(resetPassword.newPassword),
        atLeastOneSpecialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(resetPassword.newPassword),
        passwordsAreSame: resetPassword.newPassword.length > 0 && repeatedPassword.length > 0
            && resetPassword.newPassword === repeatedPassword
    };

    const onChangeResetPassword = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        propName: keyof ResetPasswordModel
    ) => {
        let { value } = e.target;
        value = value.trim();
        setResetPassword({ ...resetPassword, [propName]: value });
    };

    const onChangeRepeatedPassword = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        let { value } = e.target;
        value = value.trim();

        setRepeatedPassword(value);
    };

    const onSubmit = async () => {
        const isValid = IsResetPasswordValid(resetPassword, repeatedPassword)
            && resetPasswordRequestStatus !== ApiStatus.Pending
            && Object.values(passwordValidation).every(x => x === true);

        if (!isValid) {
            setValidate(true);
            return;
        }

        const request: ResetPasswordModel = {
            email: resetPassword.email,
            oldPassword: resetPassword.oldPassword,
            newPassword: resetPassword.newPassword
        };

        const resetPasswordResponse = await dispatch(resetPasswordRequest(request));

        if (!resetPasswordResponse.payload) {
            return;
        }

        const loginRequest: LoginRequest = {
            email: resetPassword.email,
            password: resetPassword.newPassword
        };

        const loginResponse = await dispatch(login(loginRequest));

        if (loginResponse.payload) {
            navigate(routes.dashboard);
        }

        onClear();
        onClose();
    };

    const onEnterPressed = async (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            await onSubmit();
        }
    }

    const onClear = () => {
        setResetPassword(INIT_RESET_PASSWORD);
        setValidate(INIT_VALIDATE);
    };

    useEffect(() => {
        setResetPassword({
            ...resetPassword,
            email: user.email,
            oldPassword: user.password
        });
    }, [user]);

    return (
        <>
            <Dialog onClose={onClose} open={isOpen} maxWidth="xs" fullWidth>
                <DialogTitle sx={{ textAlign: "center" }}>
                    {"Promjena lozinke"}
                </DialogTitle>

                <DialogContent>
                    <Box my={2}>
                        <BasicInput
                            type="text"
                            label="E-mail adresa"
                            value={resetPassword.email}
                            onKeyDown={onEnterPressed}
                            error={validate && !IsEmailValid(resetPassword.email)}
                            errorMessage={"E-mail adresa nije validna."}
                            disabled
                            readOnly
                            boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.3)"}
                            multiline={false}
                        />
                    </Box>

                    <Box my={2}>
                        <BasicInput
                            type="password"
                            label="Trenutna lozinka"
                            value={resetPassword.oldPassword}
                            onKeyDown={onEnterPressed}
                            error={validate && !IsPasswordValid(resetPassword.oldPassword)}
                            errorMessage={"Trenutna lozinka je obavezna."}
                            disabled
                            readOnly
                            boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.3)"}
                            multiline={false}
                        />
                    </Box>

                    <Box my={2}>
                        <BasicInput
                            type="password"
                            label="Nova lozinka"
                            value={resetPassword.newPassword}
                            onChange={(e) => onChangeResetPassword(e, "newPassword")}
                            onKeyDown={onEnterPressed}
                            error={validate && !IsPasswordValid(resetPassword.newPassword)}
                            errorMessage={"Nova lozinka je obavezna."}
                            boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.3)"}
                            multiline={false}
                        />
                    </Box>

                    <Box my={2}>
                        <BasicInput
                            type="password"
                            label="Ponovite lozinku"
                            value={repeatedPassword}
                            onChange={onChangeRepeatedPassword}
                            onKeyDown={onEnterPressed}
                            error={validate && (!IsPasswordValid(repeatedPassword)
                                || resetPassword.newPassword !== repeatedPassword)}
                            errorMessage={!IsPasswordValid(repeatedPassword)
                                ? "Ponovljena lozinka je obavezna."
                                : "Lozinke nisu iste."}
                            boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.3)"}
                            multiline={false}
                        />
                    </Box>

                    <Box my={2}>
                        {<Typography
                            variant="body1"
                            sx={{ color: passwordValidation.greaterThanOrEqualTo8 ? colors.green : colors.validationErrorMessage, display: "flex", my: 1 }}
                        >
                            {passwordValidation.greaterThanOrEqualTo8
                                ? <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                                : <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                            }
                            Lozinka mora imati minimum 8 karaktera.
                        </Typography>}

                        {<Typography
                            variant="body1"
                            sx={{ color: passwordValidation.atLeastOneUpperCase ? colors.green : colors.validationErrorMessage, display: "flex", my: 1 }}
                        >
                            {passwordValidation.atLeastOneUpperCase
                                ? <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                                : <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                            }
                            Lozinka mora imati barem jedno veliko slovo.
                        </Typography>}

                        {<Typography
                            variant="body1"
                            sx={{ color: passwordValidation.atLeastOneNumber ? colors.green : colors.validationErrorMessage, display: "flex", my: 1 }}
                        >
                            {passwordValidation.atLeastOneNumber
                                ? <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                                : <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                            }
                            Lozinka mora imati barem jedan broj.
                        </Typography>}

                        {<Typography
                            variant="body1"
                            sx={{ color: passwordValidation.atLeastOneSpecialCharacter ? colors.green : colors.validationErrorMessage, display: "flex", my: 1 }}
                        >
                            {passwordValidation.atLeastOneSpecialCharacter
                                ? <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                                : <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                            }
                            Lozinka mora imati barem jedan specijalan karakter.
                        </Typography>}

                        {<Typography
                            variant="body1"
                            sx={{ color: passwordValidation.passwordsAreSame ? colors.green : colors.validationErrorMessage, display: "flex", my: 1 }}
                        >
                            {passwordValidation.passwordsAreSame
                                ? <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                                : <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                            }
                            Lozinke se moraju poklapati.
                        </Typography>}
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Grid container justifyContent="space-between" mx={1}>
                        <Grid item>
                            <AppButton
                                onClick={onClear}
                                color={colors.sectionTitle}
                                hover={colors.sectionTitle}
                                label={"Očistite polja"}
                            />
                        </Grid>

                        <Grid item>
                            <Grid container columnSpacing={2}>
                                <Grid item>
                                    <AppButton
                                        onClick={onClose}
                                        color={colors.primary}
                                        hover={colors.primary}
                                        label={"Zatvorite"}
                                    />
                                </Grid>
                                <Grid item>
                                    <AppButton
                                        onClick={onSubmit}
                                        label={"Promijenite lozinku"}
                                        color={colors.green}
                                        hover={colors.green}
                                        disabled={(validate && (!IsResetPasswordValid(resetPassword, repeatedPassword)
                                            || resetPasswordRequestStatus === ApiStatus.Pending))
                                            || Object.values(passwordValidation).some(x => x === false)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};