import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "rs",
    lng: "rs",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {},
      },
      rs: {
        translation: {
          common: {
            close: "Zatvori",
            monday: "Ponedjeljak",
            tuesday: "Utorak",
            wednesday: "Srijeda",
            thursday: "Četvrtak",
            friday: "Petak",
            saturday: "Subota",
            sunday: "Nedjelja",
            hours: "čas",
          },
          detailModal: {
            cardAddress: "Adresa",
            cardWeb: "Web",
            cardWorkingTimesClosed: "Zatvoreno",
            cardWorkingTimesOpen: "Otvoreno 24h",
          },
          messageKey: {
            SaveGeneralData_Pending: "Čuvanje generalnih podataka...",
            SaveGeneralData_CompanyNameIsRequired: "Naziv firme je obavezan.",
            SaveGeneralData_StreetIsRequired: "Ulica firme je obavezna.",
            SaveGeneralData_CategoryIsRequired: "Djelatnost firme je obavezna.",
            SaveGeneralData_CompanyByIdNotFound: "Firma nije pronađena.",
            SaveGeneralData_ParentCategoryIsRequired: "Glavna djelatnost firme je obavezna.",
            SaveGeneralData_WebsiteAlreadyExists: "Web sajt već postoji.",
            SaveGeneralData_WebsiteByIdNotFound: "Web sajt nije pronađen.",
            SaveGeneralData_DefaultEmailTypeNotFound: "Naziv email-a nije pronađen.",
            SaveGeneralData_EmailAlreadyExists: "Email već postoji.",
            SaveGeneralData_EmailByIdNotFound: "Email nije pronađen.",
            SaveGeneralData_AtLeastOnePhoneRequired: "Telefon je obavezan.",
            SaveGeneralData_PhoneNumberRequired: "Broj telefona je obavezan.",
            SaveGeneralData_DefaultPhoneTypeNotFound: "Naziva broja telefona nije pronađen.",
            SaveGeneralData_PhoneByIdNotFound: "Telefon nije pronađen.",
            SaveGeneralData_PhoneAlreadyExists: "Telefon već postoji.",
            SaveGeneralData_WorkingTimeByIdNotFound: "Radni sat nije pronađen.",
            SaveGeneralData_Success: "Generalni podaci sačuvani.",

            SaveDescriptionData_Pending: "Čuvanje opisa...",
            SaveDescriptionData_CompanyByIdNotFound: "Firma nije pronađena.",
            SaveDescriptionData_DescriptionByIdNotFound: "Opis nije pronađen.",
            SaveDescriptionData_AltTextAlreadyExists: "Alternativni tekst već postoji.",
            SaveDescriptionData_AltTextByIdNotFound: "Alternativni tekst nije pronađen.",
            SaveDescriptionData_Success: "Opisi sačuvani.",

            SaveLogo_Pending: "Čuvanje logo slike...",
            SaveLogo_CompanyByIdNotFound: "Firma nije pronađena.",
            SaveLogo_LogoByCompanyIdNotFound: "Logo slika nije pronađena.",
            SaveLogo_ImageFileNotCreated: "Logo slika nije kreirana.",
            SaveLogo_ImageFileNotDeleted: "Logo slika nije obrisana.",
            SaveLogo_Success: "Logo slika sačuvana.",

            SaveGalleryPhotos_Pending: "Čuvanje slike za galeriju.",
            SaveGalleryPhotos_CompanyByIdNotFound: "Firma nije pronađena.",
            SaveGalleryPhotos_ImageFileNotCreated: "Slika za galeriju nije kreirana.",
            SaveGalleryPhotos_Success: "Slika za galeriju sačuvana.",

            SaveBanner_Pending: "Čuvanje banera...",
            SaveBanner_CompanyByIdNotFound: "Firma nije pronađena.",
            SaveBanner_BannerUrlIsRequired: "Url banera je obavezan.",
            SaveBanner_BannerPhotoIsRequired: "Slika banera je obavezna.",
            SaveBanner_BannerResolutionIsRequired: "Rezolucija slike banera je obavezna.",
            SaveBanner_BannerAlreadyExists: "Baner već postoji.",
            SaveBanner_ImageFileNotCreated: "Slika banera nije kreirana.",
            SaveBanner_BannerPhotoResolutionByIdNotFound: "Rezolucija slike banera nije pronađena.",
            SaveBanner_BannerByIdNotFound: "Baner nije pronađen.",
            SaveBanner_ImageFileNotDeleted: "Slika banera nije obrisana.",
            SaveBanner_Success: "Baner je sačuvan.",

            SaveTextAdvertisements_Pending: "Čuvanje tekstualne reklame...",
            SaveTextAdvertisements_CompanyByIdNotFound: "Firma nije pronađena.",
            SaveTextAdvertisements_TitleIsRequired: "Naslov tekstualne reklame je obavezan.",
            SaveTextAdvertisements_DescriptionIsRequired: "Opis tekstualne reklame je obavezan.",
            SaveTextAdvertisements_AlreadyExists: "Tekstualna reklama već postoji.",
            SaveTextAdvertisements_TextAdvertisementByIdNotFound: "Tekstualna reklama nije pronađena.",
            SaveTextAdvertisements_Success: "Tekstualna reklama sačuvana.",

            SaveContactData_Pending: "Čuvanje kontakt podataka firme...",
            SaveContactData_SocialMediaAlreadyExists: "Socijalna medija već postoji.",
            SaveContactData_SocialMediaTypeByIdNotFound: "Vrsta socijalne medije nije pronađena.",
            SaveContactData_SocialMediaByIdNotFound: "Socijalna medija nije pronađena.",
            SaveContactData_CompanyByIdNotFound: "Firma nije pronađena.",
            SaveContactData_LocationAlreadyExists: "Lokacija firme već postoji.",
            SaveContactData_LocationByIdNotFound: "Lokacija nije pronađena.",
            SaveContactData_Success: "Kontakt podaci firme sačuvani.",

            DeleteLogo_Pending: "Brisanje logo slike...",
            DeleteLogo_LogoByCompanyIdNotFound: "Logo slika nije pronađena.",
            DeleteLogo_ImageFileNotDeleted: "Logo slika nije obrisana.",
            DeleteLogo_Success: "Logo slika obrisana.",

            DeleteGalleryPhoto_Pending: "Brisanje slike galerije...",
            DeleteGalleryPhoto_GalleryPhotoByCompanyIdNotFound: "Slika galerije nije pronađena.",
            DeleteGalleryPhoto_ImageFileNotDeleted: "Slika galerije nije obrisana.",
            DeleteGalleryPhoto_Success: "Slika galerije obrisana.",

            RemoveCompany_Pending: "Brisanje firme...",
            RemoveCompany_CompanyByIdNotFound: "Firma nije pronađena.",
            RemoveCompany_Success: "Firma je obrisana.",

            Logout_ExpiredToken: "Vaša sesija je istekla.",

            SaveCompany_Pending: "Čuvanje firme...",
            SaveCompany_Success: "Firma je uspešno sačuvana.",

            SaveStreet_Pending: "Čuvanje ulice...",
            SaveStreet_PlaceByIdNotFound: "Mjesto nije pronađeno.",
            SaveStreet_StreetByIdNotFound: "Ulica nije pronađena.",
            SaveStreet_Success: "Ulica je sačuvana.",

            RemoveStreet_Pending: "Brisanje ulice...",
            RemoveStreet_StreetByIdNotFound: "Ulica nije pronađena.",
            RemoveStreet_Success: "Ulica je obrisana.",

            SavePlace_Pending: "Čuvanje mjesta...",
            SavePlace_CountryByIdNotFound: "Država nije pronađena.",
            SavePlace_PlaceByIdNotFound: "Mjesto nije pronađeno.",
            SavePlace_Success: "Mjesto je sačuvano.",

            RemovePlace_Pending: "Brisanje mjesta...",
            RemovePlace_PlaceByIdNotFound: "Mjesto nije pronađeno.",
            RemovePlace_Success: "Mjesto je obrisano.",

            Login_Pending: "Prijavljivanje u toku...",
            GetByEmail_AccountByEmailNotFound: "Nalog sa ovom e-mail adresom ne postoji.",
            Login_AccountByEmailNotFound: "Nalog sa ovom e-mail adresom ne postoji.",
            Login_TooManyAttempts: "Previše neuspjelih pokušaja prijavljivanja. Kontaktirajte administratora.",
            Login_NeedToChangePassword: "Potrebno je da resetujete lozinku.",
            Login_WrongPassword: "Pogrešna lozinka.",
            Login_AccountInactive: "Nalog nije aktivan. Kontaktirajte administratora.",
            Login_NeedResetPasswordOnFirstLogin: "Potrebno je da promijenite lozinku.",
            Login_Success: "Prijavili ste se.",

            Logout_Success: "Odjavili ste se.",

            SaveCategory_Pending: "Čuvanje djelatnosti...",
            SaveCategory_CategoryByIdNotFound: "Djelatnost nije pronađena.",
            SaveCategory_Success: "Djelatnost sačuvana.",

            RemoveCategory_Pending: "Brisanje djelatnosti...",
            RemoveCategory_CategoryByIdNotFound: "Djelatnost nije pronađena.",
            RemoveCategory_CompanyWithThisCategoryExists: "Ne može se obrisati djelatnost jer firma sa ovom djelatnošću postoji.",
            RemoveCategory_Success: "Obrisana djelatnost.",

            RequestForgotPassword_Pending: "Slanje zahtjeva...",
            RequestForgotPassword_AccountByEmailNotFound: "Nalog nije pronađen.",
            RequestForgotPassword_EmailSent: "E-mail je poslat. Provjerite sanduče.",

            ForgotPassword_Pending: "Promjena lozinke...",
            ForgotPassword_TokenExpired: "Kod je istekao. Pošaljite zahtjev ponovo.",
            ForgotPassword_AccountByEmailNotFound: "Nalog nije pronađen.",
            ForgotPassword_Success: "Lozinka promjenjena.",

            SaveAccount_Pending: "Čuvanje naloga...",
            CreateAccount_Success: "Nalog je kreiran.",
            CreateAccount_AccountWithThisEmailAlreadyExists: "Nalog sa ovom e-mail adresom već postoji.",
            UpdateAccount_AccountByIdNotFound: "Nalog nije pronađen.",
            UpdateAccount_Success: "Nalog je ažuriran.",

            SetAccountActiveStatus_Deactivating: "Deaktiviranje naloga...",
            SetAccountActiveStatus_Activating: "Aktiviranje naloga...",
            SetAccountActiveStatus_AccountByIdNotFound: "Nalog nije pronađen.",
            SetAccountActiveStatus_Activated: "Nalog aktiviran.",
            SetAccountActiveStatus_Deactivated: "Nalog deaktiviran.",

            ResetPassword_Pending: "Promjena lozinke...",
            ResetPassword_AccountByIdNotFound: "Nalog nije pronađen.",
            ResetPassword_AccountByEmailNotFound: "Nalog nije pronađen.",
            ResetPassword_IncorrectOldPassword: "Netačna prijethodna lozinka.",
            ResetPassword_Success: "Promjenjena lozinka.",

            NetworkError: "Greška pri povjezivanju sa serverom."
          }
        },
      },
      rsCyrl: {
        translation: {},
      },
    },
  });

export default i18n;
