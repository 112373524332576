import {
    Autocomplete,
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import BasicInput from "../../../components/basicInput/basicInput";
import RemoveIconButton from "../../../components/icons/RemoveIconButton";
import { ApiStatus } from "../../../models/app/apiStatus";
import { CategoryShortDetail } from "../../../models/categories/categoryShortDetail";
import {
    companyHasCategory as companyHasCategoryRequest,
    getAllCategoryShortDetails,
    removeCategory,
    saveCategory,
    selectCategoryShortDetails,
    selectSaveCategoryRequestStatus
} from "../../../slice/categorySlice";
import { EmptyGuid } from "../../stepper/initialData";
import RemoveCategoryModal from "../removeCategory/RemoveCategoryModal";
import { useEditCategoryModalStyles } from "./editCategoryModalStyles";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    setCompanyCategories: (categories: CategoryShortDetail[]) => void;
    selectedCategoryIdsForm: string[];
};

const INIT_CATEGORY: CategoryShortDetail = {
    id: EmptyGuid,
    name: "",
    hasStarRating: false,
    parentId: null,
};

export default function EditCategoryModal({
    isOpen,
    onClose,
    setCompanyCategories,
    selectedCategoryIdsForm
}: Props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const { classes } = useEditCategoryModalStyles();
    const dispatch = useAppDispatch();
    const saveCategoryRequestStatus = useAppSelector(selectSaveCategoryRequestStatus);

    const [companyHasCategory, setCompanyHasCategory] = useState<boolean>(false);
    const categories = useAppSelector(selectCategoryShortDetails);
    const [selectedCategory, setSelectedCategory] = useState<CategoryShortDetail | null>(null);
    const [parentCategory, setParentCategory] = useState<CategoryShortDetail | null>(null);
    const [category, setCategory] = useState<CategoryShortDetail>({ ...INIT_CATEGORY });
    const [isParentCategory, setIsParentCategory] = useState<boolean>(true);

    const [isOpenRemoveCategoryModal, setIsOpenRemoveCategoryModal] = useState<boolean>(false);

    const onChangeSelectedCategory = async (
        event: SyntheticEvent<Element, Event>,
        newValue: CategoryShortDetail | null
    ) => {
        setSelectedCategory(newValue);

        if (newValue?.parentId) {
            setParentCategory(categories?.find(x => x.id === newValue?.parentId && !x.parentId) ?? null);
            setIsParentCategory(false);
        } else {
            setParentCategory(null);
            setIsParentCategory(true);
        }

        if (newValue) {
            const response = await dispatch(companyHasCategoryRequest(newValue.id));
            setCompanyHasCategory(response?.payload === true);
            setCategory({
                ...newValue,
                hasStarRating: newValue.parentId === null ? newValue.hasStarRating : false
            });
        } else {
            setCategory({ ...INIT_CATEGORY });
        }
    };

    const onChangeParentCategory = (
        event: SyntheticEvent<Element, Event>,
        newValue: CategoryShortDetail | null
    ) => {
        setParentCategory(newValue);
        setCategory({ ...category, parentId: newValue ? newValue.id : null });
    }

    const onSaveCategory = async () => {
        const request: CategoryShortDetail = { ...category };

        if (request.parentId === EmptyGuid) {
            request.parentId = null;
        }

        if (request.parentId !== null) {
            request.hasStarRating = false;
        }

        // Send request

        const response = await dispatch(saveCategory(request));

        if (!response.payload) {
            return;
        }

        const result = response.payload as CategoryShortDetail;

        // Get categories again

        const getCategoriesResponse = await dispatch(getAllCategoryShortDetails());

        if (!getCategoriesResponse.payload) {
            return;
        }

        const newCategories = getCategoriesResponse.payload as CategoryShortDetail[];

        // Set new saved category in select

        const savedCategory = newCategories.find(x => x.id === result.id);

        if (!savedCategory) {
            return;
        }

        setSelectedCategory(savedCategory);
        setCategory({ ...savedCategory });

        // Update selected categories in form

        const updateSelectedCategoriesForm = newCategories.filter(x => selectedCategoryIdsForm.includes(x.id));
        setCompanyCategories([...updateSelectedCategoriesForm]);
    };

    const onRemoveCategory = async () => {
        const response = await dispatch(removeCategory(category.id));

        if (!response.payload) {
            return;
        }

        // Get categories again

        const getCategoriesResponse = await dispatch(getAllCategoryShortDetails());

        if (!getCategoriesResponse.payload) {
            return;
        }

        const newCategories = getCategoriesResponse.payload as CategoryShortDetail[];

        // Clear category select and inputs

        setSelectedCategory(null);
        setCategory({ ...INIT_CATEGORY });
        setIsParentCategory(true);
        setParentCategory(null);

        // Update selected categories in form

        const updateSelectedCategoriesForm = newCategories.filter(x => selectedCategoryIdsForm.includes(x.id));
        setCompanyCategories([...updateSelectedCategoriesForm]);

        setIsOpenRemoveCategoryModal(false);
    };

    const onOpenRemoveCategoryModal = () => {
        setIsOpenRemoveCategoryModal(true);
    };

    const onCloseRemoveCategoryModal = () => {
        setIsOpenRemoveCategoryModal(false);
    };

    const onClear = () => {
        setSelectedCategory(null);
        setCategory({ ...INIT_CATEGORY });
        setIsParentCategory(true);
        setParentCategory(null);
    };

    return (
        <Dialog onClose={onClose} open={isOpen} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ textAlign: "center" }}>
                Uređivanje djelatnosti
            </DialogTitle>

            <DialogContent>
                <Box mt={4} mb={2}>
                    <Grid container spacing={1}>
                        <Grid item xs={isMobile ? 10 : 11}>
                            <Autocomplete
                                options={categories ?? []}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                value={selectedCategory}
                                onChange={onChangeSelectedCategory}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props} style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}>
                                        <span
                                            style={{
                                                marginRight: 6,
                                                marginLeft: option.parentId ? 20 : 0,
                                            }}
                                        >
                                            {option.name}
                                        </span>
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Postojeća djelatnost"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                )}
                                className={classes.autocomplete}
                                sx={{
                                    boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
                                }}
                                ListboxProps={{
                                    sx: { maxHeight: 180, overflow: "auto" }
                                }}
                            />
                        </Grid>

                        <Grid item xs={isMobile ? 2 : 1}>
                            <RemoveIconButton
                                onClick={onOpenRemoveCategoryModal}
                                disabled={companyHasCategory || !selectedCategory}
                                tooltipTitle={!selectedCategory
                                    ? "Nije odabrana postojeća djelatnost"
                                    : companyHasCategory ? "Nije moguće obrisati djelatnost jer postoji kompanija koja sadrži izabranu djelatnost"
                                        : ""}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box mb={2}>
                    <BasicInput
                        value={category.name}
                        onChange={(e) => setCategory({ ...category, name: e.target.value })}
                        boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.3)"}
                        multiline={false}
                        label="Naziv djelatnosti"
                    />
                </Box>

                <Box ml={1} mb={2}>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="category-type-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={isParentCategory}
                            onChange={(e) => {
                                const value = e.target.value === String(true);

                                setIsParentCategory(value);

                                if (!value) {
                                    setCategory({ ...category, hasStarRating: false });
                                }
                            }}
                        >
                            <Box>
                                <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label={"Glavna djelatnost"}
                                    disabled={selectedCategory !== null}
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label={"Pod djelatnost"}
                                    disabled={selectedCategory !== null}
                                />
                            </Box>
                        </RadioGroup>
                    </FormControl>
                </Box>

                {!isParentCategory &&
                    <Box mb={2}>
                        <Autocomplete
                            options={categories?.filter(x => !x.parentId) ?? []}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={parentCategory}
                            onChange={onChangeParentCategory}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Glavna djelatnost"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            )}
                            className={classes.autocomplete}
                            sx={{
                                boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
                                mb: 4,
                                mt: 2,
                            }}
                            ListboxProps={{
                                sx: { maxHeight: 180, overflow: "auto" }
                            }}
                            disabled={selectedCategory !== null || isParentCategory}
                            readOnly={selectedCategory !== null || isParentCategory}
                        />
                    </Box>}

                <Box ml={1}>
                    <FormControl>
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Zvjezdice"
                            checked={category.hasStarRating}
                            onChange={(e, checked) => setCategory({ ...category, hasStarRating: checked })}
                            style={{ color: colors.sectionTitle }}
                            disabled={!isParentCategory}
                        />
                    </FormControl>
                </Box>
            </DialogContent>

            <DialogActions>
                <Grid container justifyContent="space-between" mx={1}>
                    <Grid item>
                        <AppButton
                            onClick={onClear}
                            color={colors.sectionTitle}
                            hover={colors.sectionTitle}
                            label={"Očisti polja"}
                        />
                    </Grid>

                    <Grid item>
                        <Grid container columnSpacing={2}>
                            <Grid item>
                                <AppButton
                                    onClick={onClose}
                                    color={colors.primary}
                                    hover={colors.primary}
                                    label={"Zatvori"}
                                />
                            </Grid>
                            <Grid item>

                                <AppButton
                                    onClick={onSaveCategory}
                                    label={category.id === EmptyGuid ? "Kreiraj novu djelatnost" : "Ažuriraj djelatnost"}
                                    color={category.id === EmptyGuid ? colors.green : colors.primary}
                                    hover={category.id === EmptyGuid ? colors.green : colors.primary}
                                    disabled={category.name.trim().length === 0
                                        || (!isParentCategory && !parentCategory)
                                        || saveCategoryRequestStatus === ApiStatus.Pending}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>

            <RemoveCategoryModal
                isOpen={isOpenRemoveCategoryModal}
                onClose={onCloseRemoveCategoryModal}
                onRemove={onRemoveCategory}
                categoryName={category.name}
            />
        </Dialog>
    );
};