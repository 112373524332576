import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import {
  getAllSocialMediaTypes,
  selectSocialMediaTypes,
} from "../../slice/contactSlice";

export default function useGetAllSocialMediaTypes() {
  const dispatch = useAppDispatch();
  const socialMediaTypes = useAppSelector(selectSocialMediaTypes);

  const get = useCallback(() => {
    dispatch(getAllSocialMediaTypes());
  }, [dispatch]);

  useEffect(() => {
    get();
  }, [get]);

  return { socialMediaTypes, refetchSocialMediaTypes: get };
}
