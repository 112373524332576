import {
  Checkbox,
  DialogActions,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppSelector } from "../../app/redux/hooks";
import { colors } from "../../app/theme/colors";
import { Convert12to24Time, Convert24to12Time } from "../../common/helpers/helpers";
import { AreAllWorkingTimesValid, IsWorkingTimeEmpty } from "../../common/validations/generalSectionValidations";
import AppButton from "../../components/appButton/appButton";
import BasicInput from "../../components/basicInput/basicInput";
import AddIconButton from "../../components/icons/AddIconButton";
import RemoveIconButton from "../../components/icons/RemoveIconButton";
import { CompanyStepper } from "../../models/companies/saveCompany/companyStepper";
import { WorkingTimeForSaveCompany } from "../../models/companies/saveCompany/general/workingTimeForSaveCompany";
import WorkingDays from "../../models/days/workingDays";
import { selectSavedGeneralData } from "../../slice/companySlice";
import { EmptyGuid } from "../stepper/initialData";
import { useWorkingHoursModalStyles } from "./workingHoursModalStyles";
import { useTranslation } from "react-i18next";

const DayButton = styled(Button)(({ theme }) => ({
  borderRadius: "50%",
  height: "60px",
  margin: "5px",
  color: colors.inputTitle,
  fontWeight: "bold",
  backgroundColor: "#DBDBDB",
  border: "none",
  fontSize: "1.2em",
  "&.MuiButton-contained": {
    backgroundColor: "#B9CFF4",
    color: colors.inputTitle,
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  company: CompanyStepper;
  setCompany: Dispatch<SetStateAction<CompanyStepper>>;
  workingDays?: WorkingDays[] | null;
  setWorkingDays?: any;
}

const initWorkingTime: WorkingTimeForSaveCompany = {
  dayId: EmptyGuid,
  fromTime: null,
  id: EmptyGuid,
  isOpen24Hours: null,
  isClosed: null,
  toTime: null,
};

export default function WorkingHoursModal({
  onClose,
  open,
  company,
  setCompany,
  workingDays,
  setWorkingDays,
}: Props) {
  const { t } = useTranslation();
  const generalData = useAppSelector(selectSavedGeneralData);
  const { classes } = useWorkingHoursModalStyles();
  const [workingTimes, setWorkingTimes] = useState<WorkingTimeForSaveCompany[]>(
    [{ ...initWorkingTime }]
  );

  // On open modal and on change selected day
  useEffect(() => {
    const firstSelectedDay = workingDays?.find(x => x.selected);

    if (!firstSelectedDay) {
      return;
    }

    const dataFromFirstDay = company.general.workingTimes
      .filter((x) => x.dayId === firstSelectedDay.id);

    if (dataFromFirstDay.length === 0) {
      setWorkingTimes([{ ...initWorkingTime }]);
    } else {
      setWorkingTimes([...dataFromFirstDay]);
    }
  }, [workingDays, generalData]);

  const handleDayClick = (dayId: string) => {
    const newDays = workingDays?.map((x) =>
      x.id === dayId
        ? { ...x, selected: !x.selected }
        : x
    );

    setWorkingDays(newDays);
  };

  const handleAddWorkingHoursInputs = () => {
    const newWorkingItem = { ...initWorkingTime };
    setWorkingTimes([...workingTimes, newWorkingItem]);
  };

  const handleDeleteWorkingHoursInputs = (index: number) => {
    let newWorkingItems = [];

    if (workingTimes.length === 1) {
      newWorkingItems = [{ ...initWorkingTime }];
    } else {
      newWorkingItems = [...workingTimes.filter((x, i) => i !== index)];
    }

    setWorkingTimes([...newWorkingItems]);
  };

  const handleSaveWorkingHours = () => {
    let newCompanyWorkingItems: WorkingTimeForSaveCompany[] =
      company.general.workingTimes.filter((x) => x.dayId !== EmptyGuid);

    workingDays?.forEach((day) => {
      if (day.selected) {
        var days = newCompanyWorkingItems.filter((x) => x.dayId !== day.id);

        const closedOrOpen = workingTimes.find(x => x.isClosed === true || x.isOpen24Hours === true);

        if (closedOrOpen) {
          const companyWorkingItem: WorkingTimeForSaveCompany = {
            dayId: day.id,
            fromTime: null,
            toTime: null,
            isOpen24Hours: closedOrOpen.isOpen24Hours,
            isClosed: closedOrOpen.isClosed,
            id: EmptyGuid,
          };

          days.push(companyWorkingItem);
        } else {
          const updWorkingTimes = [...workingTimes.filter((x) => !IsWorkingTimeEmpty(x))];

          if (updWorkingTimes.length > 0) {
            updWorkingTimes.forEach((x) => {
              const time = { ...x };

              if (time.fromTime?.includes("AM") || time.fromTime?.includes("PM")) {
                time.fromTime = Convert12to24Time(time.fromTime);
              }

              if (time.toTime?.includes("AM") || time.toTime?.includes("PM")) {
                time.toTime = Convert12to24Time(time.toTime);
              }

              const companyWorkingItem: WorkingTimeForSaveCompany = {
                dayId: day.id,
                fromTime: time.fromTime ? Convert24to12Time(time.fromTime) : "",
                toTime: time.toTime ? Convert24to12Time(time.toTime) : "",
                isOpen24Hours: time.isOpen24Hours,
                isClosed: time.isClosed,
                id: EmptyGuid,
              };

              days.push(companyWorkingItem);
            });
          }
        }

        newCompanyWorkingItems = [...days];
      }
    });

    setCompany({
      ...company,
      general: { ...company.general, workingTimes: [...newCompanyWorkingItems] },
    });

    setWorkingTimes([{ ...initWorkingTime }]);

    onClose();
  };

  const isAnyDaySelected = (days: WorkingDays[]): boolean => {
    return days.find((x) => x.selected) !== undefined;
  }

  return (
    <Dialog onClose={onClose} open={open} maxWidth="xl">
      <DialogTitle sx={{ textAlign: "center" }}>
        Izaberite datum i vrijeme
      </DialogTitle>
      <DialogContent>
        <Grid item md={12}>
          <div className={classes.dayButtonContainer}>
            {workingDays?.map((day, i) => (
              <DayButton
                key={i}
                variant={day.selected ? "contained" : "outlined"}
                onClick={() => handleDayClick(day.id)}
              >
                {day.name}
              </DayButton>
            ))}
          </div>
        </Grid>
        {workingTimes.map((item, i) => (
          <div key={i}>
            {i === 0 && (
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <FormControl>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Otvoreno 24 sata"
                      disabled={i !== 0}
                      checked={item.isOpen24Hours === true}
                      onChange={(e: any) => {
                        const newWorkingItem = { ...item };
                        newWorkingItem.isOpen24Hours = e.target.checked;
                        newWorkingItem.isClosed = newWorkingItem.isClosed ? false : newWorkingItem.isClosed;
                        var newWorkingItems = [...workingTimes];
                        newWorkingItems[i] = newWorkingItem;
                        setWorkingTimes([...newWorkingItems]);
                      }}
                      style={{ color: colors.sectionTitle }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Zatvoreno"
                      checked={item.isClosed === true}
                      onChange={(e: any) => {
                        const newWorkingItem = { ...item };
                        newWorkingItem.isClosed = e.target.checked;
                        newWorkingItem.isOpen24Hours = newWorkingItem.isOpen24Hours ? false : newWorkingItem.isOpen24Hours;
                        var newWorkingItems = [...workingTimes];
                        newWorkingItems[i] = newWorkingItem;
                        setWorkingTimes([...newWorkingItems]);
                      }}
                      style={{ color: colors.sectionTitle }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}

            {!workingTimes.find((x) => x.isClosed === true || x.isOpen24Hours === true) && (
              <div className={classes.inputWrapper}>
                <Grid
                  container
                  display="flex"
                  spacing={1}
                  justifyContent="center"
                >
                  <Grid item lg={"auto"} md={4}>
                    <BasicInput
                      type="time"
                      value={item.fromTime?.includes("AM") || item.fromTime?.includes("PM") ? Convert12to24Time(item.fromTime) : (item.fromTime ?? "")}
                      onChange={(e) => {
                        const newWorkingItem = { ...item };
                        newWorkingItem.fromTime = e.target.value;
                        var newWorkingItems = [...workingTimes];
                        newWorkingItems[i] = newWorkingItem;
                        setWorkingTimes([...newWorkingItems]);
                      }}
                      multiline={false}
                    />
                  </Grid>

                  <Grid item lg={"auto"} md={4}>
                    <BasicInput
                      type="time"
                      value={item.toTime?.includes("AM") || item.toTime?.includes("PM") ? Convert12to24Time(item.toTime) : (item.toTime ?? "")}
                      onChange={(e) => {
                        const newWorkingItem = { ...item };
                        newWorkingItem.toTime = e.target.value;
                        var newWorkingItems = [...workingTimes];
                        newWorkingItems[i] = newWorkingItem;
                        setWorkingTimes([...newWorkingItems]);
                      }}
                      multiline={false}
                    />
                  </Grid>

                  <Grid item lg={2}>
                    <RemoveIconButton
                      onClick={() => handleDeleteWorkingHoursInputs(i)}
                    />
                    {i === workingTimes.length - 1 && AreAllWorkingTimesValid(workingTimes) && (
                      <AddIconButton
                        onClick={handleAddWorkingHoursInputs}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        ))}
      </DialogContent>

      <DialogActions>
        <AppButton
          onClick={onClose}
          color={colors.primary}
          hover={colors.primary}
          label={t("common.close")}
        />
        <AppButton
          label="Sačuvaj"
          color={colors.green}
          hover={colors.green}
          onClick={handleSaveWorkingHours}
          disabled={!isAnyDaySelected(workingDays ?? [])}
        />
      </DialogActions>
    </Dialog>
  );
}
