import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Categories } from "../app/redux/adminPanelApi";
import { RootState } from "../app/redux/store";
import i18n from "../i18n";
import { ApiResponse } from "../models/app/apiResponse";
import { CategoryShortDetail } from "../models/categories/categoryShortDetail";
import { setErrorNotification, setPendingNotification, setSuccessNotification } from "./notificationSlice";
import { ApiStatus } from "../models/app/apiStatus";

type CategoryState = {
  categoryShortDetails: CategoryShortDetail[] | null;
  companyHasCategory: boolean | null;

  requestStatus: {
    saveCategory: ApiStatus;
  }
};

const initialState: CategoryState = {
  categoryShortDetails: null,
  companyHasCategory: null,

  requestStatus: {
    saveCategory: ApiStatus.Idle,
  }
};

export const getAllCategoryShortDetails = createAsyncThunk(
  "Category/Get_Category_Short_Details",
  async (_: void) => {
    const response = await Categories.GetAllCategoryShortDetails();
    return response.data;
  }
);

export const saveCategory = createAsyncThunk<
  CategoryShortDetail | null,
  CategoryShortDetail,
  { rejectValue: string }
>(
  "Category/Save_Category",
  async (category: CategoryShortDetail, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.SaveCategory_Pending")));
      const response = await Categories.SaveCategory(category);
      dispatch(setSuccessNotification(i18n.t("messageKey." + response.messageKey)));
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
      }
      return rejectWithValue(messageKey);
    }
  }
);

export const companyHasCategory = createAsyncThunk(
  "Category/Company_Has_Category",
  async (categoryId: string) => {
    const response = await Categories.CompanyHasCategory(categoryId);
    return response.data;
  }
);

export const removeCategory = createAsyncThunk<
  boolean | null,
  string,
  { rejectValue: string }
>(
  "Category/Remove_Category",
  async (categoryId: string, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.RemoveCategory_Pending")));
      const response = await Categories.RemoveCategory(categoryId);
      dispatch(setSuccessNotification(i18n.t("messageKey." + response.messageKey)));
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
      }
      return rejectWithValue(messageKey);
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategoryShortDetails.fulfilled, (state, action) => {
        state.categoryShortDetails = action.payload;
      })
      .addCase(companyHasCategory.fulfilled, (state, action) => {
        state.companyHasCategory = action.payload;
      })
      .addCase(saveCategory.pending, (state) => {
        state.requestStatus.saveCategory = ApiStatus.Pending;
      })
      .addCase(saveCategory.fulfilled, (state) => {
        state.requestStatus.saveCategory = ApiStatus.Fulfilled;
      })
      .addCase(saveCategory.rejected, (state) => {
        state.requestStatus.saveCategory = ApiStatus.Rejected;
      });
  },
});

export const selectCategoryShortDetails = (state: RootState) =>
  state.category.categoryShortDetails;

export const selectCompanyHasCategory = (state: RootState) =>
  state.category.companyHasCategory;

export const selectSaveCategoryRequestStatus = (state: RootState) =>
  state.category.requestStatus.saveCategory;

export default categorySlice.reducer;
