import { Container, Grid } from "@mui/material";
import Header from "../../features/header/header";
import UserManagement from "../../features/userManagement/UserManagement";

export default function UserManagementPage() {
  return (
    <Container maxWidth="xl">
      <Header />
      <Grid item md={12} mt={12}>
        <UserManagement />
      </Grid>
    </Container>
  );
}
