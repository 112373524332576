import {
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useBasicInputStyles } from "./basicInputStyles";

interface Props {
  label?: any;
  value?: any;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  type?: string;
  error?: boolean;
  errorMessage?: any;
  disabled?: boolean;
  inputComponent?: any;
  name?: string;
  endAdornment?: any;
  id?: string;
  autofocus?: boolean;
  helperText?: any;
  height?: any;
  width?: string;
  readOnly?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => any;
  required?: boolean;
  placeholder?: string;
  startAdornment?: any;
  multiline?: boolean;
  maxRows?: number;
  rows?: any;
  minRows?: any;
  inputTitleColor?: any;
  boxShadow?: any;
  inputProps?: any;
  fontWeight?: any;
  count?: any;
}

const BasicInput = ({
  onChange,
  error,
  errorMessage,
  helperText,
  value,
  placeholder,
  id,
  label,
  rows,
  multiline,
  inputProps,
  count,
  type,
  disabled,
  onKeyDown,
  name
}: Props) => {
  const { classes } = useBasicInputStyles();

  return (
    <FormControl
      variant="outlined"
      fullWidth
    >
      <TextField
        name={name}
        size="small"
        autoComplete="off"
        id={id}
        label={label}
        variant="outlined"
        type={type ?? "text"}
        InputProps={{ className: classes.input }}

        rows={rows}
        onChange={onChange}
        multiline={multiline}
        inputProps={{ ...inputProps }}
        placeholder={placeholder}
        value={value}
        InputLabelProps={{
          shrink: value || (placeholder && label),
        }}
        error={error}
        disabled={disabled}
        onKeyDown={onKeyDown}
      />
      {multiline !== false && count && (
        <Typography className={classes.characterCounter}>
          {value?.length}/{count}
        </Typography>
      )}
      <FormHelperText>{helperText}</FormHelperText>
      {error === true && (
        <span className={classes.errorMessage}>{errorMessage}</span>
      )}
    </FormControl>
  );
};

export default BasicInput;
