import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Auth } from "../app/redux/adminPanelApi";
import { RootState } from "../app/redux/store";
import i18n from "../i18n";
import { ApiResponse } from "../models/app/apiResponse";
import { ApiStatus } from "../models/app/apiStatus";
import { LoginRequest } from "../models/auth/loginRequest";
import { LoginResponse } from "../models/auth/loginResponse";
import {
    setErrorNotification,
    setInfoNotification,
    setPendingNotification,
    setSuccessNotification
} from "./notificationSlice";
import { EmptyGuid } from "../features/stepper/initialData";
import { User } from "../models/users/user";

type AuthState = {
    accessToken: string | null;
    loginResponse: LoginResponse | null;
    organizationId: string;
    authenticatedUser: User | null;

    requestStatus: {
        login: ApiStatus;
    }
};

const initialState: AuthState = {
    accessToken: localStorage.getItem("accessToken") ?? null,
    loginResponse: null,
    organizationId: localStorage.getItem("organizationId") ?? EmptyGuid,
    authenticatedUser: null,

    requestStatus: {
        login: ApiStatus.Idle,
    }
};

export const login = createAsyncThunk<
    LoginResponse | null,
    LoginRequest,
    { rejectValue: string }
>(
    "Auth/Login",
    async (request: LoginRequest, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setPendingNotification(i18n.t("messageKey.Login_Pending")));
            const response = await Auth.Login(request);
            const result = response.data;
            if (result) {
                if (result.isFirstLogin) {
                    dispatch(setInfoNotification(
                        i18n.t("messageKey.Login_NeedResetPasswordOnFirstLogin")
                    ));
                } else {
                    dispatch(setSuccessNotification(i18n.t("messageKey."
                        + response.messageKey)));
                }
            }
            return result;
        } catch (error) {
            const errorResponse = error as ApiResponse<null>;
            const messageKey = errorResponse.messageKey;
            if (messageKey) {
                dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
            }
            return rejectWithValue(messageKey);
        }
    }
);

export const getAuthenticatedUser = createAsyncThunk(
    "Auth/Get_Authenticated_User",
    async () => {
        const response = await Auth.GetAuthenticatedUser();
        return response.data;
    }
);

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            state.loginResponse = initialState.loginResponse;
            state.accessToken = null;
            state.organizationId = initialState.organizationId;
            localStorage.removeItem("accessToken");
            localStorage.removeItem("organizationId");
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.requestStatus.login = ApiStatus.Pending;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.requestStatus.login = ApiStatus.Fulfilled;
                state.loginResponse = action.payload;
                if (action.payload?.isFirstLogin) {
                    return;
                }
                state.accessToken = action.payload?.accessToken ?? initialState.accessToken;
                localStorage.setItem("accessToken", action.payload?.accessToken ?? "");
                state.organizationId = action.payload?.organizationId ?? EmptyGuid;
                localStorage.setItem("organizationId", action.payload?.organizationId ?? EmptyGuid);
            })
            .addCase(login.rejected, (state) => {
                state.requestStatus.login = ApiStatus.Rejected;
            })
            .addCase(getAuthenticatedUser.fulfilled, (state, action) => {
                state.authenticatedUser = action.payload;
            });
    },
});

export const { logout } = authSlice.actions;

export const selectLoginResponse = (state: RootState) => state.auth.loginResponse;
export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectLoginRequestStatus = (state: RootState) => state.auth.requestStatus.login;
export const selectOrganizationId = (state: RootState) => state.auth.organizationId;
export const selectAuthenticatedUser = (state: RootState) => state.auth.authenticatedUser;

export default authSlice.reducer;
