import { CountryModel } from "../../models/address/countryModel";
import { PlaceModel } from "../../models/address/placeModel";
import { StreetModel } from "../../models/address/streetModel";
import { CategoryShortDetail } from "../../models/categories/categoryShortDetail";
import { EmailForSaveCompany } from "../../models/companies/saveCompany/general/emailForSaveCompany";
import { PhoneForSaveCompany } from "../../models/companies/saveCompany/general/phoneForSaveCompany";
import { WebsiteForSaveCompany } from "../../models/companies/saveCompany/general/websiteForSaveCompany";
import { WorkingTimeForSaveCompany } from "../../models/companies/saveCompany/general/workingTimeForSaveCompany";

function IsPhoneValid(phone: PhoneForSaveCompany): boolean {
  return (
    phone.phoneNumber.trim().length !== 0 && phone.typeName.trim().length !== 0
  );
}

export function AreAllPhonesValid(phones: PhoneForSaveCompany[]): boolean {
  for (let i = 0; i < phones.length; i++) {
    if (!IsPhoneValid(phones[i])) {
      return false;
    }
  }

  return true;
}

function IsWebsiteValid(website: WebsiteForSaveCompany): boolean {
  return website.url.trim().length !== 0;
}

export function AreAllWebsitesValid(
  websites: WebsiteForSaveCompany[]
): boolean {
  for (let i = 0; i < websites.length; i++) {
    if (!IsWebsiteValid(websites[i])) {
      return false;
    }
  }

  return true;
}

function IsEmailValid(email: EmailForSaveCompany): boolean {
  return (
    email.emailAddress.trim().length !== 0 && email.typeName.trim().length !== 0
  );
}

export function AreAllEmailsValid(emails: EmailForSaveCompany[]): boolean {
  for (let i = 0; i < emails.length; i++) {
    if (!IsEmailValid(emails[i])) {
      return false;
    }
  }

  return true;
}

export function IsCompanyNameValid(name: string): boolean {
  return name.trim().length > 0
}

export function IsCompanyCategoryValid(categories: CategoryShortDetail[]): boolean {
  return categories.length > 0;
}

export function IsCompanyCountryValid(country: CountryModel | null): boolean {
  return country !== null;
}

export function IsCompanyPlaceValid(place: PlaceModel | null): boolean {
  return place !== null;
}

export function IsCompanyStreetValid(street: StreetModel | null): boolean {
  return street !== null;
}

export function IsCompanyPhoneTypeValid(phone: PhoneForSaveCompany): boolean {
  return phone.typeName.trim().length > 0;
}

export function IsCompanyPhoneNumberValid(phone: PhoneForSaveCompany): boolean {
  return phone.phoneNumber.trim().length > 0;
}

export function IsAllCompanyPhoneTypesValid(phones: PhoneForSaveCompany[]): boolean {
  if (phones.length === 0) {
    return false;
  }

  const invalid = phones.find(x => x.typeName.trim().length === 0);

  return invalid === undefined;
}

export function IsAllCompanyPhoneNumbersValid(phones: PhoneForSaveCompany[]): boolean {
  if (phones.length === 0) {
    return false;
  }

  const invalid = phones.find(x => x.phoneNumber.trim().length === 0);

  return invalid === undefined;
}

export function AreAllWorkingTimesValid(workingTimes: WorkingTimeForSaveCompany[]): boolean {
  for (let i = 0; i < workingTimes.length; i++) {
    const x = workingTimes[i];

    const hasFromTime = x.fromTime !== null && x.fromTime.trim().length > 0;
    const hasToTime = x.toTime !== null && x.toTime.trim().length > 0;
    const openOrClosed = x.isClosed === true || x.isOpen24Hours === true;

    const valid = (hasFromTime && hasToTime) || openOrClosed;

    if (!valid) {
      return false;
    }
  }

  return true;
}

export function IsWorkingTimeEmpty(x: WorkingTimeForSaveCompany): boolean {
  return ((x.fromTime === null || x.fromTime.trim().length === 0)
    || (x.toTime === null || x.toTime.trim().length === 0))
    && (!x.isClosed && !x.isOpen24Hours)
}