import { Grid } from "@mui/material";
import { useDashboardSectionStyles } from "./dashboardSectionStyles";
import DashboardChart from "../dashboardChart/dashboardChart";
import DashboardClientsTable from "../dashboardClientsTable/dashboardClientsTable";

export default function DashboardSection() {
  const { classes } = useDashboardSectionStyles();
  return (
    <Grid container>
      <Grid item md={12} xs={12} className={classes.chartWrapper} mt={5}>
        <DashboardChart />
      </Grid>
      <Grid
        item
        md={12}
        xs={12}
        mt={3}
        container
        justifyContent="center"
        alignItems="center"
      >
        <DashboardClientsTable />
      </Grid>
    </Grid>
  );
}
