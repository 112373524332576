import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../app/theme/colors';
import AppButton from '../../../components/appButton/appButton';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onRemove: () => Promise<void>;
    streetName: string;
};

export default function RemoveStreetModal({ isOpen, onClose, onRemove, streetName, }: Props) {
    const { t } = useTranslation();

    return (
        <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                Brisanje ulice
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Typography mb={2}>Da li zaista želite obrisati ovu ulicu?</Typography>

                <Typography fontWeight="bold">
                    Ulica: {streetName}
                </Typography>
            </DialogContent>
            <DialogActions>
                <AppButton
                    onClick={onClose}
                    color={colors.primary}
                    hover={colors.primary}
                    label={t("common.close")}
                />
                <AppButton
                    label={"Obriši"}
                    onClick={onRemove}
                    color={colors.red}
                    hover={colors.red}
                />
            </DialogActions>
        </BootstrapDialog>
    );
}

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
        </DialogTitle>
    );
}