import { Container, Grid } from "@mui/material";
import Header from "../../features/header/header";

export default function StatisticsPage() {
  return (
    <Container maxWidth="xl">
      <Header />
      <Grid item md={12} mt={6}>
        <></>
      </Grid>
    </Container>
  );
}
