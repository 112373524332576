import { ControlPoint } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { colors } from "../../app/theme/colors";

type Props = {
  onClick: any;
};

export default function AddIconButton({ onClick }: Props) {
  return (
    <IconButton onClick={onClick} size="large" sx={{ p: 0.5, ml: 1 }}>
      <ControlPoint
        sx={{ fontSize: "1.1em", color: colors.icons.controlPointIcon }}
      />
    </IconButton>
  );
}
