type Props = {};

export default function BuildingIcon({}: Props) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.786873 23.5081V5.83603H5.83604V0.786865H18.4589V10.8852H23.5081V23.5081H13.4098V18.4589H10.8852V23.5081H0.786873ZM3.31145 20.9835H5.83604V18.4589H3.31145V20.9835ZM3.31145 15.9344H5.83604V13.4098H3.31145V15.9344ZM3.31145 10.8852H5.83604V8.36061H3.31145V10.8852ZM8.36062 15.9344H10.8852V13.4098H8.36062V15.9344ZM8.36062 10.8852H10.8852V8.36061H8.36062V10.8852ZM8.36062 5.83603H10.8852V3.31145H8.36062V5.83603ZM13.4098 15.9344H15.9344V13.4098H13.4098V15.9344ZM13.4098 10.8852H15.9344V8.36061H13.4098V10.8852ZM13.4098 5.83603H15.9344V3.31145H13.4098V5.83603ZM18.4589 20.9835H20.9835V18.4589H18.4589V20.9835ZM18.4589 15.9344H20.9835V13.4098H18.4589V15.9344Z"
        fill="#747474"
      />
    </svg>
  );
}
