import axios from "axios";

export const PoslovniImenikEnv = {
  Local: "https://localhost:5001",
  Qa: "https://poslovniimenik-bih-qa.api.midenas.rs",
  Prod: "https://admin.api.poslovniimenik.net"
};

export const AdminEnv = {
  Local: "https://localhost:6001",
  Qa: "https://poslovniimenik-bih-qa-us.api.midenas.rs",
  Prod: "https://um.api.poslovniimenik.net"
};

export const api = axios.create({
  baseURL: PoslovniImenikEnv.Prod + "/api",
});

export const adminApi = axios.create({
  baseURL: AdminEnv.Prod + "/api",
});