import Close from "@mui/icons-material/Close";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import { CompanyStepper } from "../../../models/companies/saveCompany/companyStepper";
import { GalleryPhotoForSaveCompany } from "../../../models/companies/saveCompany/photos/galleryPhotoForSaveCompany";
import { GalleryPhotoForSaveCompanyResponse } from "../../../models/companies/saveCompany/photos/galleryPhotoForSaveCompanyResponse";
import { Logo } from "../../../models/companies/saveCompany/photos/logo";
import {
  deleteGalleryPhoto,
  deleteLogo,
  removeGalleryPhoto,
  saveGalleryPhoto,
  saveLogo,
  selectSavedGalleryPhoto,
  selectSavedLogo,
} from "../../../slice/companySlice";
import { useImagesSectionStyles } from "./imagesSectionStyles";
import { PoslovniImenikEnv } from "../../../app/redux/api";

interface Props {
  company: CompanyStepper;
  setCompany: Dispatch<SetStateAction<CompanyStepper>>;
}

export default function ImagesSection({ company, setCompany }: Props) {
  const dispatch = useAppDispatch();
  const { classes } = useImagesSectionStyles();
  const logoInputRef = useRef<HTMLInputElement>(null);
  const galleryPhotosInputRef = useRef<HTMLInputElement>(null);
  const savedLogo = useAppSelector(selectSavedLogo);
  const savedGalleryPhoto = useAppSelector(selectSavedGalleryPhoto);

  const validateFile = (file: File | undefined): boolean => {
    if (!file) {
      return false;
    }

    if (file.size > 2000000) {
      alert("Size of the image is too big.");
      return false;
    }

    return true;
  };

  const resetLogoDialog = () => {
    if (logoInputRef.current) {
      logoInputRef.current.value = ""; // Reset the file input value
    }
  };

  const onLogoChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];

    const isValidFile: boolean = validateFile(file);

    if (!isValidFile) {
      return;
    }

    const response = await dispatch(
      saveLogo({ companyId: company.general.companyId, imageFile: file })
    );

    if (!response.payload) {
      return;
    }

    const savedLogo = { ...(response.payload as Logo) };

    resetLogoDialog();
  };

  const onLogoRemove = async () => {
    await dispatch(deleteLogo({ companyId: company.general.companyId }));
    resetLogoDialog();
  };

  const removeGalleryPhotoAtIndex = async (photoFileName: string) => {
    await dispatch(deleteGalleryPhoto({ photoPath: photoFileName }));

    dispatch(removeGalleryPhoto(photoFileName));

    resetGalleryPhotosDialog();
  };

  const resetGalleryPhotosDialog = () => {
    if (galleryPhotosInputRef.current) {
      galleryPhotosInputRef.current.value = ""; // Reset the file input value
    }
  };

  const onGalleryPhotosChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (!files) {
      return;
    }

    const fileArray = Array.from(files).filter((x) => validateFile(x));

    const request: GalleryPhotoForSaveCompany = {
      companyId: company.general.companyId,
      galleryPhotos: [...fileArray],
    };

    const response = await dispatch(saveGalleryPhoto(request));

    if (!response.payload) {
      return;
    }

    const savedGalleryPhoto = {
      ...(response.payload as GalleryPhotoForSaveCompanyResponse),
    };

    resetGalleryPhotosDialog();
  };

  return (
    <>
      <Grid item md={12} my={2}>
        <Typography
          variant={"h5"}
          sx={{ color: colors.primary, fontWeight: "bold" }}
        >
          Logotip i galerija
        </Typography>
      </Grid>
      <Box
        sx={{
          background: "#F3F3F3",
          boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "20px" }}
        >
          <Grid my={2}>
            <Typography sx={{ color: colors.primary, fontWeight: "bold" }}>
              Dodaj logotip firme/preduzeća
            </Typography>
          </Grid>
          <Grid item md={12} xs={12} textAlign={"center"}>
            <input
              type="file"
              accept="image/*"
              onChange={onLogoChange}
              style={{ display: "none" }}
              id="logo-upload"
              ref={logoInputRef}
            />
            {!savedLogo?.imageFileName && (
              <label htmlFor="logo-upload">
                <AppButton
                  component="span"
                  label={"Dodaj logo"}
                  color={colors.primary}
                  hover={colors.primary}
                  startIcon={<PublishOutlinedIcon />}
                />
              </label>
            )}

            {savedLogo?.imageFileName && (
              <>
                <Box mt={2}>
                  <img
                    src={PoslovniImenikEnv.Prod + "/" + savedLogo.imageFileName}
                    alt="Dodata logo fotografija"
                    style={{
                      maxWidth: "250px",
                      maxHeight: "250px",
                    }}
                  />
                </Box>
                <Grid
                  container
                  mt={2}
                  display="flex"
                  textAlign={"center"}
                  justifyContent={"center"}
                >
                  <Grid item md={1}>
                    <AppButton
                      color={colors.red}
                      hover={colors.red}
                      label={"Obrisi"}
                      onClick={onLogoRemove}
                      width="100%"
                    />
                  </Grid>
                  <Grid item md={1} ml={1}>
                    <AppButton
                      type="submit"
                      color={colors.green}
                      hover={colors.green}
                      label="Promeni"
                      onClick={() => logoInputRef.current?.click()}
                      width="100%"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.boxWrapper} mt={2}>
        <Grid container justifyContent="center" alignItems="center" sx={{}}>
          <Grid item md={12} xs={12} textAlign={"center"}>
            <Grid item md={12}>
              <CollectionsOutlinedIcon
                className={classes.collectionsOutlinedIcon}
              />
              <Typography className={classes.galleryTitle}>
                Galerija fotografija
              </Typography>
              <Typography className={classes.gallerySubtitle}>
                Dodajte fotografije u vašu galeriju
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Button
                variant="contained"
                component="label"
                className={classes.addGalleryButton}
              >
                Dodaj fotografije
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  style={{ display: "none" }}
                  onChange={onGalleryPhotosChange}
                  ref={galleryPhotosInputRef}
                />
              </Button>
            </Grid>

            <Grid container spacing={3} justifyContent="center" sx={{ mt: 2 }}>
              {savedGalleryPhoto?.galleryPhotos.map((x, i) => (
                <Grid key={i} item xs={12} sm={6} md={4} lg={3} display="flex">
                  <div style={{ position: "relative" }}>
                    <img
                      src={PoslovniImenikEnv.Prod + "/" + x.galleryPhotoFileName}
                      alt={"Dodata fotografija galerije br. " + (i + 1)}
                      className={classes.galleryImage}
                    />
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-5px",
                        border: "1px solid gray",
                        backgroundColor: "white",
                      }}
                      size="small"
                      color="error"
                      onClick={async () => {
                        await removeGalleryPhotoAtIndex(x.galleryPhotoFileName);
                      }}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
