import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";

export const useWorkingHoursModalStyles = makeStyles()((theme: Theme) => ({
    inputTitle: { color: colors.inputLabel, textAlign: "start" },
  boxWrapper: {
    background: "#F3F3F3",
    boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
    padding: "20px",
  },
  autocompleteTextField: {
    "& .MuiInputBase-input": {
      marginTop: "-0.2rem",
      height: "1.6rem",
    },
    "& .MuiInputBase-root": {
      height: "2.5rem",
    },
  },
  autocomplete: {
    backgroundColor: colors.white,
    borderRadius: "10px",
    overflow: "hidden",
    "& .MuiOutlinedInput-input": {
      height: "40px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
  },
  checkbox: {
    color: colors.inputTitle,
  },
  bannerTitle: {
    color: colors.primary,
    fontWeight: "bold",
  },
  bannerSubtitle: {
    color: "#747474",
    fontWeight: "bold",
  },
  logoImg: {
    maxWidth: "250px",
    maxHeight: "250px",
  },
  checkboxWrapper: {
    display: "flex",
  },
  subtitle: {
    color: colors.inputTitle,
    fontWeight: "bold",
  },
  dateInput: {
    background: "white",
    height: "80%",
  },
  deleteIcon: {
    fontSize: 30,
    marginLeft: "10px",
    color: colors.red,
    cursor: "pointer",
  },
  addIcon: {
    fontSize: 30,
    marginLeft: "10px",
    color: colors.icons.controlPointIcon,
    cursor: "pointer",
  },
  dayButtonContainer: {
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  marginBottom: "16px",
},
inputWrapper: {
  display: "flex",
  alignItems: "center",
  marginTop: "5px",
},
}));
