import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Advertisements } from "../app/redux/adminPanelApi";
import { RootState } from "../app/redux/store";
import { BannerPhotoResolution } from "../models/advertisements/bannerPhotoResolution";

type AdvertisementState = {
  bannerPhotoResolutions: BannerPhotoResolution[] | null;
};

const initialState: AdvertisementState = {
  bannerPhotoResolutions: null,
};

export const getAllBannerPhotoResolutions = createAsyncThunk(
  "Advertisement/Get_All_Banner_Photo_Resolutions",
  async (_: void) => {
    const response = await Advertisements.GetAllBannerPhotoResolutions();
    return response.data;
  }
);

const advertisementSlice = createSlice({
  name: "advertisement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllBannerPhotoResolutions.fulfilled, (state, action) => {
      state.bannerPhotoResolutions = action.payload;
    });
  },
});

export const selectBannerPhotoResolutions = (state: RootState) =>
  state.advertisement.bannerPhotoResolutions;

export default advertisementSlice.reducer;
