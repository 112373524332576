import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import { colors } from '../../app/theme/colors';
import { IconButton, Tooltip } from '@mui/material';

type Props = {
    onClick: any;
    tooltipTitle?: string;
    disabled?: boolean;
};

export default function AddCircleIconButton({ onClick, tooltipTitle, disabled }: Props) {
    return (
        <Tooltip title={tooltipTitle}>
            <IconButton
                size="large"
                sx={{ p: 0.5, ml: 1, cursor: disabled ? "default" : "pointer" }}
                onClick={disabled ? () => { } : onClick}
            >
                <AddCircleTwoToneIcon
                    sx={{
                        fontSize: "1.1em",
                        color: disabled ? "lightgray" : colors.green
                    }}
                />
            </IconButton>
        </Tooltip>
    );
}