import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { colors } from "../../../app/theme/colors";
import { Convert12to24Time } from "../../../common/helpers/helpers";
import {
  AreAllEmailsValid,
  AreAllPhonesValid,
  AreAllWebsitesValid,
  IsCompanyCategoryValid,
  IsCompanyCountryValid,
  IsCompanyNameValid,
  IsCompanyPhoneNumberValid,
  IsCompanyPhoneTypeValid,
  IsCompanyPlaceValid,
  IsCompanyStreetValid,
} from "../../../common/validations/generalSectionValidations";
import AppButton from "../../../components/appButton/appButton";
import BasicInput from "../../../components/basicInput/basicInput";
import AddIconButton from "../../../components/icons/AddIconButton";
import RemoveIconButton from "../../../components/icons/RemoveIconButton";
import { CityModel } from "../../../models/address/cityModel";
import { CountryModel } from "../../../models/address/countryModel";
import { PlaceModel } from "../../../models/address/placeModel";
import { StreetModel } from "../../../models/address/streetModel";
import { CategoryShortDetail } from "../../../models/categories/categoryShortDetail";
import { CompanyStepper } from "../../../models/companies/saveCompany/companyStepper";
import { EmailForSaveCompany } from "../../../models/companies/saveCompany/general/emailForSaveCompany";
import { PhoneForSaveCompany } from "../../../models/companies/saveCompany/general/phoneForSaveCompany";
import { WebsiteForSaveCompany } from "../../../models/companies/saveCompany/general/websiteForSaveCompany";
import WorkingDays from "../../../models/days/workingDays";
import WorkingDaysType from "../../../models/days/workingDaysType";
import WorkingDaysView from "../../../models/days/workingDaysView";
import {
  getAllCountries,
  getPlacesByCountryId,
  getStreetsByPlaceId,
  selectAllCountries,
  selectPlacesByCountryId,
  selectStreetsByPlaceId
} from "../../../slice/addressSlice";
import { selectDays } from "../../../slice/companySlice";
import WorkingHoursModal from "../../modals/workingHoursModal";
import {
  EmptyGuid,
  INIT_EMAIL,
  INIT_PHONE,
  INIT_WEBSITE
} from "../../stepper/initialData";
import { useGeneralDataSectionStyles } from "./generalDataSectionStyles";
import SelectCategories from "./selectCategories/SelectCategories";
import SelectStarRating from "./selectStarRating/SelectStarRating";
import { useBasicInputStyles } from "../../../components/basicInput/basicInputStyles";
import AddressModal from "../../modals/address/AddressModal";
import EditCategoryModal from "../../modals/category/EditCategoryModal";

interface Props {
  company: CompanyStepper;
  setCompany: Dispatch<SetStateAction<CompanyStepper>>;
  categories: CategoryShortDetail[];
  setCategories: Dispatch<SetStateAction<CategoryShortDetail[]>>;
  categoryShortDetails: CategoryShortDetail[];
  country: CountryModel | null;
  setCountry: Dispatch<SetStateAction<CountryModel | null>>;
  place: PlaceModel | null;
  setPlace: Dispatch<SetStateAction<PlaceModel | null>>;
  street: StreetModel | null;
  setStreet: Dispatch<SetStateAction<StreetModel | null>>;
  validate: boolean;
}

export default function GeneralDataSection({
  company,
  setCompany,
  categories,
  setCategories,
  categoryShortDetails,
  country,
  setCountry,
  place,
  setPlace,
  street,
  setStreet,
  validate,
}: Props) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { classes } = useGeneralDataSectionStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const countries = useAppSelector(selectAllCountries);
  const placesByCountryId = useAppSelector(selectPlacesByCountryId);
  const streetsByPlaceId = useAppSelector(selectStreetsByPlaceId);
  const { classes: basicInputStyles } = useBasicInputStyles();

  const hasStarRating = categories.find((x) => x.hasStarRating);

  const [isOpenAddressModal, setIsOpenAddressModal] = useState<boolean>(false);
  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState<boolean>(false);
  const [editCountry, setEditCountry] = useState<CountryModel | null>(country);
  const [editPlace, setEditPlace] = useState<PlaceModel | null>(null);
  const [editStreet, setEditStreet] = useState<StreetModel | null>(null);

  const handleAutocompleteChange = (
    type: string,
    newValue: CountryModel | CityModel | PlaceModel | StreetModel | null
  ) => {

    switch (type) {
      case "country":
        if (newValue) {
          setCountry(newValue as CountryModel);
          dispatch(getPlacesByCountryId(newValue.id));
        } else {
          setCountry(null);
        }
        setPlace(null);
        setStreet(null);
        break;

      case "place":
        if (newValue) {
          setPlace(newValue as PlaceModel);
          dispatch(getStreetsByPlaceId(newValue.id));
        } else {
          setPlace(null);
        }
        setStreet(null);

        break;

      case "street":
        let streetId: string = "";

        streetId = newValue === null ? EmptyGuid : newValue.id;

        if (newValue) {
          setStreet(newValue as StreetModel);
        } else {
          setStreet(null);
        }

        setCompany({
          ...company,
          general: {
            ...company.general,
            streetId: streetId,
          },
        });
        break;

      default:
        break;
    }
  };

  const allDays = useAppSelector(selectDays);

  const [openWorkingHoursModal, setOpenWorkingHoursModal] = useState(false);
  const [days, setDays] = useState(allDays);
  const [workingDaysView, setWorkingDeysView] = useState<WorkingDaysView[]>([]);

  const handleWorkingHoursModaClose = (): void => {
    setOpenWorkingHoursModal(false);
  };

  const handleWorkingHoursModalOpen = (type: WorkingDaysType): void => {
    handleSelectWorkingDays(type);
    setOpenWorkingHoursModal(true);
  };

  useEffect(() => {
    setDays(allDays);
    const initWorkingDaysView = getInitWorkingDaysView();
    setWorkingDeysView([...initWorkingDaysView]);
  }, [allDays]);

  const getInitWorkingDaysView = (): WorkingDaysView[] => {
    const newWorkingDaysViews: WorkingDaysView[] = [];
    let newWorkingDayView: WorkingDaysView = { day: "", id: "", time: "" };
    allDays?.forEach((day) => {
      switch (day.orderNumber) {
        case 1:
          newWorkingDayView = { id: day.id, day: "Ponedjeljak", time: "" };
          break;
        case 2:
          newWorkingDayView = { id: day.id, day: "Utorak", time: "" };
          break;
        case 3:
          newWorkingDayView = { id: day.id, day: "Srijeda", time: "" };
          break;
        case 4:
          newWorkingDayView = { id: day.id, day: "Četvrtak", time: "" };
          break;
        case 5:
          newWorkingDayView = { id: day.id, day: "Petak", time: "" };
          break;
        case 6:
          newWorkingDayView = { id: day.id, day: "Subota", time: "" };
          break;
        case 7:
          newWorkingDayView = { id: day.id, day: "Nedjelja", time: "" };
          break;
      }
      newWorkingDaysViews.push(newWorkingDayView);
    });

    return newWorkingDaysViews;
  }

  const handleSelectWorkingDays = (type: WorkingDaysType) => {
    const newDays: WorkingDays[] = [];

    days?.forEach((day) => {
      const newDay = { ...day, selected: false };
      switch (type) {
        case WorkingDaysType.AllDays:
          newDay.selected = true;
          break;
        case WorkingDaysType.Weekdays:
          if (day.orderNumber !== 6 && day.orderNumber !== 7) {
            newDay.selected = true;
          }
          break;
        case WorkingDaysType.Weekend:
          if (day.orderNumber === 6 || day.orderNumber === 7) {
            newDay.selected = true;
          }
          break;
      }
      newDays.push(newDay);
    });

    setDays(newDays);
  };

  const onChangeData = (
    value: string | boolean,
    name: keyof (PhoneForSaveCompany &
      WebsiteForSaveCompany &
      EmailForSaveCompany),
    index: number,
    type: "phone" | "web" | "email"
  ) => {
    let updatedData;
    switch (type) {
      case "phone":
        const updated = [...company.general.phones];
        updated[index] = { ...updated[index], [name]: value };
        setCompany({
          ...company,
          general: { ...company.general, phones: updated },
        });

        break;

      case "web":
        updatedData = [...company.general.websites].map((web, i) => {
          if (i === index) {
            return { ...web, [name]: value };
          }
          return web;
        });
        setCompany({
          ...company,
          general: { ...company.general, websites: updatedData },
        });
        break;

      case "email":
        updatedData = [...company.general.emails].map((email, i) => {
          if (i === index) {
            return { ...email, [name]: value };
          }
          return email;
        });
        setCompany({
          ...company,
          general: { ...company.general, emails: updatedData },
        });
        break;

      default:
        break;
    }
  };

  const addEmptyData = (dataType: string) => {
    let newEmptyData;
    switch (dataType) {
      case "phones":
        newEmptyData = { ...INIT_PHONE };
        setCompany({
          ...company,
          general: {
            ...company.general,
            phones: [...company.general.phones, newEmptyData],
          },
        });
        break;

      case "websites":
        newEmptyData = { ...INIT_WEBSITE };
        setCompany({
          ...company,
          general: {
            ...company.general,
            websites: [...company.general.websites, newEmptyData],
          },
        });
        break;

      case "emails":
        newEmptyData = { ...INIT_EMAIL };
        setCompany({
          ...company,
          general: {
            ...company.general,
            emails: [...company.general.emails, newEmptyData],
          },
        });
        break;

      default:
        return;
    }
  };

  const onRemoveData = (index: number, dataType: string) => {
    let updatedData;
    switch (dataType) {
      case "phones":
        updatedData = [...company.general.phones].filter(
          (phone, i) => i !== index
        );
        setCompany({
          ...company,
          general: { ...company.general, phones: [...updatedData] },
        });
        break;

      case "websites":
        updatedData = [...company.general.websites].filter(
          (website, i) => i !== index
        );
        setCompany({
          ...company,
          general: { ...company.general, websites: [...updatedData] },
        });
        break;

      case "emails":
        updatedData = [...company.general.emails].filter(
          (email, i) => i !== index
        );
        setCompany({
          ...company,
          general: { ...company.general, emails: [...updatedData] },
        });
        break;

      default:
        return;
    }
  };

  const resetCompanyWorkingHours = (): WorkingDaysView[] => {
    const newWorkingDaysViews: WorkingDaysView[] = [];

    const workingDays = workingDaysView.length > 0 ? workingDaysView : getInitWorkingDaysView();

    workingDays.forEach((day) => {
      const id = day.id;
      const name = day.day;
      var newDay: WorkingDaysView = { id: id, day: name, time: "" };
      newWorkingDaysViews.push(newDay);
    });

    return newWorkingDaysViews;
  };

  const onOpenAddressModal = () => {
    setIsOpenAddressModal(true);
  };

  const onCloseAddressModal = async () => {
    // Changed country
    if (country?.id !== editCountry?.id) {
      if (country) {
        dispatch(getPlacesByCountryId(country.id));
      }
      if (place) {
        dispatch(getStreetsByPlaceId(place.id));
      }
      setIsOpenAddressModal(false);
      return;
    }

    // Same place
    if (place?.id === editPlace?.id) {
      if (country) {
        dispatch(getPlacesByCountryId(country.id));
      }
      setPlace(editPlace);
    } else {
      if (country) {
        const placesResponse = await dispatch(getPlacesByCountryId(country.id));
        if (placesResponse.payload) {
          const exists = (placesResponse.payload as PlaceModel[])?.find(x => x.id === place?.id);
          if (!exists) {
            setPlace(null);
            setStreet(null);
            setIsOpenAddressModal(false);
            return;
          }

          setPlace(exists);
        }
      }
    }

    // Same street
    if (street?.id === editStreet?.id) {
      if (place) {
        dispatch(getStreetsByPlaceId(place.id));
      }
      setStreet(editStreet);
    } else {
      if (place) {
        const streetsResponse = await dispatch(getStreetsByPlaceId(place.id));
        if (streetsResponse.payload) {
          const exists = (streetsResponse.payload as StreetModel[])?.find(x => x.id === street?.id);
          if (!exists) {
            setStreet(null);
            setIsOpenAddressModal(false);
            return;
          }

          setStreet(exists);
        }
      }
    }

    setIsOpenAddressModal(false);
  };

  const onOpenCategoryModal = () => {
    setIsOpenCategoryModal(true);
  };

  const onCloseCategoryModal = () => {
    setIsOpenCategoryModal(false);
  };

  const setCompanyCategories = (categories: CategoryShortDetail[]) => {
    setCategories([...categories]);

    const ids = categories.map((x) => x.id);

    setCompany({
      ...company,
      general: {
        ...company.general,
        categoryIds: ids,
      },
    });
  };

  useEffect(() => {
    dispatch(getAllCountries());
  }, []);

  useEffect(() => {
    var companyWorkingHoursByDays = [...(company.general.workingTimes ?? [])];
    var newWorkingDaysViews: WorkingDaysView[] = resetCompanyWorkingHours();

    const daysView = workingDaysView.length > 0 ? workingDaysView : getInitWorkingDaysView();

    if (companyWorkingHoursByDays.length > 0) {
      companyWorkingHoursByDays.forEach((item) => {
        var workingDaysViewIndex = daysView.findIndex(
          (d) => d.id === item.dayId
        );

        if (workingDaysViewIndex !== -1) {
          let newWorkingDay: WorkingDaysView = { ...newWorkingDaysViews[workingDaysViewIndex] };

          let time = newWorkingDay.time.length > 0 ? newWorkingDay.time + " i " : "";

          if (item.isClosed === true) {
            time = "Zatvoreno";
          } else if (item.isOpen24Hours === true) {
            time = "Otvoreno 24h";
          } else if (item.fromTime !== null && item.fromTime.length > 0 && item.toTime !== null && item.toTime.length > 0) {
            time += (Convert12to24Time(item.fromTime) + "-" + Convert12to24Time(item.toTime));
          }
          newWorkingDay.time = time;
          newWorkingDaysViews[workingDaysViewIndex] = newWorkingDay;
        }
      });
    }

    if (newWorkingDaysViews.length > 0) {
      setWorkingDeysView(newWorkingDaysViews);
    }
  }, [company.general.workingTimes]);

  return (
    <>
      <Grid item md={12} my={2}>
        <Typography variant={"h5"} className={classes.title}>
          Unos generalnih podataka
        </Typography>
      </Grid>
      <Box className={classes.boxContainer} mb={2}>
        <Grid>
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item md={6} sm={12} xs={12}>
              <SelectCategories
                categoryShortDetails={categoryShortDetails}
                categories={categories}
                setCompanyCategories={setCompanyCategories}
                validate={validate}
              />

              {validate && !IsCompanyCategoryValid(categories) && <br />}

              <span
                onClick={onOpenCategoryModal}
                style={{
                  textDecoration: "underline",
                  color: colors.primary,
                  cursor: "pointer",
                  fontSize: "0.9em",
                  marginLeft: 5
                }}
              >
                Uredi djelatnosti
              </span>
            </Grid>

            {hasStarRating && (
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <SelectStarRating company={company} setCompany={setCompany} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.boxContainer}>
        <Grid>
          <Grid container spacing={2}>
            <Grid item md={5} xs={12}>
              <BasicInput
                label={"Naziv firme"}
                height={"40px"}
                boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.3)"}
                multiline={false}
                value={company.general.name}
                onChange={(e) =>
                  setCompany({
                    ...company,
                    general: { ...company.general, name: e.target.value },
                  })
                }
                error={validate && !IsCompanyNameValid(company.general.name)}
                errorMessage={"Naziv firme je obavezan."}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControl sx={{ ml: 2 }}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Premium"
                  checked={company.general.isPremium}
                  onChange={(e, checked) =>
                    setCompany({
                      ...company,
                      general: { ...company.general, isPremium: checked },
                    })
                  }
                  style={{ color: colors.sectionTitle }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item md={3} xs={12}>
              <Autocomplete
                options={countries ?? []}
                value={country}
                onChange={(event, newValue) =>
                  handleAutocompleteChange("country", newValue)
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Država"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={validate && !IsCompanyCountryValid(country)}
                  />
                )}
                className={classes.autocomplete}
                sx={{
                  boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
                }}
              />

              {validate && !IsCompanyCountryValid(country) && <span className={basicInputStyles.errorMessage}>Država je obavezna.</span>}

              {validate && !IsCompanyCountryValid(country) && <br />}

              {<span
                onClick={onOpenAddressModal}
                style={{
                  textDecoration: "underline",
                  color: colors.primary,
                  cursor: "pointer",
                  fontSize: "0.9em",
                  marginLeft: 5
                }}
              >
                Uredi adrese
              </span>}
            </Grid>
            <Grid item md={3} xs={12}>
              <Autocomplete
                options={placesByCountryId ?? []}
                getOptionLabel={(option) => option.name}
                sx={{
                  boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
                }}
                value={place}
                onChange={(event, newValue) =>
                  handleAutocompleteChange("place", newValue)
                }
                disabled={!country}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Mjesto"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={validate && !IsCompanyPlaceValid(place)}
                  />
                )}
                className={classes.autocomplete}
              />
              {validate && !IsCompanyPlaceValid(place) && <span className={basicInputStyles.errorMessage}>Mjesto je obavezno.</span>}
            </Grid>
            <Grid item md={2} xs={12}>
              <Autocomplete
                options={streetsByPlaceId ?? []}
                value={street}
                onChange={(event, newValue) => {
                  handleAutocompleteChange("street", newValue);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disabled={!place}
                getOptionLabel={(option) => option.name}
                sx={{
                  boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ulica"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={validate && !IsCompanyStreetValid(street)}
                  />
                )}
                className={classes.autocomplete}
              />
              {validate && !IsCompanyStreetValid(street) && <span className={basicInputStyles.errorMessage}>Ulica je obavezna.</span>}
            </Grid>
            <Grid item md={1} xs={12}>
              <BasicInput
                label={"Broj"}
                height={"40px"}
                boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.1)"}
                multiline={false}
                value={company.general.homeNumber}
                onChange={(e) =>
                  setCompany({
                    ...company,
                    general: { ...company.general, homeNumber: e.target.value },
                  })
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.boxContainer} mt={2}>
        <Grid>
          <Grid item md={12} my={1}>
            <Typography
              variant={"h5"}
              sx={{ color: "#747474", fontWeight: "bold" }}
            >
              Kontakt
            </Typography>
          </Grid>
          <Grid
            container
            spacing={1}
            mt={1}
            sx={{
              background: company.general.phones.length > 1 ? "#E8EDF6" : null,
              padding: company.general.phones.length > 1 ? "20px" : null,
            }}
          >
            <Grid container>
              <Grid item md={8} sm={12} xs={12} display="flex" flexDirection="column">
                {company.general.phones.map((phone, i) => (
                  <div
                    key={i}
                    className={classes.inputWrapper}
                    style={{ marginBottom: isMobile ? 30 : 10 }}
                  >
                    <Grid item md={9} sm={12} xs={12}>
                      <Grid
                        container
                        display="flex"
                        alignItems={"center"}
                        rowSpacing={isMobile ? 2 : 0}
                        columnSpacing={isMobile ? 0 : 2}
                      >
                        <Grid item md={6} sm={12} xs={12}>
                          <BasicInput
                            label={"Naziv broja telefona"}
                            multiline={false}
                            value={phone.typeName}
                            onChange={(e) =>
                              onChangeData(
                                e.target.value,
                                "typeName",
                                i,
                                "phone"
                              )
                            }
                            error={validate && !IsCompanyPhoneTypeValid(phone)}
                            errorMessage={"Naziv broja telefona je obavezan."}
                          />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <BasicInput
                            label={"Telefon"}
                            multiline={false}
                            value={phone.phoneNumber}
                            onChange={(e) =>
                              onChangeData(
                                e.target.value,
                                "phoneNumber",
                                i,
                                "phone"
                              )
                            }
                            error={validate && !IsCompanyPhoneNumberValid(phone)}
                            errorMessage={"Telefon je obavezan."}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                      {company.general.phones.length > 1 && (
                        <RemoveIconButton
                          onClick={() => onRemoveData(i, "phones")}
                        />
                      )}
                      {i === company.general.phones.length - 1 &&
                        AreAllPhonesValid(company.general.phones) && (
                          <AddIconButton
                            onClick={() => addEmptyData("phones")}
                          />
                        )}
                    </Grid>
                  </div>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            mt={1}
            sx={{
              background:
                company.general.websites.length > 1 ? "#E8EDF6" : null,
              padding: company.general.websites.length > 1 ? "20px" : null,
            }}
          >
            <Grid container>
              <Grid item md={8} sm={12} xs={12} display="flex" flexDirection="column">
                {company.general.websites.map((web, i) => (
                  <div
                    key={i}
                    className={classes.inputWrapper}
                    style={{
                      marginBottom: i !== company.general.websites.length - 1
                        && isMobile ? 30 : 10
                    }}
                  >
                    <Grid item md={9} sm={12} xs={12}>
                      <BasicInput
                        value={web.url}
                        onChange={(e) =>
                          onChangeData(e.target.value, "url", i, "web")
                        }
                        label={"Web sajt"}
                        multiline={false}
                      />
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                      {company.general.websites.length > 1 && (
                        <RemoveIconButton
                          onClick={() => onRemoveData(i, "websites")}
                        />
                      )}
                      {i === company.general.websites.length - 1 &&
                        AreAllWebsitesValid(company.general.websites) && (
                          <AddIconButton
                            onClick={() => addEmptyData("websites")}
                          />
                        )}
                    </Grid>
                  </div>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            mt={1}
            sx={{
              background: company.general.emails.length > 1 ? "#E8EDF6" : null,
              padding: company.general.emails.length > 1 ? "20px" : null,
            }}
          >
            <Grid container>
              <Grid item md={8} sm={12} xs={12} display="flex" flexDirection="column">
                {company.general.emails.map((email, i) => (
                  <div
                    key={i}
                    className={classes.inputWrapper}
                    style={{ marginBottom: isMobile ? 30 : 10 }}
                  >
                    <Grid item md={9} sm={12} xs={12}>
                      <Grid
                        container
                        display="flex"
                        alignItems={"center"}
                        spacing={1}
                        rowSpacing={isMobile ? 2 : 0}
                        columnSpacing={isMobile ? 0 : 2}
                      >
                        <Grid item md={6} sm={12} xs={12}>
                          <BasicInput
                            label={"Naziv e-mail"}
                            multiline={false}
                            value={email.typeName}
                            onChange={(e) =>
                              onChangeData(
                                e.target.value,
                                "typeName",
                                i,
                                "email"
                              )
                            }
                          />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <BasicInput
                            label={"E-mail"}
                            multiline={false}
                            value={email.emailAddress}
                            onChange={(e) =>
                              onChangeData(
                                e.target.value,
                                "emailAddress",
                                i,
                                "email"
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                      {company.general.emails.length > 1 && (
                        <RemoveIconButton
                          onClick={() => onRemoveData(i, "emails")}
                        />
                      )}
                      {i === company.general.emails.length - 1 &&
                        AreAllEmailsValid(company.general.emails) && (
                          <AddIconButton
                            onClick={() => addEmptyData("emails")}
                          />
                        )}
                    </Grid>
                  </div>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{ background: "#F3F3F3", width: isMobile ? "100%" : "35%" }}
        mt={2}
      >
        <Grid sx={{ padding: "20px" }}>
          <Grid item md={12} xs={12}>
            <Typography
              variant={"h5"}
              sx={{ color: "#747474", fontWeight: "bold" }}
            >
              Radno vrijeme
            </Typography>
          </Grid>
          <Divider />
          <Grid container spacing={2} mt={0.5}>
            {workingDaysView.map((day) => (
              <Grid
                key={day.id}
                item
                md={12}
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <Typography fontWeight={"bold"}>{day.day}</Typography>
                </Grid>
                <Grid>
                  <Typography>{day.time}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2} mt={2}>
            <Grid item md={4}>
              <AppButton
                label={"Uredi sve dane"}
                color={colors.primary}
                hover={colors.primary}
                onClick={(e: any) =>
                  handleWorkingHoursModalOpen(WorkingDaysType.AllDays)
                }
              />
            </Grid>
            <Grid item md={4}>
              {" "}
              <AppButton
                label={"Uredi Pon-Pet"}
                color={colors.primary}
                hover={colors.primary}
                onClick={(e: any) =>
                  handleWorkingHoursModalOpen(WorkingDaysType.Weekdays)
                }
              />
            </Grid>
            <Grid item md={4}>
              {" "}
              <AppButton
                label={"Uredi Sub-Ned"}
                color={colors.primary}
                hover={colors.primary}
                onClick={(e: any) =>
                  handleWorkingHoursModalOpen(WorkingDaysType.Weekend)
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <WorkingHoursModal
          open={openWorkingHoursModal}
          onClose={handleWorkingHoursModaClose}
          company={company}
          setCompany={setCompany}
          workingDays={days}
          setWorkingDays={setDays}
        />
        <AddressModal
          isOpen={isOpenAddressModal}
          onClose={onCloseAddressModal}
          country={country}
          selectedCountry={editCountry}
          setSelectedCountry={setEditCountry}
          selectedPlace={editPlace}
          setSelectedPlace={setEditPlace}
          selectedStreet={editStreet}
          setSelectedStreet={setEditStreet}
        />
        <EditCategoryModal
          isOpen={isOpenCategoryModal}
          onClose={onCloseCategoryModal}
          setCompanyCategories={setCompanyCategories}
          selectedCategoryIdsForm={company.general.categoryIds}
        />
      </Box>
    </>
  );
}
