import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../../app/theme/colors";

export const useEditCategoryModalStyles = makeStyles()((theme: Theme) => ({
    autocomplete: {
        backgroundColor: colors.white,
        borderRadius: "10px",
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: "10px",
        },
    },
}));
