import PlaceIcon from "@mui/icons-material/Place";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useAppSelector } from "../../../app/redux/hooks";
import {
  AreAllLocationsValid,
  AreAllSocialMediasValid,
} from "../../../common/validations/contactSectionValidations";
import BasicInput from "../../../components/basicInput/basicInput";
import AddIconButton from "../../../components/icons/AddIconButton";
import RemoveIconButton from "../../../components/icons/RemoveIconButton";
import { CompanyStepper } from "../../../models/companies/saveCompany/companyStepper";
import { LocationForSaveCompany } from "../../../models/companies/saveCompany/contact/locationForSaveCompany";
import { SocialMediaGrouped } from "../../../models/companies/saveCompany/contact/socialMediaGrouped";
import { selectSocialMediaTypes } from "../../../slice/contactSlice";
import { EmptyGuid, INIT_LOCATION } from "../../stepper/initialData";
import { useSocialMediaSectionStyles } from "./socialMediaSectionStyles";
import { FacebookRounded, Instagram, LinkedIn, Twitter, YouTube } from "@mui/icons-material";
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  company: CompanyStepper;
  setCompany: Dispatch<SetStateAction<CompanyStepper>>;
  socialMedias: SocialMediaGrouped[];
  setSocialMedias: (socialMedias: SocialMediaGrouped[]) => void;
}

function sortByOrderNumberProp(list: SocialMediaGrouped[]): SocialMediaGrouped[] {
  return list.sort((a, b) => a.orderNumber - b.orderNumber);
}

export default function SocialeMediaSection({
  company,
  setCompany,
  socialMedias,
  setSocialMedias,
}: Props) {
  const { classes } = useSocialMediaSectionStyles();
  const socialMediaTypes = useAppSelector(selectSocialMediaTypes);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const currentTypeIds = socialMedias.map(x => x.typeId);
    if (!socialMediaTypes) {
      return;
    }
    const missingTypes = socialMediaTypes.filter(x => !currentTypeIds.includes(x.id));
    const addedMissing: SocialMediaGrouped[] = missingTypes.map(x => ({
      typeId: x.id,
      typeName: x.name,
      orderNumber: x.orderNumber,
      socialMedias: [{ id: EmptyGuid, url: "" }]
    }));
    const combined = socialMedias.concat(addedMissing);
    const result = sortByOrderNumberProp(combined);

    let locations: LocationForSaveCompany[] = [];
    if (company.contact.locations.length === 0) {
      locations = [{ ...INIT_LOCATION }];
    } else {
      locations = [...company.contact.locations];
    }

    setCompany({
      ...company,
      contact: {
        ...company.contact,
        socialMedias: [...result],
        locations: [...locations]
      }
    })
  }, []);

  const handleUrlChange = (typeId: string, valueIndex: number, url: string) => {
    const updatedFormData = socialMedias.map((x) => {
      if (x.typeId === typeId) {
        const updatedSocialMedias = x.socialMedias.map((y, index) =>
          index === valueIndex ? { ...y, url: url } : y
        );
        return { ...x, socialMedias: updatedSocialMedias };
      }
      return x;
    });

    setSocialMedias(updatedFormData);
  };

  const handleAddUrl = (typeId: string) => {
    const updatedFormData = socialMedias.map((x) => {
      if (x.typeId === typeId) {
        const updatedValues = [...x.socialMedias, { id: EmptyGuid, url: "" }];
        return { ...x, socialMedias: [...updatedValues] };
      }
      return x;
    });

    setSocialMedias([...updatedFormData]);
  };

  const handleRemoveUrl = (typeId: string, valueIndex: number) => {
    const updatedFormData = socialMedias.map((x) => {
      if (x.typeId === typeId) {
        const updatedValues = x.socialMedias.filter((_, i) => i !== valueIndex);
        return { ...x, socialMedias: [...updatedValues] };
      }
      return x;
    });

    setSocialMedias([...updatedFormData]);
  };

  return (
    <>
      <Grid item md={12} my={2}>
        <Typography variant={"h5"} className={classes.title}>
          Socijalne mreže
        </Typography>
      </Grid>
      <Box className={classes.boxContainer}>
        <Grid
          container
          spacing={1}
          mt={1}
        >
          {socialMedias.map((x, i) => (
            <Grid container key={i} my={2} rowSpacing={isMobile ? 1 : 0}>
              <Grid item md={2} sm={12} xs={12} display="flex" alignItems={"center"}>
                <Grid container spacing={1}>
                  <Grid item display="flex" justifyContent="center">
                    {x.typeName === "Facebook" && <FacebookRounded className={classes.facebookIcon} />}
                    {x.typeName === "Instagram" && <Instagram className={classes.instagramIcon} />}
                    {x.typeName === "Twitter" && <Twitter className={classes.twitterIcon} />}
                    {x.typeName === "YouTube" && <YouTube className={classes.youtubeIcon} />}
                    {x.typeName === "LinkedIn" && <LinkedIn className={classes.linkedInIcon} />}
                    {x.typeName === "TikTok" && <FontAwesomeIcon icon={faTiktok} className={classes.tiktokIcon} />}
                  </Grid>
                  <Grid item display="flex" justifyContent="center">
                    <Typography className={classes.sectionTitle} display="flex" alignItems="center">
                      {x.typeName}:
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={8} xs={12} display="flex" flexDirection="column">
                {x.socialMedias.map((y, i) => (
                  <div key={i} className={classes.inputWrapper}>
                    <Grid item md={9} sm={9} xs={9}>
                      <BasicInput
                        type="text"
                        value={y.url}
                        onChange={(e) =>
                          handleUrlChange(x.typeId, i, e.target.value)
                        }
                        label={"Nalepite link"}
                        multiline={false}
                        placeholder={"http://"}
                      />
                    </Grid>
                    <Grid item md={3} sm={3} xs={3}>
                      {x.socialMedias.length > 1 && (
                        <RemoveIconButton
                          onClick={() => handleRemoveUrl(x.typeId, i)}
                        />
                      )}

                      {i === x.socialMedias.length - 1 &&
                        AreAllSocialMediasValid(x.socialMedias) && (
                          <AddIconButton
                            onClick={() => handleAddUrl(x.typeId)}
                          />
                        )}
                    </Grid>
                  </div>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Grid item md={12} my={2}>
        <Typography variant={"h5"} className={classes.title}>
          Lokacije
        </Typography>
      </Grid>
      <Box className={classes.boxContainer} mt={2}>
        <Grid
          container
          spacing={1}
          mt={1}
        >
          <Grid container rowSpacing={isMobile ? 1 : 0}>
            <Grid item md={2} sm={12} xs={12} display="flex" alignItems={"center"}>
              <PlaceIcon className={classes.mapsIcon} />
              <Typography className={classes.sectionTitle}>
                Google maps:
              </Typography>
            </Grid>
            <Grid item md={8} sm={12} xs={12} display="flex" flexDirection="column">
              <div className={classes.inputWrapper}>
                <Grid item md={9} sm={9} xs={9}>
                  <BasicInput
                    value={company.contact?.locations?.[0]?.urlCoordinates ?? ""}
                    onChange={(e) => {
                      let updatedLocations = [...company.contact.locations];

                      if (updatedLocations.length === 0) {
                        updatedLocations = [{ ...INIT_LOCATION }];
                      }

                      updatedLocations[0] = { ...updatedLocations[0], urlCoordinates: e.target.value }

                      setCompany({
                        ...company,
                        contact: {
                          ...company.contact,
                          locations: [...updatedLocations],
                        },
                      });
                    }}
                    label={"Lokacija"}
                    multiline={false}
                  />
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
