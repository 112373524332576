import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";

export const useLayoutStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: 0,
    position: "relative",
  },
  header: {
    width: "100%",
  },
}));
