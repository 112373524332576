import { Box, Grid, Typography } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { colors } from "../../../app/theme/colors";
import BasicInput from "../../../components/basicInput/basicInput";
import { CompanyStepper } from "../../../models/companies/saveCompany/companyStepper";
import { INIT_ALT_TEXT } from "../../stepper/initialData";

interface Props {
  company: CompanyStepper;
  setCompany: Dispatch<SetStateAction<CompanyStepper>>;
}

export default function DescriptionSection({ company, setCompany }: Props) {
  const shortDescriptionAllowedCharacters = 500;
  const longDescriptionAllowedCharacters = 1000;

  const onChangeDescription = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    propName: "shortDescription" | "longDescription"
  ) => {
    const { value } = e.target;

    const allowedCharacters =
      propName === "shortDescription"
        ? shortDescriptionAllowedCharacters
        : longDescriptionAllowedCharacters;

    let newValue = "";

    if (value.length <= allowedCharacters) {
      newValue = value;
    } else {
      newValue = value.slice(0, allowedCharacters);
    }

    setCompany({
      ...company,
      description: {
        ...company.description,
        description: {
          ...company.description.description,
          [propName]: newValue,
        },
      },
    })
  }

  return (
    <>
      <Grid item md={12} my={2}>
        <Typography
          variant={"h5"}
          sx={{ color: colors.primary, fontWeight: "bold" }}
        >
          Opis
        </Typography>
      </Grid>
      <Box
        sx={{
          background: "#F3F3F3",
          boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid sx={{ padding: "20px" }}>
          <Grid item md={12} xs={12}>
            <BasicInput
              label={"Kratak opis"}
              boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.1)"}
              inputTitleColor={colors.inputTitle}
              multiline={true}
              rows={3}
              count={shortDescriptionAllowedCharacters.toString()}
              value={company.description.description.shortDescription}
              onChange={(e) => onChangeDescription(e, "shortDescription")}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          background: "#F3F3F3",
          boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
        }}
        mt={2}
      >
        <Grid sx={{ padding: "20px" }}>
          <Grid item md={12} xs={12}>
            <BasicInput
              label={"Opis"}
              boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.1)"}
              inputTitleColor={colors.inputTitle}
              multiline={true}
              rows={7}
              value={company.description.description.longDescription}
              onChange={(e) => onChangeDescription(e, "longDescription")}
              count={longDescriptionAllowedCharacters.toString()}
              endAdornment={
                <Typography
                  sx={{ position: "absolute", bottom: 5, right: 5 }}
                  align="right"
                  variant="caption"
                >
                  {company.description.description.longDescription.length}/{longDescriptionAllowedCharacters}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          background: "#F3F3F3",
          boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
        }}
        mt={2}
      >
        <Grid sx={{ padding: "20px" }}>
          <Grid item md={12} xs={12}>
            <BasicInput
              label={"Alternativni tekst"}
              boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.1)"}
              inputTitleColor={colors.inputTitle}
              multiline={true}
              rows={9}
              value={company.description?.altText?.text}
              onChange={(e) =>
                setCompany({
                  ...company,
                  description: {
                    ...company.description,
                    altText: {
                      ...(company.description.altText ?? { ...INIT_ALT_TEXT }),
                      text: e.target.value,
                    },
                  },
                })
              }
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
