import { CountryModel } from "../../models/address/countryModel";
import { StreetModel } from "../../models/address/streetModel";
import { BannerPhotoResolution } from "../../models/advertisements/bannerPhotoResolution";
import { ApiResponse } from "../../models/app/apiResponse";
import { CategoryShortDetail } from "../../models/categories/categoryShortDetail";
import { CompanyForDashboard } from "../../models/companies/companyForDashboard";
import { AdvertisementSectionResponse } from "../../models/companies/saveCompany/advertisement/advertisementSectionResponse";
import { CompanyDetail } from "../../models/companies/companyDetail";
import { ContactSection } from "../../models/companies/saveCompany/contact/contactSection";
import { DescriptionSection } from "../../models/companies/saveCompany/description/descriptionSection";
import { GeneralSection } from "../../models/companies/saveCompany/general/generalSection";
import { DeleteGalleryPhotoCommand } from "../../models/companies/saveCompany/photos/deleteGalleryPhotoCommand";
import { DeleteLogoCommand } from "../../models/companies/saveCompany/photos/deleteLogoCommand";
import { GalleryPhotoForSaveCompany } from "../../models/companies/saveCompany/photos/galleryPhotoForSaveCompany";
import { GalleryPhotoForSaveCompanyResponse } from "../../models/companies/saveCompany/photos/galleryPhotoForSaveCompanyResponse";
import { Logo } from "../../models/companies/saveCompany/photos/logo";
import { LogoForSaveCompany } from "../../models/companies/saveCompany/photos/logoForSaveCompany";
import { SocialMediaType } from "../../models/contacts/socialMediaType";
import { adminApi, api } from "./api";
import { CompanyShortDetail } from "../../models/companies/companyShortDetail";
import { GeneralSectionResponse } from "../../models/companies/saveCompany/general/generalSectionResponse";
import WorkingDays from "../../models/days/workingDays";
import { PlaceModel } from "../../models/address/placeModel";
import { BannerForSaveCompany } from "../../models/companies/saveCompany/advertisement/bannerForSaveCompany";
import { TextAdvertisementForSaveCompany } from "../../models/companies/saveCompany/advertisement/textAdvertisementForSaveCompany";
import { BannerForSaveCompanyResponse } from "../../models/companies/saveCompany/advertisement/bannerForSaveCompanyResponse";
import { CompanyForDashboardRequest } from "../../models/companies/companyForDashboardRequest";
import { PagedResult } from "../../models/app/pagedResult";
import { LoginRequest } from "../../models/auth/loginRequest";
import { LoginResponse } from "../../models/auth/loginResponse";
import { CompanyFullDetailsModel } from "../../models/companies/companyDetails/companyFullDetailsModel";
import { CountStatistic } from "../../models/companies/countStatistic";
import { Place } from "../../models/address/place";
import { Street } from "../../models/address/street";
import { RequestForgotPasswordModel } from "../../models/users/requestForgotPasswordModel";
import { ForgotPasswordModel } from "../../models/users/forgotPasswordModel";
import { User } from "../../models/users/user";
import { SetUserActiveStatusModel } from "../../models/users/setUserActiveStatusModel";
import { ResetPasswordModel } from "../../models/users/resetPasswordModel";

export const Companies = {
  SaveGeneralData: async (general: GeneralSection) => {
    return api.put("/company/save-company-general-data", general) as Promise<
      ApiResponse<GeneralSectionResponse>
    >;
  },

  SaveDescriptionData: async (description: DescriptionSection) => {
    return api.put(
      "/company/save-company-description-data",
      description
    ) as Promise<ApiResponse<DescriptionSection>>;
  },

  SaveLogo: async (logo: LogoForSaveCompany) => {
    const formData = new FormData();
    formData.append("CompanyId", logo.companyId);
    formData.append("ImageFile", logo.imageFile!);

    return api.put("/company/save-company-logo", formData) as Promise<
      ApiResponse<Logo>
    >;
  },

  DeleteLogo: async (request: DeleteLogoCommand) => {
    return api.put("/company/delete-company-logo", request);
  },

  SaveGalleryPhotos: async (galleryPhoto: GalleryPhotoForSaveCompany) => {
    const formData = new FormData();

    formData.append("CompanyId", galleryPhoto.companyId);

    galleryPhoto.galleryPhotos.forEach((x) => {
      if (x) {
        formData.append("GalleryPhotoFiles", x);
      }
    });

    return api.put("/company/save-company-gallery-photos", formData) as Promise<
      ApiResponse<GalleryPhotoForSaveCompanyResponse>
    >;
  },

  DeleteGalleryPhoto: async (request: DeleteGalleryPhotoCommand) => {
    return api.put("/company/delete-company-gallery-photo", request) as Promise<ApiResponse<AdvertisementSectionResponse>>;
  },

  SaveBanner: async (banner: BannerForSaveCompany) => {
    const formData = new FormData();

    formData.append("CompanyId", banner.companyId);
    formData.append("Id", banner.id);
    formData.append("Url", banner.url);
    formData.append("ResolutionId", banner.resolutionId);

    if (banner.bannerPhotoFile) {
      formData.append("BannerPhotoFile", banner.bannerPhotoFile);
    }

    return api.put("/company/save-company-banner", formData) as
      Promise<ApiResponse<BannerForSaveCompanyResponse>>;
  },

  SaveTextAdvertisements: async (textAdvertisement: TextAdvertisementForSaveCompany) => {
    return api.put("/company/save-company-text-advertisements", textAdvertisement) as
      Promise<ApiResponse<TextAdvertisementForSaveCompany>>;
  },

  SaveContact: async (contact: ContactSection) => {
    return api.put("/company/save-company-contact-data", contact) as Promise<
      ApiResponse<ContactSection>
    >;
  },

  GetCompaniesForDashboard: async (request: CompanyForDashboardRequest) => {
    return api.post("/company/get-companies-for-dashboard", request) as Promise<
      ApiResponse<PagedResult<CompanyForDashboard>>
    >;
  },

  GetAllCompanyShortDetails: async () => {
    return api.get("/company/get-all-company-short-details") as Promise<ApiResponse<CompanyShortDetail[]>>;
  },

  GetCompanyDetailForEditById: async (id: string) => {
    return api.get("/company/get-company-detail-for-edit-by-id/" + id) as Promise<ApiResponse<CompanyDetail>>;
  },

  RemoveCompany: async (id: string) => {
    return api.delete("/company/remove-company/" + id) as Promise<ApiResponse<boolean>>;
  },

  GetCompanyDetail: async (id: string) => {
    return api.get("/company/get-company-detail-for-dashboard-by-id/" + id) as
      Promise<ApiResponse<CompanyFullDetailsModel>>;
  },

  GetCreatedCompaniesCountStatistic: async () => {
    return api.get("/company/get-created-companies-count-statistic") as Promise<ApiResponse<CountStatistic[]>>;
  }
};

export const Days = {
  GetAllDays: async () => {
    return api.get("/day/get-all-days") as Promise<ApiResponse<WorkingDays[]>>;
  }
}

export const ContactApi = {
  GetAllSocialMediaTypes: async () => {
    return api.get("/social-media-type/get-all-social-media-types") as Promise<
      ApiResponse<SocialMediaType[]>
    >;
  },
};

export const Addresses = {
  GetAllCountries: async () => {
    return api.get("/country/get-all-countries") as Promise<ApiResponse<CountryModel[]>>;
  },

  GetPlacesByCountryId: async (countryId: string) => {
    return api.get("/place/get-places-by-country-id/" + countryId) as Promise<ApiResponse<PlaceModel[]>>;
  },

  GetStreetsByPlaceId: async (placeId: string) => {
    return api.get("/street/get-streets-by-place-id/" + placeId) as Promise<ApiResponse<StreetModel[]>>;
  },

  PlaceHasCompany: async (placeId: string) => {
    return api.get("/place/place-has-company/" + placeId) as Promise<ApiResponse<boolean>>;
  },

  StreetHasCompany: async (streetId: string) => {
    return api.get("/street/street-has-company/" + streetId) as Promise<ApiResponse<boolean>>;
  },

  SavePlace: async (place: Place) => {
    return api.put("/place/save-place", place) as Promise<ApiResponse<Place>>;
  },

  SaveStreet: async (street: Street) => {
    return api.put("/street/save-street", street) as Promise<ApiResponse<Street>>;
  },

  RemovePlace: async (placeId: string) => {
    return api.delete("/place/remove-place/" + placeId) as Promise<ApiResponse<boolean>>;
  },

  RemoveStreet: async (streetId: string) => {
    return api.delete("/street/remove-street/" + streetId) as Promise<ApiResponse<boolean>>;
  },
}

export const Categories = {
  GetAllCategoryShortDetails: async () => {
    return api.get("/category/get-all-category-short-details") as Promise<
      ApiResponse<CategoryShortDetail[]>
    >;
  },

  SaveCategory: async (category: CategoryShortDetail) => {
    return api.put("/category/save-category", category) as Promise<ApiResponse<CategoryShortDetail>>;
  },

  CompanyHasCategory: async (categoryId: string) => {
    return api.get("/category/company-has-category/" + categoryId) as Promise<ApiResponse<boolean>>;
  },

  RemoveCategory: async (categoryId: string) => {
    return api.delete("/category/remove-category/" + categoryId) as Promise<ApiResponse<boolean>>;
  },
};

export const Advertisements = {
  GetAllBannerPhotoResolutions: async () => {
    return api.get(
      "/banner-photo-resolution/get-all-banner-photo-resolutions"
    ) as Promise<ApiResponse<BannerPhotoResolution[]>>;
  },
};

export const Auth = {
  Login: async (request: LoginRequest) => {
    return adminApi.post("/auth/login", request) as Promise<ApiResponse<LoginResponse>>;
  },

  GetAuthenticatedUser: async () => {
    return adminApi.get("/auth/get-authenticated-user/") as Promise<ApiResponse<User>>;
  },
}

export const Users = {
  RequestForgotPassword: async (request: RequestForgotPasswordModel) => {
    return adminApi.post("/accounts/request-forgot-password/", request) as Promise<ApiResponse<null>>;
  },

  ForgotPassword: async (request: ForgotPasswordModel) => {
    return adminApi.post("/accounts/forgot-password/", request) as Promise<ApiResponse<null>>;
  },

  SaveUser: async (user: User) => {
    return adminApi.put("/accounts/save/", user) as Promise<ApiResponse<User>>;
  },

  GetAllUsers: async () => {
    return adminApi.get("/accounts/") as Promise<ApiResponse<User[]>>;
  },

  SetUserActiveStatus: async (request: SetUserActiveStatusModel) => {
    return adminApi.put("/accounts/set-account-active-status/", request) as Promise<ApiResponse<null>>;
  },

  ResetPassword: async (request: ResetPasswordModel) => {
    return adminApi.put("/accounts/reset-password/", request) as Promise<ApiResponse<User>>;
  }
};