import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../../app/theme/colors";

export const useSocialMediaSectionStyles = makeStyles()((theme: Theme) => ({
  title: {
    color: colors.primary,
    fontWeight: "bold",
  },
  boxContainer: {
    background: "#F3F3F3",
    boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
    padding: "20px",
  },
  inputTitle: { color: colors.inputLabel, textAlign: "start" },
  boxWrapper: {
    backgroundColor: "white",
  },
  autocomplete: {
    backgroundColor: colors.white,
    borderRadius: "10px",
    overflow: "hidden",
    boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
  },
  facebookIcon: {
    fontSize: "30px",
    color: colors.icons.facebook,
  },
  mapsIcon: {
    fontSize: "30px",
    color: "#DB4437",
  },
  instagramIcon: {
    fontSize: "30px",
    color: colors.icons.instagram,
  },
  twitterIcon: {
    fontSize: "30px",
    color: colors.icons.twitter,
  },
  youtubeIcon: {
    fontSize: "30px",
    color: colors.icons.youtube,
  },
  linkedInIcon: {
    fontSize: "30px",
    color: colors.icons.linkedIn,
  },
  tiktokIcon: {
    fontSize: "23px",
    marginLeft: 3,
  },
  deleteIcon: {
    fontSize: 30,
    marginLeft: "10px",
    color: colors.red,
    cursor: "pointer",
  },
  controlPointIcon: {
    fontSize: 30,
    marginLeft: "10px",
    color: colors.icons.controlPointIcon,
    cursor: "pointer",
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: "5px",
  },
  sectionTitle: {
    color: colors.inputTitle,
    fontWeight: "bold",
    marginLeft: "5px",
  },
}));
