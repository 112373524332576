import { AxiosResponse } from "axios";
import { adminApi, api } from "./api";
import { setErrorNotification, setWarningNotification } from "../../slice/notificationSlice";
import i18n from "../../i18n";
import { logout } from "../../slice/authSlice";

export default function AddInterceptors(store: any) {
  const { dispatch } = store;

  // Request interceptor
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("accessToken");

      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptor
  api.interceptors.response.use(
    (res: AxiosResponse) => {
      return res.data;
    },
    (err) => {
      if (err.code === "ERR_NETWORK") {
        dispatch(setErrorNotification(i18n.t("messageKey.NetworkError")));
      } else {
        const statusCode = err?.response?.status;

        if (statusCode === 401) {
          dispatch(setWarningNotification(i18n.t("messageKey.Logout_ExpiredToken")));
          dispatch(logout());
        }
      }

      return Promise.reject(err?.response?.data);
    }
  );

  // Request interceptor
  adminApi.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("accessToken");

      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptor
  adminApi.interceptors.response.use(
    (res: AxiosResponse) => {
      return res.data;
    },
    (err) => {
      if (err.code === "ERR_NETWORK") {
        dispatch(setErrorNotification(i18n.t("messageKey.NetworkError")));
      } else {
        const statusCode = err?.response?.status;

        if (statusCode === 401) {
          dispatch(setWarningNotification(i18n.t("messageKey.Logout_ExpiredToken")));
          dispatch(logout());
        }
      }

      return Promise.reject(err?.response?.data);
    }
  );
};
