import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import {
  getAllCategoryShortDetails,
  selectCategoryShortDetails,
} from "../../slice/categorySlice";

export default function useGetAllCategoryShortDetails() {
  const dispatch = useAppDispatch();
  const categoryShortDetails = useAppSelector(selectCategoryShortDetails);

  const get = useCallback(() => {
    dispatch(getAllCategoryShortDetails());
  }, [dispatch]);

  useEffect(() => {
    get();
  }, [get]);

  return { categoryShortDetails, refetchCategoryShortDetails: get };
}
