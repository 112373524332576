import { Autocomplete, TextField } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CompanyStepper } from "../../../../models/companies/saveCompany/companyStepper";
import { useGeneralDataSectionStyles } from "../generalDataSectionStyles";

type Props = {
  company: CompanyStepper;
  setCompany: Dispatch<SetStateAction<CompanyStepper>>;
};

interface StarRatingOption {
  value: number;
  label: string;
}

const starRatings: StarRatingOption[] = [
  {
    value: 1,
    label: "1 zvjezdica",
  },
  {
    value: 2,
    label: "2 zvjezdice",
  },
  {
    value: 3,
    label: "3 zvjezdice",
  },
  {
    value: 4,
    label: "4 zvjezdice",
  },
  {
    value: 5,
    label: "5 zvjezdice",
  },
];

export default function SelectStarRating({ company, setCompany }: Props) {
  const [starRating, setStarRating] = useState<StarRatingOption | null>(
    starRatings.find((x) => x.value === company.general.numberOfStars) ?? null
  );
  const { classes } = useGeneralDataSectionStyles();

  const handleChangeStarRating = (
    _: any,
    newStarRating: StarRatingOption | null
  ) => {
    setStarRating(newStarRating);

    setCompany({
      ...company,
      general: {
        ...company.general,
        numberOfStars: newStarRating?.value ?? null,
      },
    });
  };

  useEffect(() => {
    setStarRating(starRatings.find((x) => x.value === company.general.numberOfStars) ?? null);
  }, [company]);

  return (
    <Autocomplete
      options={starRatings ?? []}
      value={starRating}
      onChange={handleChangeStarRating}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Zvjezdice"
          label=""
          variant="outlined"
          size="small"
          fullWidth
        />
      )}
      className={classes.autocomplete}
      sx={{
        boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
      }}
      id="select-star-rating"
    />
  );
}
