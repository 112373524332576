import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ClientManagementPage from "../../pages/clientManagementPage/clientManagementPage";
import DashboardPage from "../../pages/dashboardPage/dashboardPage";
import LoginPage from "../../pages/loginPage/loginPage";
import StatisticsPage from "../../pages/statisticsPage/StatisticsPage";
import UserManagementPage from "../../pages/userManagementPage/UserManagementPage";
import { selectAccessToken, selectLoginResponse } from "../../slice/authSlice";
import { useAppSelector } from "../redux/hooks";
import { routes } from "./routes";

const AppRouter = () => {
  const currentAccessToken = useAppSelector(selectAccessToken);
  const loginResponse = useAppSelector(selectLoginResponse);
  const [accessToken, setAccessToken] = useState<string | null>(currentAccessToken);
  const isAuthenticated = accessToken !== null
    && (loginResponse === null || !loginResponse.isFirstLogin);
  const notFirstLogin = loginResponse === null || !loginResponse.isFirstLogin;

  useEffect(() => {
    setAccessToken(currentAccessToken);
  }, [currentAccessToken]);

  return (
    <Routes>
      {!isAuthenticated && <Route path={routes.login} element={<LoginPage />} />}
      {isAuthenticated && <Route path={routes.dashboard} element={<DashboardPage />} />}
      {isAuthenticated && <Route path={routes.clientManagement} element={<ClientManagementPage />} />}
      {isAuthenticated && <Route path={routes.userManagement} element={<UserManagementPage />} />}
      {isAuthenticated && <Route path={routes.statistic} element={<StatisticsPage />} />}
      {notFirstLogin &&
        <Route
          path="*"
          element={<Navigate to={accessToken === null ? routes.login : routes.dashboard} />}
        />}
    </Routes>
  );
};

export default AppRouter;
