import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";
import bosniaFlag from "../../images/ba-03.png";

export const useLoginFormStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "1000px",
    left: 0,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  container: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.50)",
  },
  inputWrapper: {
    padding: "35px",
  },
  aboutText: {
    color: "white",
  },
  aboutUsImg: {
    width: "60%",
  },
  title: {
    color: colors.primary,
    fontWeight: "bold",
  },
  descTitle: {
    color: "white",
    fontWeight: "bold",
  },
  descText: {
    color: "white",
  },
  icon: {
    color: `${colors.green} !important`,
    marginRight: "10px",
    fill: "white",
  },
  formWrapper: {
    padding: "0 20px",
  },
  buttonWrapper: {
    padding: "10px 20px",
  },
  descriptionBackground: {
    background: `url(${bosniaFlag}) center/cover no-repeat`,
    backgroundSize: "80%",
  },
  descriptionWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    backgroundImage: `url(${bosniaFlag})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: colors.primary,
    backgroundPosition: "center",
    backgroundSize: "100%",
    [theme.breakpoints.down("md")]: {
      backgroundSize: "80%",
      height: "200px",
    },
  },
  logo: {
    width: "350px",
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
  },
  logoShort: {
    width: "60px",
    height: "60px",
    marginLeft: "5px",
    [theme.breakpoints.down("md")]: {
      width: "45px",
      height: "45px",
      marginLeft: "5px",
    },
  },
  logoWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
}));
