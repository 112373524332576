import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppNotification from "../../components/appNotification/AppNotification";
import { useLayoutStyles } from "./layoutStyles";

interface Props {
  children?: React.ReactNode;
}

const theme = createTheme({
  typography: {
    fontSize: 11.5,
  },
});

export default function Layout({ children }: Props) {
  const { classes } = useLayoutStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer autoClose={5000} position="bottom-left" theme="colored" />
      <div
        className={classes.container}
        style={{
          backgroundColor: "#e8edf6",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <div id="row-layout">{children}</div>
      </div>
      <AppNotification />
    </ThemeProvider>
  );
}
