import {
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { Dispatch, SetStateAction, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import BasicInput from "../../../components/basicInput/basicInput";
import RemoveIconButton from "../../../components/icons/RemoveIconButton";
import { CountryModel } from "../../../models/address/countryModel";
import { Place } from "../../../models/address/place";
import { PlaceModel } from "../../../models/address/placeModel";
import { StreetModel } from "../../../models/address/streetModel";
import {
    clearPlaces,
    clearStreets,
    getPlacesByCountryId, getStreetsByPlaceId,
    placeHasCompany as placeHasCompanyRequest,
    removePlace,
    removeStreet,
    savePlace,
    saveStreet,
    selectAllCountries,
    selectPlacesByCountryId,
    selectStreetsByPlaceId,
    streetHasCompany as streetHasCompanyRequest
} from "../../../slice/addressSlice";
import { useGeneralDataSectionStyles } from "../../clientManagement/generalDataSection/generalDataSectionStyles";
import { EmptyGuid } from "../../stepper/initialData";
import RemovePlaceModal from "../removeAddress/RemovePlaceModal";
import RemoveStreetModal from "../removeAddress/RemoveStreetModal";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    country: CountryModel | null;
    selectedCountry: CountryModel | null;
    setSelectedCountry: Dispatch<SetStateAction<CountryModel | null>>;
    selectedPlace: PlaceModel | null;
    setSelectedPlace: Dispatch<SetStateAction<PlaceModel | null>>;
    selectedStreet: StreetModel | null;
    setSelectedStreet: Dispatch<SetStateAction<StreetModel | null>>;
};

const INIT_STREET: StreetModel = {
    id: EmptyGuid,
    name: "",
    placeId: EmptyGuid,
    placeName: "",
};

const INIT_PLACE: Place = {
    id: EmptyGuid,
    name: "",
    countryId: EmptyGuid,
}

export default function AddressModal({
    isOpen,
    onClose,
    country,
    selectedCountry,
    setSelectedCountry,
    selectedPlace,
    setSelectedPlace,
    selectedStreet,
    setSelectedStreet,
}: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { classes } = useGeneralDataSectionStyles();

    const countries = useAppSelector(selectAllCountries);
    const places = useAppSelector(selectPlacesByCountryId);
    const streets = useAppSelector(selectStreetsByPlaceId);

    const [placeHasCompany, setPlaceHasCompany] = useState<boolean>(false);
    const [streetHasCompany, setStreetHasCompany] = useState<boolean>(false);

    const [place, setPlace] = useState<Place>({ ...INIT_PLACE });
    const [street, setStreet] = useState<StreetModel>({ ...INIT_STREET });

    const [isOpenRemovePlaceModal, setIsOpenRemovePlaceModal] = useState<boolean>(false);
    const [isOpenRemoveStreetModal, setIsOpenRemoveStreetModal] = useState<boolean>(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const onSavePlace = async () => {
        if (place.name.trim().length === 0 || selectedCountry === null) {
            return;
        }

        const isCreate = place.id === EmptyGuid;

        const request: Place = {
            id: place.id,
            name: place.name,
            countryId: selectedCountry.id,
        };

        const responseSavePlace = await dispatch(savePlace(request));

        if (!responseSavePlace.payload) {
            return;
        }

        const result = responseSavePlace.payload as Place;

        const responsePlacesByCountryId = await dispatch(getPlacesByCountryId(result.countryId));

        if (!responsePlacesByCountryId.payload) {
            return;
        }

        const newPlaces = responsePlacesByCountryId.payload as PlaceModel[];

        setSelectedPlace(newPlaces.find((x) => x.id === result.id) ?? null);

        setPlace({
            id: result.id,
            name: result.name,
            countryId: result.countryId,
        });

        if (!isCreate) {
            setSelectedStreet(selectedStreet);
            setStreet(selectedStreet ?? { ...INIT_STREET });
            return;
        }

        setSelectedStreet(null);
        dispatch(getStreetsByPlaceId(result.id));
        setStreet({ ...INIT_STREET, placeId: result.id });
    };

    const onSaveStreet = async () => {
        if (street.name.trim().length === 0 || selectedPlace === null) {
            return;
        }

        const request: StreetModel = {
            id: street.id,
            name: street.name,
            placeId: selectedPlace.id,
            placeName: "",
        };

        const responseSaveStreet = await dispatch(saveStreet(request));

        if (!responseSaveStreet.payload) {
            return;
        }

        const result = responseSaveStreet.payload as StreetModel;

        const responseStreetsByPlaceId = await dispatch(getStreetsByPlaceId(result.placeId));

        if (!responseStreetsByPlaceId.payload) {
            return;
        }

        const newStreets = responseStreetsByPlaceId.payload as StreetModel[];

        setSelectedStreet(newStreets.find((x) => x.id === result.id) ?? null);

        setStreet({
            id: result.id,
            name: result.name,
            placeId: result.placeId,
            placeName: "",
        });
    };

    const onRemovePlace = async () => {
        const response = await dispatch(removePlace(place.id));
        if (response.payload) {
            setSelectedPlace(null);
            setPlace({ ...INIT_PLACE });
            setSelectedStreet(null);
            setStreet({ ...INIT_STREET });

            if (selectedCountry) {
                dispatch(getPlacesByCountryId(selectedCountry.id));
            }
        }
        setIsOpenRemovePlaceModal(false);
    };

    const onRemoveStreet = async () => {
        const response = await dispatch(removeStreet(street.id));
        if (response.payload) {
            setSelectedStreet(null);
            setStreet({ ...INIT_STREET });

            if (selectedPlace) {
                dispatch(getStreetsByPlaceId(selectedPlace.id));
            }
        }
        setIsOpenRemoveStreetModal(false);
    };

    const onChangeCountry = (e: SyntheticEvent<Element, Event>, newCountry: CountryModel | null) => {
        if (newCountry) {
            setSelectedCountry(newCountry as CountryModel);
            dispatch(getPlacesByCountryId(newCountry.id));
        } else {
            setSelectedCountry(null);
            setPlaceHasCompany(false);
            setStreetHasCompany(false);
            dispatch(clearPlaces());
        }
        setSelectedPlace(null);
        setPlace({ ...INIT_PLACE });
        setSelectedStreet(null);
        setStreet({ ...INIT_STREET });
    };

    const onChangePlace = async (e: SyntheticEvent<Element, Event>, newPlace: PlaceModel | null) => {
        if (newPlace) {
            setSelectedPlace(newPlace as PlaceModel);
            dispatch(getStreetsByPlaceId(newPlace.id));
            const response = await dispatch(placeHasCompanyRequest(newPlace.id));
            setPlaceHasCompany(response?.payload === true);
        } else {
            setSelectedPlace(null);
            setPlaceHasCompany(false);
            setStreetHasCompany(false);
            dispatch(clearStreets());
        }
        setPlace({
            id: newPlace?.id ?? EmptyGuid,
            name: newPlace?.name ?? "",
            countryId: selectedCountry?.id ?? EmptyGuid,
        });
        setSelectedStreet(null);
        setStreet({
            ...INIT_STREET,
            placeId: newPlace?.id ?? EmptyGuid,
        });
    };

    const onChangeStreet = async (e: SyntheticEvent<Element, Event>, newStreet: StreetModel | null) => {
        if (newStreet) {
            const response = await dispatch(streetHasCompanyRequest(newStreet.id));
            setStreetHasCompany(response?.payload === true);
        } else {
            setStreetHasCompany(false);
        }
        setSelectedStreet(newStreet);
        setStreet({
            id: newStreet?.id ?? EmptyGuid,
            name: newStreet?.name ?? "",
            placeId: selectedPlace?.id ?? EmptyGuid,
            placeName: "",
        });
    };

    useEffect(() => {
        setSelectedCountry(country);
    }, [country]);

    return <>
        <Dialog onClose={onClose} open={isOpen} maxWidth={isMobile ? "xs" : "md"} fullWidth>
            <DialogTitle sx={{ textAlign: "center" }}>
                Uređivanje adresa
            </DialogTitle>

            <DialogContent>
                <Typography variant="h6" mt={2} mb={1}>Izaberite državu:</Typography>
                <Autocomplete
                    options={countries ?? []}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={selectedCountry}
                    onChange={onChangeCountry}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Država"
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    )}
                    className={classes.autocomplete}
                    sx={{
                        boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
                        mb: 4,
                        mt: 2,
                    }}
                    ListboxProps={{
                        sx: { maxHeight: 180, overflow: "auto" }
                    }}
                />

                <Typography variant="h6" mt={2} mb={1}>Uređivanje mjesta:</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={selectedPlace ? (isMobile ? 10 : 11) : 12}>
                        <Autocomplete
                            options={places ?? []}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={selectedPlace}
                            onChange={onChangePlace}
                            disabled={!selectedCountry}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Mjesto"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            )}
                            className={classes.autocomplete}
                            sx={{
                                boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
                                mb: 2
                            }}
                            ListboxProps={{
                                sx: { maxHeight: 180, overflow: "auto" }
                            }}
                        />
                    </Grid>

                    {selectedPlace && <Grid item xs={isMobile ? 2 : 1}>
                        <RemoveIconButton
                            onClick={() => { setIsOpenRemovePlaceModal(true) }}
                            disabled={placeHasCompany}
                            tooltipTitle="Nije moguće obrisati jer postoji kompanija koja u adresi sadrži izabrano mjesto"
                        />
                    </Grid>}
                </Grid>
                <BasicInput
                    value={place.name}
                    onChange={(e) => setPlace({ ...place, name: e.target.value })}
                    boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.3)"}
                    multiline={false}
                    label="Naziv mjesta"
                    disabled={!selectedCountry}
                />
                <Grid container justifyContent="end" sx={{ mt: 2 }}>
                    <Grid item>
                        <AppButton
                            label={place.id === EmptyGuid ? "Kreiraj novo mjesto" : "Ažuriraj mjesto"}
                            color={place.id === EmptyGuid ? colors.green : colors.primary}
                            hover={place.id === EmptyGuid ? colors.green : colors.primary}
                            disabled={place.name.trim().length === 0}
                            onClick={onSavePlace}
                        />
                    </Grid>
                </Grid>

                <Typography variant="h6" mb={1}>Uređivanje ulica:</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={selectedStreet ? (isMobile ? 10 : 11) : 12}>
                        <Autocomplete
                            options={streets ?? []}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={selectedStreet}
                            onChange={onChangeStreet}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Ulica"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            )}
                            disabled={!selectedPlace}
                            className={classes.autocomplete}
                            sx={{
                                boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
                                mb: 2,
                            }}
                            ListboxProps={{
                                sx: { maxHeight: 180, overflow: "auto" }
                            }}
                        />
                    </Grid>

                    {selectedStreet && <Grid item xs={isMobile ? 2 : 1}>
                        <RemoveIconButton
                            onClick={() => { setIsOpenRemoveStreetModal(true) }}
                            disabled={streetHasCompany}
                            tooltipTitle="Nije moguće obrisati jer postoji kompanija koja u adresi sadrži izabranu ulicu"
                        />
                    </Grid>}
                </Grid>
                <BasicInput
                    value={street.name}
                    onChange={(e) => setStreet({ ...street, name: e.target.value })}
                    boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.3)"}
                    multiline={false}
                    label="Naziv ulice"
                    disabled={!selectedPlace}
                />
                <Grid container justifyContent="end" sx={{ mt: 2 }}>
                    <Grid item>
                        <AppButton
                            label={street.id === EmptyGuid ? "Kreiraj novu ulicu" : "Ažuriraj ulicu"}
                            color={street.id === EmptyGuid ? colors.green : colors.primary}
                            hover={street.id === EmptyGuid ? colors.green : colors.primary}
                            disabled={street.name.trim().length === 0}
                            onClick={onSaveStreet}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <AppButton
                    onClick={onClose}
                    color={colors.primary}
                    hover={colors.primary}
                    label={t("common.close")}
                />
            </DialogActions>
        </Dialog>

        <RemovePlaceModal
            isOpen={isOpenRemovePlaceModal}
            onClose={() => setIsOpenRemovePlaceModal(false)}
            placeName={place.name}
            onRemove={onRemovePlace}
        />

        <RemoveStreetModal
            isOpen={isOpenRemoveStreetModal}
            onClose={() => setIsOpenRemoveStreetModal(false)}
            streetName={street.name}
            onRemove={onRemoveStreet}
        />
    </>;
}