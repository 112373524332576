import { colors } from '../../app/theme/colors';
import { IconButton, Tooltip } from '@mui/material';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';

type Props = {
    onClick: any;
    tooltipTitle?: string;
    disabled?: boolean;
};

export default function RemoveCircleIconButton({ onClick, tooltipTitle, disabled }: Props) {
    return (
        <Tooltip title={tooltipTitle}>
            <IconButton
                size="large"
                sx={{ p: 0.5, ml: 1, cursor: disabled ? "default" : "pointer" }}
                onClick={disabled ? () => { } : onClick}
            >
                <RemoveCircleTwoToneIcon
                    sx={{
                        fontSize: "1.1em",
                        color: disabled ? "lightgray" : colors.red
                    }}
                />
            </IconButton>
        </Tooltip>
    );
}