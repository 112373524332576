import { ResetPasswordModel } from "../../models/users/resetPasswordModel";
import { IsEmailValid } from "./commonValidations";

export function IsTokenValid(value: string): boolean {
    return value.trim().length > 0;
}

export function IsPasswordValid(value: string): boolean {
    return value.trim().length > 0
}

function IsBothPasswordsValid(newPassword: string, repeatPassword: string): boolean {
    return IsPasswordValid(newPassword)
        && IsPasswordValid(repeatPassword)
        && newPassword === repeatPassword;
}

export function IsForgotPasswordValid(
    value: string,
    newPassword: string,
    repeatPassword: string
): boolean {
    return IsTokenValid(value) && IsBothPasswordsValid(newPassword, repeatPassword);
}

export function IsResetPasswordValid(
    resetPassword: ResetPasswordModel,
    repeatedPassword: string
): boolean {
    return IsEmailValid(resetPassword.email)
        && IsBothPasswordsValid(resetPassword.newPassword, repeatedPassword);
}