import { LocationForSaveCompany } from "../../models/companies/saveCompany/contact/locationForSaveCompany";
import { SocialMediaForSaveCompany } from "../../models/companies/saveCompany/contact/socialMediaForSaveCompany";

function IsSocialMediaValid(socialMedia: SocialMediaForSaveCompany): boolean {
  return socialMedia.url.trim().length !== 0;
}

export function AreAllSocialMediasValid(
  socialMedias: SocialMediaForSaveCompany[]
): boolean {
  for (let i = 0; i < socialMedias.length; i++) {
    if (!IsSocialMediaValid(socialMedias[i])) {
      return false;
    }
  }

  return true;
}

function IsLocationValid(location: LocationForSaveCompany): boolean {
  return location.urlCoordinates.trim().length !== 0;
}

export function AreAllLocationsValid(
  locations: LocationForSaveCompany[]
): boolean {
  for (let i = 0; i < locations.length; i++) {
    if (!IsLocationValid(locations[i])) {
      return false;
    }
  }

  return true;
}
