import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Addresses } from "../app/redux/adminPanelApi";
import { RootState } from "../app/redux/store";
import { CountryModel } from "../models/address/countryModel";
import { StreetModel } from "../models/address/streetModel";
import { PlaceModel } from "../models/address/placeModel";
import { Place } from "../models/address/place";
import { ApiResponse } from "../models/app/apiResponse";
import { setErrorNotification, setPendingNotification, setSuccessNotification } from "./notificationSlice";
import i18n from "../i18n";
import { Street } from "../models/address/street";

type AddressState = {
  countries: CountryModel[] | null;
  places: PlaceModel[] | null;
  streets: StreetModel[] | null;
  placeHasCompany: boolean | null;
  streetHasCompany: boolean | null;
};

const initialState: AddressState = {
  countries: [],
  places: [],
  streets: [],
  placeHasCompany: null,
  streetHasCompany: null,
};

export const selectAllCountries = (state: RootState) => state.address.countries;

export const selectPlacesByCountryId = (state: RootState) => state.address.places;

export const selectStreetsByPlaceId = (state: RootState) => state.address.streets;

export const selectPlaceHasCompany = (state: RootState) => state.address.placeHasCompany;

export const selectStreetHasCompany = (state: RootState) => state.address.streetHasCompany;

export const getAllCountries = createAsyncThunk(
  "address/get_all_countries",
  async () => {
    const response = await Addresses.GetAllCountries();
    return response.data;
  }
);

export const getPlacesByCountryId = createAsyncThunk(
  "address/get_places_by_country_id",
  async (countryId: string) => {
    const response = await Addresses.GetPlacesByCountryId(countryId);
    return response.data;
  }
);


export const getStreetsByPlaceId = createAsyncThunk(
  "address/get_streets_by_place_id",
  async (placeId: string) => {
    const response = await Addresses.GetStreetsByPlaceId(placeId);
    return response.data;
  }
);

export const placeHasCompany = createAsyncThunk(
  "Address/Place_Has_Company",
  async (placeId: string) => {
    const response = await Addresses.PlaceHasCompany(placeId);
    return response.data;
  }
);

export const streetHasCompany = createAsyncThunk(
  "Address/Street_Has_Company",
  async (streetId: string) => {
    const response = await Addresses.StreetHasCompany(streetId);
    return response.data;
  }
);

export const savePlace = createAsyncThunk<
  Place | null,
  Place,
  { rejectValue: string }
>(
  "Address/Save_Place",
  async (place: Place, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.SavePlace_Pending")));
      const response = await Addresses.SavePlace(place);
      dispatch(setSuccessNotification(i18n.t("messageKey." + response.messageKey)));
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
      }
      return rejectWithValue(messageKey);
    }
  }
);

export const removePlace = createAsyncThunk<
  boolean | null,
  string,
  { rejectValue: string }
>(
  "Address/Remove_Place",
  async (placeId: string, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.RemovePlace_Pending")));
      const response = await Addresses.RemovePlace(placeId);
      dispatch(setSuccessNotification(i18n.t("messageKey." + response.messageKey)));
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
      }
      return rejectWithValue(messageKey);
    }
  }
);

export const saveStreet = createAsyncThunk<
  Street | null,
  StreetModel,
  { rejectValue: string }
>(
  "Address/Save_Street",
  async (street: StreetModel, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.SaveStreet_Pending")));
      const response = await Addresses.SaveStreet(street);
      dispatch(setSuccessNotification(i18n.t("messageKey." + response.messageKey)));
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
      }
      return rejectWithValue(messageKey);
    }
  }
);

export const removeStreet = createAsyncThunk<
  boolean | null,
  string,
  { rejectValue: string }
>(
  "Address/Remove_Street",
  async (streetId: string, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.RemoveStreet_Pending")));
      const response = await Addresses.RemoveStreet(streetId);
      dispatch(setSuccessNotification(i18n.t("messageKey." + response.messageKey)));
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
      }
      return rejectWithValue(messageKey);
    }
  }
);

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    clearPlaces: (state) => {
      state.places = initialState.places;
    },
    clearStreets: (state) => {
      state.streets = initialState.streets;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.countries = action.payload;
      })
      .addCase(getPlacesByCountryId.fulfilled, (state, action) => {
        state.places = action.payload;
      })
      .addCase(getStreetsByPlaceId.fulfilled, (state, action) => {
        state.streets = action.payload;
      })
      .addCase(placeHasCompany.fulfilled, (state, action) => {
        state.placeHasCompany = action.payload;
      })
      .addCase(streetHasCompany.fulfilled, (state, action) => {
        state.streetHasCompany = action.payload;
      });
  },
});

export const { clearPlaces, clearStreets } = addressSlice.actions;

export default addressSlice.reducer;
