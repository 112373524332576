import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../../app/theme/colors";

export const useImagesSectionStyles = makeStyles()((theme: Theme) => ({
  inputTitle: { color: colors.inputLabel, textAlign: "start" },
  boxWrapper: {
    background: "#F3F3F3",
    boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
    display: "flex",
    justifyContent: "center",
    padding: "20px" 
  },
  autocompleteTextField: {
    "& .MuiInputBase-input": {
      marginTop: "-0.2rem",
      height: "1.6rem",
    },
    "& .MuiInputBase-root": {
      height: "2.5rem",
    },
  },
  autocomplete: {
    backgroundColor: colors.white,
    borderRadius: "10px",
    overflow: "hidden",
    "& .MuiOutlinedInput-input": {
      height: "40px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
  },
  galleryImage: {
    maxWidth: "200px",
    maxHeight: "200px",
    height: "auto",
    padding: "5px",
  },
  addGalleryButton: {
    background: colors.primary,
  },
  galleryTitle:{
    color: colors.primary,
    fontWeight: "bold",
    padding: "5px",
  },
  gallerySubtitle:{
    color: colors.inputTitle,
            padding: "5px",
  },
  collectionsOutlinedIcon:{
    fontSize: "50px", color: colors.primary
  }
}));
