import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import companyReducer from "../../slice/companySlice";
import addressReducer from "../../slice/addressSlice";
import categoryReducer from "../../slice/categorySlice";
import advertisementReducer from "../../slice/advertisementSlice";
import contactReducer from "../../slice/contactSlice";
import notificationReducer from "../../slice/notificationSlice";
import authReducer from "../../slice/authSlice";
import userReducer from "../../slice/userSlice";

export const store = configureStore({
  reducer: {
    company: companyReducer,
    address: addressReducer,
    category: categoryReducer,
    advertisement: advertisementReducer,
    contact: contactReducer,
    notification: notificationReducer,
    auth: authReducer,
    user: userReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
