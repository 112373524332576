import { Button, Typography } from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createMixins";

type Props = {
  label: any;
  onClick?: any;
  startIcon?: any;
  endIcon?: JSX.Element;
  disabled?: boolean;
  color: string;
  hover?: string;
  float?: any;
  width?: string;
  variant?: any;
  mt?: string;
  ref?: any;
  component?: any;
  type?: any;
  borderRadius?: any;
  href?: any;
};

const AppButton = (props: Props) => {
  const buttonStyles: CSSProperties = {
    textTransform: "none",
    backgroundColor: "#fff",
    width: props.width,
    borderRadius: props.borderRadius ? props.borderRadius : "5px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    float: props.float,
    color: props.color,
    marginBottom: "5px",
    fontWeight: "bold",
    marginTop: props.mt,
  };

  const buttonSx = {
    "&:hover": {
      backgroundColor: props.hover,
      color: "white",
    },
    "&:disabled": {
      boxShadow: "none",
    },
    ...buttonStyles,
  };

  return (
    <Button
      ref={props.ref}
      sx={buttonSx}
      onClick={props.onClick}
      disabled={props.disabled}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      variant={props.variant}
      type={props.type}
      component={props.component}
      href={props.href}
    >
      <Typography variant="body1">{props.label}</Typography>
    </Button>
  );
};

export default AppButton;
