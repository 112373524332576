import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import {
  Box,
  Divider,
  Grid,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import BuildingIcon from "../../../components/icons/BuildingIcon";
import { PagedResult } from "../../../models/app/pagedResult";
import { CompanyForDashboard } from "../../../models/companies/companyForDashboard";
import { CompanyForDashboardRequest } from "../../../models/companies/companyForDashboardRequest";
import { getCompaniesForDashboard, selectCompanyPagedResult } from "../../../slice/companySlice";
import { useDashboardClientsTableStyle } from "./dashboardClientsTableStyles";
import FullDetailsModal from "../../modals/detailModal/FullDetailsModal";

interface TableRowProps {
  isEven: boolean;
}

const INIT_PAGE_SIZE = 20;

const INIT_PAGED_RESULT: PagedResult<CompanyForDashboard> = {
  totalCount: 0,
  pageNumber: 1,
  pageSize: INIT_PAGE_SIZE,
  totalPages: 0,
  items: [],
};

const INIT_COMPANY_DASHBOARD_REQUEST: CompanyForDashboardRequest = {
  pageNumber: 1,
  pageSize: INIT_PAGE_SIZE,
}

const ClientTableRow = styled(TableRow)<TableRowProps>(({ theme, isEven }) => ({
  backgroundColor: isEven ? "#E8EDF6" : undefined,
  borderRadius: isEven ? "8px" : undefined,
  "&:hover": {
    backgroundColor: "#4d8cf594",
    cursor: "pointer",
    "& .MuiTableCell-root": {
      color: "#fff",
    },
  },
  "& > *": {},
}));

export default function ClientList() {
  const dispatch = useAppDispatch();
  const pagedResult = useAppSelector(selectCompanyPagedResult);
  const { classes } = useDashboardClientsTableStyle();
  const pageSizes = [10, 20, 30, 40, 50];
  const [companyPagedResult, setCompanyPagedResult] =
    useState<PagedResult<CompanyForDashboard>>({ ...INIT_PAGED_RESULT });
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(getCompaniesForDashboard(INIT_COMPANY_DASHBOARD_REQUEST));
  }, []);

  useEffect(() => {
    if (!pagedResult) {
      return;
    }

    setCompanyPagedResult(pagedResult);
  }, [pagedResult]);

  const onChangePageSize = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newPageSize = +e.target.value;

    const request: CompanyForDashboardRequest = {
      pageNumber: 1,
      pageSize: newPageSize,
    };

    dispatch(getCompaniesForDashboard(request));
  };

  const onChangePageNumber = (event: ChangeEvent<unknown>, page: number): void => {
    const request: CompanyForDashboardRequest = {
      pageNumber: page,
      pageSize: companyPagedResult.pageSize
    };

    dispatch(getCompaniesForDashboard(request));
  };

  const handleOpenDetailsModal = (companyId: string) => {
    setSelectedCompanyId(companyId);
    setIsOpenDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setIsOpenDetailsModal(false);
  };

  return (
    <Grid
      container
      style={{
        display: "flex",
        backgroundColor: "white",
        padding: isMobile ? "10px" : "30px",
      }}
    >
      <Grid item xs={12} className={classes.titleWrapper}>
        <FormatListBulletedIcon className={classes.icon} />
        <Typography variant="h5" align="left" className={classes.title}>
          Lista klijenata
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid container>
        <Grid
          container
          display="flex"
          justifyContent={isMobile ? "center" : "space-between"}
          sx={{ display: companyPagedResult.items.length !== 0 ? "" : "none" }}
          my={1}
          spacing={isMobile ? 2 : 0}
          mb={isMobile ? 4 : 0}
        >
          <Grid item lg={3} md={4} sm={4} xs={12} sx={isMobile ? {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          } : {}} >
            <Typography
              variant="body1"
              sx={{ fontSize: "10px", color: "gray" }}
            >
              Rezultata po stranici
            </Typography>
            <TextField
              select
              variant="standard"
              id="demo-simple-select"
              value={companyPagedResult.pageSize}
              onChange={(e) => onChangePageSize(e)}
              InputProps={{
                disableUnderline: true,
              }}
            >
              {pageSizes.map((x, i) => (
                <MenuItem key={i} value={x}>
                  {x}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item lg={3} md={6} sm={6} xs={12} sx={{ justifyContent: isMobile ? "center" : "end" }} display="flex" alignContent="center">
            <Pagination
              sx={{ display: "flex", fontSize: "5px" }}
              size="small"
              count={companyPagedResult.totalPages}
              page={companyPagedResult.pageNumber}
              onChange={onChangePageNumber}
              siblingCount={1}
            />
          </Grid>
        </Grid>

        <Grid item lg={6} md={12} sm={12} xs={12} style={{ paddingRight: isMobile ? 0 : 10 }}>
          <Table>
            <TableBody>
              {companyPagedResult.items
                ?.slice(0, Math.ceil(companyPagedResult.items.length / 2))
                .map((x, i) => (
                  <ClientTableRow key={x.id} isEven={i % 2 === 0} onClick={() => handleOpenDetailsModal(x.id)}>
                    <TableCell>
                      <Box
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <BuildingIcon />
                      </Box>
                    </TableCell>
                    <TableCell sx={isMobile ? {} : {
                      whiteSpace: 'nowrap',
                      maxWidth: 200,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>{x.name}</TableCell>
                    {!isMobile && <>
                      <TableCell>{x.cityName}</TableCell>
                      <TableCell>{x.placeName}</TableCell>
                      <TableCell sx={{
                        whiteSpace: 'nowrap',
                        maxWidth: 200,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>{x.streetName} {x.homeNumber}</TableCell>
                    </>}
                  </ClientTableRow>
                ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12} style={{ paddingLeft: isMobile ? 0 : 10 }}>
          <Table>
            <TableBody>
              {companyPagedResult.items
                ?.slice(Math.ceil(companyPagedResult.items.length / 2))
                .map((x, i) => (
                  <ClientTableRow key={x.id} isEven={i % 2 === 0} onClick={() => handleOpenDetailsModal(x.id)}>
                    <TableCell>
                      <Box
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <BuildingIcon />
                      </Box>
                    </TableCell>
                    <TableCell sx={isMobile ? {} : {
                      whiteSpace: 'nowrap',
                      maxWidth: 200,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>{x.name}</TableCell>
                    {!isMobile && <>
                      <TableCell>{x.cityName}</TableCell>
                      <TableCell>{x.placeName}</TableCell>
                      <TableCell sx={{
                        whiteSpace: 'nowrap',
                        maxWidth: 200,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>{x.streetName} {x.homeNumber}</TableCell>
                    </>}
                  </ClientTableRow>
                ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>

      <FullDetailsModal open={isOpenDetailsModal} handleClose={handleCloseDetailsModal} companyId={selectedCompanyId} />
    </Grid>
  );
}
