export function GetFormattedDate(dateTime: string): string {
  const now = new Date(dateTime);
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");

  const result = `${year}-${month}-${day}`;

  return result;
}

export function Convert12to24Time(time12: string): string {
  const [time, modifier] = time12.split(' ');
  let [hours, minutes] = time?.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier?.toUpperCase() === 'PM') {
    hours = String(parseInt(hours, 10) + 12);
  }

  return `${hours}:${minutes}`;
};


export function Convert24to12Time(time24: string): string {
  const [hours, minutes] = time24?.split(':');
  let convertedHours = parseInt(hours, 10);
  let modifier = 'AM';

  if (convertedHours >= 12) {
    convertedHours = convertedHours === 12 ? 12 : convertedHours - 12;
    modifier = 'PM';
  }

  if (convertedHours === 0) {
    convertedHours = 12;
  }

  const formattedHours = convertedHours.toString().padStart(2, '0');
  const formattedMinutes = minutes.padStart(2, '0');

  return `${formattedHours}:${formattedMinutes} ${modifier}`;
};