import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { colors } from "../../../app/theme/colors";
import { IsEmailValid } from "../../../common/validations/commonValidations";
import { IsForgotPasswordValid, IsPasswordValid, IsTokenValid } from "../../../common/validations/userValidations";
import AppButton from "../../../components/appButton/appButton";
import BasicInput from "../../../components/basicInput/basicInput";
import { ApiResponse } from "../../../models/app/apiResponse";
import { ApiStatus } from "../../../models/app/apiStatus";
import { ForgotPasswordModel } from "../../../models/users/forgotPasswordModel";
import { RequestForgotPasswordModel } from "../../../models/users/requestForgotPasswordModel";
import {
    requestForgotPassword,
    forgotPassword as forgotPasswordRequest,
    selectForgotPasswordRequestStatus,
    selectRequestForgotPasswordRequestStatus
} from "../../../slice/userSlice";
import RemoveCircleOutlineTwoToneIcon from '@mui/icons-material/RemoveCircleOutlineTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

const INIT_EMAIL: string = "";
const INIT_VALIDATE: boolean = false;
const INIT_IS_SET_NEW_PASSWORD_DISPLAYED: boolean = false;
const INIT_FORGOT_PASSWORD: ForgotPasswordModel = {
    token: "",
    newPassword: "",
};
const INIT_REPEATED_PASSWORD: string = "";

interface PasswordValidation {
    greaterThanOrEqualTo8: boolean;
    atLeastOneUpperCase: boolean;
    atLeastOneNumber: boolean;
    atLeastOneSpecialCharacter: boolean;
    passwordsAreSame: boolean;
}

export default function ForgotPasswordModal({ isOpen, onClose }: Props) {
    const dispatch = useAppDispatch();
    const requestForgotPasswordRequestStatus = useAppSelector(selectRequestForgotPasswordRequestStatus);
    const forgotPasswordRequestStatus = useAppSelector(selectForgotPasswordRequestStatus);

    const [email, setEmail] = useState<string>(INIT_EMAIL);
    const [validate, setValidate] = useState<boolean>(INIT_VALIDATE);
    const [isSetNewPasswordDisplayed, setIsSetNewPasswordDisplayed] = useState<boolean>(INIT_IS_SET_NEW_PASSWORD_DISPLAYED);
    const [forgotPassword, setForgotPassword] = useState<ForgotPasswordModel>(INIT_FORGOT_PASSWORD);
    const [repeatedPassword, setRepeatedPassword] = useState<string>(INIT_REPEATED_PASSWORD);

    const passwordValidation: PasswordValidation = {
        greaterThanOrEqualTo8: forgotPassword.newPassword.length >= 8,
        atLeastOneUpperCase: /[A-Z]/.test(forgotPassword.newPassword),
        atLeastOneNumber: /\d/.test(forgotPassword.newPassword),
        atLeastOneSpecialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(forgotPassword.newPassword),
        passwordsAreSame: forgotPassword.newPassword.length > 0 && repeatedPassword.length > 0
            && forgotPassword.newPassword === repeatedPassword
    };

    const onChangeEmail = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let { value } = e.target;
        value = value.trim();

        setEmail(value);
    };

    const onChangeForgotPassword = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        propName: keyof ForgotPasswordModel
    ) => {
        let { value } = e.target;
        value = value.trim();

        setForgotPassword({ ...forgotPassword, [propName]: value });
    };

    const onChangeRepeatedPassword = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let { value } = e.target;
        value = value.trim();

        setRepeatedPassword(value);
    };

    const onEnterPressed = async (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            if (isSetNewPasswordDisplayed) {
                await onForgotPassword();
            } else {
                await onSendRequestForgotPassword();
            }
        }
    }

    const onClear = () => {
        setEmail(INIT_EMAIL);
        setValidate(INIT_VALIDATE);
        setIsSetNewPasswordDisplayed(INIT_IS_SET_NEW_PASSWORD_DISPLAYED);
        setForgotPassword(INIT_FORGOT_PASSWORD);
        setRepeatedPassword(INIT_REPEATED_PASSWORD);
    };

    const onSendRequestForgotPassword = async () => {
        const isValid = IsEmailValid(email)
            && requestForgotPasswordRequestStatus !== ApiStatus.Pending;

        if (!isValid) {
            setValidate(true);
            return;
        }

        const request: RequestForgotPasswordModel = {
            email: email
        };

        const response = await dispatch(requestForgotPassword(request));

        if (!response.payload) {
            return;
        }

        const result = response.payload as ApiResponse<null>;
        const succeeded = result.succeeded;

        if (!succeeded) {
            return;
        }

        setIsSetNewPasswordDisplayed(true);

        setValidate(INIT_VALIDATE);
    };

    const onForgotPassword = async () => {
        const isValid = IsForgotPasswordValid(
            forgotPassword.token,
            forgotPassword.newPassword,
            repeatedPassword
        ) && forgotPasswordRequestStatus !== ApiStatus.Pending;

        if (!isValid) {
            setValidate(true);
            return;
        }

        const request: ForgotPasswordModel = {
            token: forgotPassword.token,
            newPassword: forgotPassword.newPassword
        };

        await dispatch(forgotPasswordRequest(request));

        setValidate(INIT_VALIDATE);

        onClear();
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={isOpen} maxWidth="xs" fullWidth>
            <DialogTitle sx={{ textAlign: "center" }}>
                {isSetNewPasswordDisplayed ? "Promijenite lozinku" : "Zaboravili ste lozinku?"}
            </DialogTitle>

            <DialogContent>
                <Box my={2}>
                    <BasicInput
                        label="E-mail adresa"
                        value={email}
                        onChange={onChangeEmail}
                        onKeyDown={onEnterPressed}
                        error={validate && !IsEmailValid(email)}
                        errorMessage={"E-mail adresa nije validna."}
                        disabled={isSetNewPasswordDisplayed}
                        readOnly={isSetNewPasswordDisplayed}
                        boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.3)"}
                        multiline={false}
                    />
                </Box>

                {isSetNewPasswordDisplayed &&
                    <>
                        <Box my={2}>
                            <BasicInput
                                label="Kod"
                                value={forgotPassword.token}
                                onChange={(e) => onChangeForgotPassword(e, "token")}
                                onKeyDown={onEnterPressed}
                                error={validate && !IsTokenValid(forgotPassword.token)}
                                errorMessage={"Kod je obavezan."}
                                boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.3)"}
                                multiline={false}
                            />
                        </Box>
                        <Box my={2}>
                            <BasicInput
                                type="password"
                                label="Nova lozinka"
                                value={forgotPassword.newPassword}
                                onChange={(e) => onChangeForgotPassword(e, "newPassword")}
                                onKeyDown={onEnterPressed}
                                error={validate && !IsPasswordValid(forgotPassword.newPassword)}
                                errorMessage={"Nova lozinka je obavezna."}
                                boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.3)"}
                                multiline={false}
                            />
                        </Box>
                        <Box my={2}>
                            <BasicInput
                                type="password"
                                label="Ponovite lozinku"
                                value={repeatedPassword}
                                onChange={onChangeRepeatedPassword}
                                onKeyDown={onEnterPressed}
                                error={validate && (!IsPasswordValid(repeatedPassword)
                                    || forgotPassword.newPassword !== repeatedPassword)}
                                errorMessage={!IsPasswordValid(repeatedPassword)
                                    ? "Ponovljena lozinka je obavezna."
                                    : "Lozinke nisu iste."}
                                boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.3)"}
                                multiline={false}
                            />
                        </Box>
                        <Box my={2}>
                            {<Typography
                                variant="body1"
                                sx={{ color: passwordValidation.greaterThanOrEqualTo8 ? colors.green : colors.validationErrorMessage, display: "flex", my: 1 }}
                            >
                                {passwordValidation.greaterThanOrEqualTo8
                                    ? <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                                    : <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                                }
                                Lozinka mora imati minimum 8 karaktera.
                            </Typography>}

                            {<Typography
                                variant="body1"
                                sx={{ color: passwordValidation.atLeastOneUpperCase ? colors.green : colors.validationErrorMessage, display: "flex", my: 1 }}
                            >
                                {passwordValidation.atLeastOneUpperCase
                                    ? <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                                    : <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                                }
                                Lozinka mora imati barem jedno veliko slovo.
                            </Typography>}

                            {<Typography
                                variant="body1"
                                sx={{ color: passwordValidation.atLeastOneNumber ? colors.green : colors.validationErrorMessage, display: "flex", my: 1 }}
                            >
                                {passwordValidation.atLeastOneNumber
                                    ? <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                                    : <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                                }
                                Lozinka mora imati barem jedan broj.
                            </Typography>}

                            {<Typography
                                variant="body1"
                                sx={{ color: passwordValidation.atLeastOneSpecialCharacter ? colors.green : colors.validationErrorMessage, display: "flex", my: 1 }}
                            >
                                {passwordValidation.atLeastOneSpecialCharacter
                                    ? <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                                    : <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                                }
                                Lozinka mora imati barem jedan specijalan karakter.
                            </Typography>}

                            {<Typography
                                variant="body1"
                                sx={{ color: passwordValidation.passwordsAreSame ? colors.green : colors.validationErrorMessage, display: "flex", my: 1 }}
                            >
                                {passwordValidation.passwordsAreSame
                                    ? <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                                    : <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                                }
                                Lozinke se moraju poklapati.
                            </Typography>}
                        </Box>
                    </>
                }
            </DialogContent>

            <DialogActions>
                <Grid container justifyContent="space-between" mx={1}>
                    <Grid item>
                        <AppButton
                            onClick={onClear}
                            color={colors.sectionTitle}
                            hover={colors.sectionTitle}
                            label={"Očistite polja"}
                        />
                    </Grid>

                    <Grid item>
                        <Grid container columnSpacing={2}>
                            <Grid item>
                                <AppButton
                                    onClick={onClose}
                                    color={colors.primary}
                                    hover={colors.primary}
                                    label={"Zatvorite"}
                                />
                            </Grid>
                            <Grid item>
                                <AppButton
                                    onClick={isSetNewPasswordDisplayed ? onForgotPassword : onSendRequestForgotPassword}
                                    label={isSetNewPasswordDisplayed ? "Promijenite lozinku" : "Pošaljite zahtjev"}
                                    color={colors.green}
                                    hover={colors.green}
                                    disabled={isSetNewPasswordDisplayed
                                        ? ((validate && (
                                            !IsForgotPasswordValid(forgotPassword.token, forgotPassword.newPassword, repeatedPassword)
                                            || forgotPasswordRequestStatus === ApiStatus.Pending
                                        )) || Object.values(passwordValidation).some(x => x === false))
                                        : ((validate && (!IsEmailValid(email)) || requestForgotPasswordRequestStatus === ApiStatus.Pending))}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
