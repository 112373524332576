import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    useTheme
} from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { colors } from "../../../app/theme/colors";
import { IsEmailValid } from "../../../common/validations/commonValidations";
import AppButton from "../../../components/appButton/appButton";
import BasicInput from "../../../components/basicInput/basicInput";
import { ApiStatus } from "../../../models/app/apiStatus";
import { User } from "../../../models/users/user";
import { selectOrganizationId } from "../../../slice/authSlice";
import { getAllUsers, saveUser, selectSaveUserRequestStatus } from "../../../slice/userSlice";
import { EmptyGuid } from "../../stepper/initialData";
import { INIT_USER } from "../../userManagement/userManagementInitData";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    user: User;
    setUser: Dispatch<SetStateAction<User>>;
}

interface UserValidation {
    firstNameValid: boolean;
    lastNameValid: boolean;
    emailValid: boolean;
}

const INIT_VALIDATE: boolean = false;

export default function EditUserModal({ isOpen, onClose, user, setUser }: Props) {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const requestStatus = useAppSelector(selectSaveUserRequestStatus);
    const [validate, setValidate] = useState<boolean>(INIT_VALIDATE);
    const organizationId = useAppSelector(selectOrganizationId);

    const userValidation: UserValidation = {
        firstNameValid: user.firstName?.trim().length > 0,
        lastNameValid: user.lastName?.trim().length > 0,
        emailValid: IsEmailValid(user.email ?? "")
    };

    const isUserValid = Object.values(userValidation).every(x => x === true);

    const isCreate = user.id === EmptyGuid;

    const onChangeUser = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        propName: keyof User
    ) => {
        const { value } = e.target;

        setUser({ ...user, [propName]: value });
    };

    const onClear = () => {
        setUser(INIT_USER);
        setValidate(INIT_VALIDATE);
    };

    const onSubmit = async () => {
        const isValid = isUserValid && requestStatus !== ApiStatus.Pending;

        if (!isValid) {
            setValidate(true);
            return;
        }

        const request: User = {
            ...user,

            // Default props for this organization
            assosiationId: organizationId,
            isDeleted: false,
            roleName: "Administrator",
            rolePriority: 3,
            isMidenas: null
        };

        const response = await dispatch(saveUser(request));

        if (response.meta.requestStatus !== "fulfilled") {
            return;
        }

        await dispatch(getAllUsers());

        setValidate(INIT_VALIDATE);

        onClear();
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={isOpen} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ textAlign: "center" }}>
                {isCreate ? "Kreiranje" : "Ažuriranje"} korisničkog naloga
            </DialogTitle>

            <DialogContent>
                <Box mt={4} mb={2}>
                    <BasicInput
                        type="text"
                        label="E-mail adresa"
                        value={user.email}
                        onChange={(e) => onChangeUser(e, "email")}
                        error={validate && !userValidation.emailValid}
                        errorMessage={"E-mail nije validan."}
                        disabled={!isCreate}
                        readOnly={!isCreate}
                        boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.3)"}
                        multiline={false}
                    />
                </Box>

                <Box mb={2}>
                    <BasicInput
                        type="text"
                        label="Ime"
                        value={user.firstName}
                        onChange={(e) => onChangeUser(e, "firstName")}
                        error={validate && !userValidation.firstNameValid}
                        errorMessage={"Ime je obavezno."}
                        boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.3)"}
                        multiline={false}
                    />
                </Box>

                <Box mb={2}>
                    <BasicInput
                        type="text"
                        label="Prezime"
                        value={user.lastName}
                        onChange={(e) => onChangeUser(e, "lastName")}
                        error={validate && !userValidation.lastNameValid}
                        errorMessage={"Prezime je obavezno."}
                        boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.3)"}
                        multiline={false}
                    />
                </Box>

                <Box mb={2}>
                    <BasicInput
                        type="text"
                        label="Broj telefona"
                        value={user.phone}
                        onChange={(e) => onChangeUser(e, "phone")}
                        boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.3)"}
                        multiline={false}
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <Grid container justifyContent="space-between" mx={1}>
                    <Grid item>
                        <AppButton
                            onClick={onClear}
                            color={colors.sectionTitle}
                            hover={colors.sectionTitle}
                            label={"Očistite polja"}
                        />
                    </Grid>

                    <Grid item>
                        <Grid container columnSpacing={2}>
                            <Grid item>
                                <AppButton
                                    onClick={onClose}
                                    color={colors.primary}
                                    hover={colors.primary}
                                    label={"Zatvorite"}
                                />
                            </Grid>
                            <Grid item>
                                <AppButton
                                    onClick={onSubmit}
                                    label={isCreate ? "Kreirajte nalog" : "Ažurirajte nalog"}
                                    color={isCreate ? colors.green : colors.primary}
                                    hover={isCreate ? colors.green : colors.primary}
                                    disabled={validate && (requestStatus === ApiStatus.Pending || !isUserValid)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};