import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { getAllCompanyShortDetails, selectCompanyShortDetails } from "../../slice/companySlice";

export function useGetAllCompanyShortDetails() {
    const dispatch = useAppDispatch();
    const companyShortDetails = useAppSelector(selectCompanyShortDetails);

    const get = useCallback(() => {
        dispatch(getAllCompanyShortDetails());
    }, [dispatch]);

    useEffect(() => {
        get();
    }, [get]);

    return { companyShortDetails, refetchGetAllCompanyShortDetails: get };
}