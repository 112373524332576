import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Users } from "../app/redux/adminPanelApi";
import { RootState } from "../app/redux/store";
import i18n from "../i18n";
import { ApiResponse } from "../models/app/apiResponse";
import { ApiStatus } from "../models/app/apiStatus";
import { ForgotPasswordModel } from "../models/users/forgotPasswordModel";
import { RequestForgotPasswordModel } from "../models/users/requestForgotPasswordModel";
import { setErrorNotification, setPendingNotification, setSuccessNotification } from "./notificationSlice";
import { User } from "../models/users/user";
import { SetUserActiveStatusModel } from "../models/users/setUserActiveStatusModel";
import { ResetPasswordModel } from "../models/users/resetPasswordModel";

type UserState = {
    users: User[];

    requestStatus: {
        requestForgotPassword: ApiStatus;
        forgotPassword: ApiStatus;
        saveUser: ApiStatus;
        setUserActiveStatus: ApiStatus;
        resetPassword: ApiStatus;
    }
};

const initialState: UserState = {
    users: [],

    requestStatus: {
        requestForgotPassword: ApiStatus.Idle,
        forgotPassword: ApiStatus.Idle,
        saveUser: ApiStatus.Idle,
        setUserActiveStatus: ApiStatus.Idle,
        resetPassword: ApiStatus.Idle
    }
};

export const requestForgotPassword = createAsyncThunk<
    ApiResponse<null>,
    RequestForgotPasswordModel,
    { rejectValue: string }
>(
    "Users/Request_Forgot_Password",
    async (request: RequestForgotPasswordModel, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setPendingNotification(i18n.t("messageKey.RequestForgotPassword_Pending")));
            const response = await Users.RequestForgotPassword(request);
            dispatch(setSuccessNotification(i18n.t("messageKey." + response.messageKey)));
            return response;
        } catch (error) {
            const errorResponse = error as ApiResponse<null>;
            const messageKey = errorResponse.messageKey;
            if (messageKey) {
                dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
            }
            return rejectWithValue(messageKey);
        }
    }
);

export const forgotPassword = createAsyncThunk<
    ApiResponse<null>,
    ForgotPasswordModel,
    { rejectValue: string }
>(
    "Users/Forgot_Password",
    async (request: ForgotPasswordModel, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setPendingNotification(i18n.t("messageKey.ForgotPassword_Pending")));
            const response = await Users.ForgotPassword(request);
            dispatch(setSuccessNotification(i18n.t("messageKey." + response.messageKey)));
            return response;
        } catch (error) {
            const errorResponse = error as ApiResponse<null>;
            const messageKey = errorResponse.messageKey;
            if (messageKey) {
                dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
            }
            return rejectWithValue(messageKey);
        }
    }
);

export const saveUser = createAsyncThunk<
    User | null,
    User,
    { rejectValue: string }
>(
    "Users/Save_User",
    async (user: User, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setPendingNotification(i18n.t("messageKey.SaveAccount_Pending")));
            const response = await Users.SaveUser(user);
            dispatch(setSuccessNotification(i18n.t("messageKey." + response.messageKey)));
            return response.data;
        } catch (error) {
            const errorResponse = error as ApiResponse<null>;
            const messageKey = errorResponse.messageKey;
            if (messageKey) {
                dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
            }
            return rejectWithValue(messageKey);
        }
    }
);

export const getAllUsers = createAsyncThunk(
    "Users/Get_All_Users",
    async () => {
        const response = await Users.GetAllUsers();
        return response.data;
    }
);

export const setUserActiveStatus = createAsyncThunk<
    SetUserActiveStatusModel | null,
    SetUserActiveStatusModel,
    { rejectValue: string }
>(
    "Users/Set_User_Status",
    async (request: SetUserActiveStatusModel, { rejectWithValue, dispatch }) => {
        try {
            const pending = "messageKey." + ((request.isActive === true)
                ? "SetAccountActiveStatus_Activating"
                : "SetAccountActiveStatus_Deactivating");
            dispatch(setPendingNotification(i18n.t(pending)));
            const response = await Users.SetUserActiveStatus(request);
            dispatch(setSuccessNotification(i18n.t("messageKey." + response.messageKey)));
            return response.data;
        } catch (error) {
            const errorResponse = error as ApiResponse<null>;
            const messageKey = errorResponse.messageKey;
            if (messageKey) {
                dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
            }
            return rejectWithValue(messageKey);
        }
    }
);

export const resetPassword = createAsyncThunk<
    User | null,
    ResetPasswordModel,
    { rejectValue: string }
>(
    "Users/Reset_Password",
    async (request: ResetPasswordModel, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setPendingNotification(i18n.t("messageKey.ResetPassword_Pending")));
            const response = await Users.ResetPassword(request);
            dispatch(setSuccessNotification(i18n.t("messageKey." + response.messageKey)));
            return response.data;
        } catch (error) {
            const errorResponse = error as ApiResponse<null>;
            const messageKey = errorResponse.messageKey;
            if (messageKey) {
                dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
            }
            return rejectWithValue(messageKey);
        }
    }
);

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(requestForgotPassword.pending, (state) => {
                state.requestStatus.requestForgotPassword = ApiStatus.Pending;
            })
            .addCase(requestForgotPassword.fulfilled, (state, action) => {
                state.requestStatus.requestForgotPassword = ApiStatus.Fulfilled;
            })
            .addCase(requestForgotPassword.rejected, (state) => {
                state.requestStatus.requestForgotPassword = ApiStatus.Rejected;
            })

            .addCase(forgotPassword.pending, (state) => {
                state.requestStatus.forgotPassword = ApiStatus.Pending;
            })
            .addCase(forgotPassword.fulfilled, (state, action) => {
                state.requestStatus.forgotPassword = ApiStatus.Fulfilled;
            })
            .addCase(forgotPassword.rejected, (state) => {
                state.requestStatus.forgotPassword = ApiStatus.Rejected;
            })

            .addCase(saveUser.pending, (state) => {
                state.requestStatus.saveUser = ApiStatus.Pending;
            })
            .addCase(saveUser.fulfilled, (state, action) => {
                state.requestStatus.saveUser = ApiStatus.Fulfilled;
            })
            .addCase(saveUser.rejected, (state) => {
                state.requestStatus.saveUser = ApiStatus.Rejected;
            })

            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.users = action.payload ?? [];
            })

            .addCase(setUserActiveStatus.pending, (state) => {
                state.requestStatus.setUserActiveStatus = ApiStatus.Pending;
            })
            .addCase(setUserActiveStatus.fulfilled, (state, action) => {
                state.requestStatus.setUserActiveStatus = ApiStatus.Fulfilled;
            })
            .addCase(setUserActiveStatus.rejected, (state) => {
                state.requestStatus.setUserActiveStatus = ApiStatus.Rejected;
            })

            .addCase(resetPassword.pending, (state) => {
                state.requestStatus.resetPassword = ApiStatus.Pending;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.requestStatus.resetPassword = ApiStatus.Fulfilled;
            })
            .addCase(resetPassword.rejected, (state) => {
                state.requestStatus.resetPassword = ApiStatus.Rejected;
            });
    },
});

export const selectRequestForgotPasswordRequestStatus = (state: RootState) =>
    state.user.requestStatus.requestForgotPassword;

export const selectForgotPasswordRequestStatus = (state: RootState) =>
    state.user.requestStatus.forgotPassword;

export const selectSaveUserRequestStatus = (state: RootState) =>
    state.user.requestStatus.saveUser;

export const selectUsers = (state: RootState) => state.user.users;

export const selectSetUserActiveStatusRequestStatus = (state: RootState) =>
    state.user.requestStatus.setUserActiveStatus;

export const selectResetPasswordRequestStatus = (state: RootState) =>
    state.user.requestStatus.resetPassword;

export default userSlice.reducer;
