import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/redux/store";
import { ContactApi } from "../app/redux/adminPanelApi";
import { SocialMediaType } from "../models/contacts/socialMediaType";

type ContactState = {
  socialMediaTypes: SocialMediaType[] | null;
};

const initialState: ContactState = {
  socialMediaTypes: null,
};

export const getAllSocialMediaTypes = createAsyncThunk(
  "Contact/Get_All_Social_Media_Types",
  async () => {
    const response = await ContactApi.GetAllSocialMediaTypes();
    return response.data;
  }
);

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllSocialMediaTypes.fulfilled, (state, action) => {
      state.socialMediaTypes = action.payload;
    });
  },
});

export const selectSocialMediaTypes = (state: RootState) => state.contact.socialMediaTypes;

export default contactSlice.reducer;
