import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Companies,
  Days,
} from "../app/redux/adminPanelApi";
import { RootState } from "../app/redux/store";
import { INIT_WORKING_DAY } from "../features/stepper/initialData";
import i18n from "../i18n";
import { ApiResponse } from "../models/app/apiResponse";
import { CompanyForDashboard } from "../models/companies/companyForDashboard";
import { CompanyShortDetail } from "../models/companies/companyShortDetail";
import { BannerForSaveCompany } from "../models/companies/saveCompany/advertisement/bannerForSaveCompany";
import { BannerForSaveCompanyResponse } from "../models/companies/saveCompany/advertisement/bannerForSaveCompanyResponse";
import { TextAdvertisementForSaveCompany } from "../models/companies/saveCompany/advertisement/textAdvertisementForSaveCompany";
import { ContactSection } from "../models/companies/saveCompany/contact/contactSection";
import { DescriptionSection } from "../models/companies/saveCompany/description/descriptionSection";
import { GeneralSection } from "../models/companies/saveCompany/general/generalSection";
import { GeneralSectionResponse } from "../models/companies/saveCompany/general/generalSectionResponse";
import { DeleteGalleryPhotoCommand } from "../models/companies/saveCompany/photos/deleteGalleryPhotoCommand";
import { DeleteLogoCommand } from "../models/companies/saveCompany/photos/deleteLogoCommand";
import { GalleryPhotoForSaveCompany } from "../models/companies/saveCompany/photos/galleryPhotoForSaveCompany";
import { GalleryPhotoForSaveCompanyResponse } from "../models/companies/saveCompany/photos/galleryPhotoForSaveCompanyResponse";
import { Logo } from "../models/companies/saveCompany/photos/logo";
import { LogoForSaveCompany } from "../models/companies/saveCompany/photos/logoForSaveCompany";
import WorkingDays from "../models/days/workingDays";
import { setErrorNotification, setPendingNotification, setSuccessNotification } from "./notificationSlice";
import { CompanyForDashboardRequest } from "../models/companies/companyForDashboardRequest";
import { PagedResult } from "../models/app/pagedResult";
import { CompanyFullDetailsModel } from "../models/companies/companyDetails/companyFullDetailsModel";
import { ApiStatus } from "../models/app/apiStatus";
import { CountStatistic } from "../models/companies/countStatistic";

type CompanyState = {
  savedGeneralData: GeneralSectionResponse | null;
  savedDescriptionData: DescriptionSection | null;
  savedLogo: Logo | null;
  savedGalleryPhoto: GalleryPhotoForSaveCompanyResponse | null;
  savedBanner: BannerForSaveCompanyResponse | null;
  savedTextAdvertisement: TextAdvertisementForSaveCompany | null;
  savedContact: ContactSection | null;
  allDays: WorkingDays[] | null;
  companyPagedResult: PagedResult<CompanyForDashboard> | null;
  companyShortDetails: CompanyShortDetail[] | null;
  companyDetail: CompanyFullDetailsModel | null;
  countStatistic: CountStatistic[] | null;

  status: {
    companyDetail: ApiStatus;
  }
};

const initialState: CompanyState = {
  savedGeneralData: null,
  savedDescriptionData: null,
  savedLogo: null,
  savedGalleryPhoto: null,
  savedBanner: null,
  savedTextAdvertisement: null,
  savedContact: null,
  allDays: null,
  companyPagedResult: null,
  companyShortDetails: null,
  companyDetail: null,
  countStatistic: null,

  status: {
    companyDetail: ApiStatus.Idle,
  }
};

export const saveGeneralData = createAsyncThunk<
  GeneralSectionResponse | null,
  GeneralSection,
  { rejectValue: string }
>(
  "Company/Save_General_Data",
  async (general: GeneralSection, { rejectWithValue, dispatch }) => {
    try {
      const response = await Companies.SaveGeneralData(general);
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
      }
      return rejectWithValue(errorResponse.messageKey);
    }
  }
);

export const saveDescriptionData = createAsyncThunk<
  DescriptionSection | null,
  DescriptionSection,
  { rejectValue: string }
>(
  "Company/Save_Description_Data",
  async (description: DescriptionSection, { rejectWithValue, dispatch }) => {
    try {
      const response = await Companies.SaveDescriptionData(description);
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
      }
      return rejectWithValue(errorResponse.messageKey);
    }
  }
);

export const saveLogo = createAsyncThunk<
  Logo | null,
  LogoForSaveCompany,
  { rejectValue: string }
>(
  "Company/Save_Logo",
  async (logo: LogoForSaveCompany, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.SaveLogo_Pending")));
      const response = await Companies.SaveLogo(logo);
      dispatch(setSuccessNotification(i18n.t("messageKey." + response.messageKey)));
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
      }
      return rejectWithValue(errorResponse.messageKey);
    }
  }
);

export const deleteLogo = createAsyncThunk<
  void,
  DeleteLogoCommand,
  { rejectValue: string }
>(
  "Company/Delete_Logo",
  async (request: DeleteLogoCommand, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.DeleteLogo_Pending")));
      await Companies.DeleteLogo(request);
      dispatch(setSuccessNotification(i18n.t("messageKey.DeleteLogo_Success")));
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
      }
      return rejectWithValue(errorResponse.messageKey);
    }
  }
);

export const saveGalleryPhoto = createAsyncThunk<
  GalleryPhotoForSaveCompanyResponse | null,
  GalleryPhotoForSaveCompany,
  { rejectValue: string }
>(
  "Company/Save_Gallery_Photo",
  async (galleryPhoto: GalleryPhotoForSaveCompany, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.SaveGalleryPhotos_Pending")));
      const response = await Companies.SaveGalleryPhotos(galleryPhoto);
      dispatch(setSuccessNotification(i18n.t("messageKey." + response.messageKey)));
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
      }
      return rejectWithValue(errorResponse.messageKey);
    }
  }
);

export const deleteGalleryPhoto = createAsyncThunk<
  void,
  DeleteGalleryPhotoCommand,
  { rejectValue: string }
>(
  "Company/Delete_Gallery_Photo",
  async (request: DeleteGalleryPhotoCommand, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.DeleteGalleryPhoto_Pending")));
      await Companies.DeleteGalleryPhoto(request);
      dispatch(setSuccessNotification(i18n.t("messageKey.DeleteGalleryPhoto_Success")));
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
      }
      return rejectWithValue(errorResponse.messageKey);
    }
  }
);

export const saveBanner = createAsyncThunk<
  BannerForSaveCompanyResponse | null,
  BannerForSaveCompany,
  { rejectValue: string }
>(
  "Company/Save_Banner",
  async (banner: BannerForSaveCompany, { rejectWithValue, dispatch }) => {
    try {
      const response = await Companies.SaveBanner(banner);
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
      }
      return rejectWithValue(errorResponse.messageKey);
    }
  }
);

export const saveTextAdvertisements = createAsyncThunk<
  TextAdvertisementForSaveCompany | null,
  TextAdvertisementForSaveCompany,
  { rejectValue: string }
>(
  "Company/Save_Text_Advertisements",
  async (textAdvertisement: TextAdvertisementForSaveCompany, { rejectWithValue, dispatch }) => {
    try {
      const response = await Companies.SaveTextAdvertisements(textAdvertisement);
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
      }
      return rejectWithValue(errorResponse.messageKey);
    }
  }
);

export const saveContact = createAsyncThunk<
  ContactSection | null,
  ContactSection,
  { rejectValue: string }
>(
  "Company/Save_Contact_Data",
  async (contact: ContactSection, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.SaveCompany_Pending")));
      const response = await Companies.SaveContact(contact);
      dispatch(setSuccessNotification(i18n.t("messageKey.SaveCompany_Success")));
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
      }
      return rejectWithValue(errorResponse.messageKey);
    }
  }
);

export const getAllDays = createAsyncThunk("Company/Get_All_Days", async () => {
  const response = await Days.GetAllDays();
  return response.data;
});

export const getCompaniesForDashboard = createAsyncThunk(
  "Company/Get_Companies_For_Dasboard",
  async (request: CompanyForDashboardRequest) => {
    const response = await Companies.GetCompaniesForDashboard(request);
    return response.data;
  }
);

export const getAllCompanyShortDetails = createAsyncThunk("Company/Get_All_Company_Short_Details", async () => {
  const response = await Companies.GetAllCompanyShortDetails();
  return response.data;
});

export const getCompanyDetailForEditById = createAsyncThunk("Company/Get_Company_Detail_For_Edit_By_Id", async (id: string) => {
  const response = await Companies.GetCompanyDetailForEditById(id);
  return response.data;
});

export const removeCompany = createAsyncThunk<
  boolean | null,
  string,
  { rejectValue: string }
>("Company/Remove_Company", async (id: string, { rejectWithValue, dispatch }) => {
  try {
    dispatch(setPendingNotification(i18n.t("messageKey.RemoveCompany_Pending")));
    const response = await Companies.RemoveCompany(id);
    dispatch(setSuccessNotification(i18n.t("messageKey." + response.messageKey)));
    return response.data;
  } catch (error) {
    const errorResponse = error as ApiResponse<null>;
    const messageKey = errorResponse.messageKey;
    if (messageKey) {
      dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
    }
    return rejectWithValue(messageKey);
  }
});

export const getCompanyDetail = createAsyncThunk("Company/Get_Company_Detail", async (id: string) => {
  const response = await Companies.GetCompanyDetail(id);
  return response.data;
});

export const getCreatedCompaniesCountStatistic = createAsyncThunk("Company/Get_Created_Companies_Count_Statistic", async () => {
  const response = await Companies.GetCreatedCompaniesCountStatistic();
  return response.data;
});

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    removeBannerPhoto: (state) => {
      if (state.savedBanner?.bannerPhotoFileName) {
        state.savedBanner.bannerPhotoFileName = "";
      }

    },
    removeGalleryPhoto: (state, action: PayloadAction<string>) => {
      if (state.savedGalleryPhoto?.galleryPhotos) {
        state.savedGalleryPhoto.galleryPhotos =
          state.savedGalleryPhoto.galleryPhotos.filter(
            (x) => x.galleryPhotoFileName !== action.payload
          );
      }
    },
    removeGalleryPhotos: (state) => {
      state.savedGalleryPhoto = null;
    },
    removeLogo: (state) => {
      state.savedLogo = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveGeneralData.fulfilled, (state, action) => {
        state.savedGeneralData = action.payload;
      })
      .addCase(saveDescriptionData.fulfilled, (state, action) => {
        state.savedDescriptionData = action.payload;
      })
      .addCase(saveLogo.fulfilled, (state, action) => {
        state.savedLogo = action.payload;
      })
      .addCase(deleteLogo.fulfilled, (state, action) => {
        state.savedLogo = null;
      })
      .addCase(saveGalleryPhoto.fulfilled, (state, action) => {
        state.savedGalleryPhoto = action.payload;
      })
      .addCase(saveBanner.fulfilled, (state, action) => {
        state.savedBanner = action.payload;
      })
      .addCase(saveContact.fulfilled, (state, action) => {
        state.savedContact = action.payload;
      })
      .addCase(getAllDays.fulfilled, (state, action) => {
        const newDays: WorkingDays[] = [];
        let newDay: WorkingDays = INIT_WORKING_DAY;
        action.payload?.forEach((day) => {
          switch (day.orderNumber) {
            case 1:
              newDay = { ...day, name: "P" };
              break;
            case 2:
              newDay = { ...day, name: "U" };
              break;
            case 3:
              newDay = { ...day, name: "S" };
              break;
            case 4:
              newDay = { ...day, name: "Č" };
              break;
            case 5:
              newDay = { ...day, name: "P" };
              break;
            case 6:
              newDay = { ...day, name: "S" };
              break;
            case 7:
              newDay = { ...day, name: "N" };
              break;
          }
          newDays.push(newDay);
        });
        state.allDays = newDays;
      })
      .addCase(getCompaniesForDashboard.fulfilled, (state, action) => {
        state.companyPagedResult = action.payload;
      })
      .addCase(getAllCompanyShortDetails.fulfilled, (state, action) => {
        state.companyShortDetails = action.payload;
      })
      .addCase(getCompanyDetailForEditById.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }

        state.savedGeneralData = action.payload.general;
        state.savedDescriptionData = action.payload.description;
        state.savedLogo = action.payload.logo;
        state.savedGalleryPhoto = action.payload.gallery;
        state.savedBanner = action.payload.banner;
        state.savedTextAdvertisement = action.payload.textAdvertisement;
        state.savedContact = action.payload.contact;
      })
      .addCase(getCompanyDetail.pending, (state, action) => {
        state.status.companyDetail = ApiStatus.Pending;
      })
      .addCase(getCompanyDetail.fulfilled, (state, action) => {
        state.companyDetail = action.payload;
        state.status.companyDetail = ApiStatus.Fulfilled;
      })
      .addCase(getCompanyDetail.rejected, (state, action) => {
        state.status.companyDetail = ApiStatus.Rejected;
      })
      .addCase(getCreatedCompaniesCountStatistic.fulfilled, (state, action) => {
        state.countStatistic = action.payload;
      });
  },
});

export const { removeBannerPhoto, removeGalleryPhoto, removeGalleryPhotos, removeLogo } = companySlice.actions;

export const selectSavedGeneralData = (state: RootState) => state.company.savedGeneralData;

export const selectCompanyPagedResult = (state: RootState) => state.company.companyPagedResult;

export const selectSavedLogo = (state: RootState) => state.company.savedLogo;

export const selectSavedBannerPhotoFilePath = (state: RootState) =>
  state.company.savedBanner?.bannerPhotoFileName;

export const selectSavedGalleryPhoto = (state: RootState) =>
  state.company.savedGalleryPhoto;

export const selectCompanyShortDetails = (state: RootState) => state.company.companyShortDetails;

export const selectDays = (state: RootState) => state.company.allDays;

export const selectCompanyDetail = (state: RootState) => state.company.companyDetail;

export const selectCompanyDetailStatus = (state: RootState) => state.company.status.companyDetail;

export const selectCountStatistic = (state: RootState) => state.company.countStatistic;

export default companySlice.reducer;
