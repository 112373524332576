import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import PublicIcon from "@mui/icons-material/Public";
import {
    Avatar,
    CardMedia,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Link,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PoslovniImenikEnv } from "../../../app/redux/api";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import { getCompanyDetail, selectCompanyDetail, selectCompanyDetailStatus } from "../../../slice/companySlice";
import Gallery from "./Gallery";
import TranslateDay from "./TranslateDay";
import { useFullDetailsModalStyles } from "./useFullDetailsModalStyles";

interface Props {
    open: boolean;
    handleClose: () => void;
    companyId: string | null;
}

export default function FullDetailsModal({
    handleClose,
    open,
    companyId,
}: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { classes } = useFullDetailsModalStyles();
    const theme = useTheme();

    const companyById = useAppSelector(selectCompanyDetail);
    const fullDetailsStatus = useAppSelector(selectCompanyDetailStatus);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        if (!companyId) {
            return;
        }

        dispatch(getCompanyDetail(companyId));
    }, [companyId]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="lg"
            id="fullDetailsModal_dialog"
        >
            {fullDetailsStatus === "Pending" ? (
                <DialogContent>
                    <Grid
                        container
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <CircularProgress />
                    </Grid>
                </DialogContent>
            ) : (
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            {companyById?.logo === null ? (
                                <Grid
                                    container
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Avatar className={classes.avatar}>
                                        {companyById?.generalData.name
                                            ? companyById.generalData.name
                                                .split(" ")
                                                .slice(0, 1)
                                                .map((word) => word[0])
                                                .join("")
                                                .toUpperCase()
                                            : ""}
                                    </Avatar>
                                </Grid>
                            ) : (
                                <Grid
                                    container
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <CardMedia
                                        id="fullDetailsModal_img_logo"
                                        component="img"
                                        className={classes.cardMedia}
                                        image={PoslovniImenikEnv.Prod + "/" + companyById?.logo?.logoBase64}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Grid item md={12} xs={12} mb={1}>
                                <Typography variant="h5" className={classes.title}>
                                    {companyById?.generalData.name}
                                </Typography>
                            </Grid>
                            <Grid item md={12} display="flex" mb={1}>
                                <PublicIcon className={classes.icon} />
                                <Typography
                                    variant="body1"
                                    className={classes.label}
                                    component="a"
                                >
                                    {t("detailModal.cardAddress")}:
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className={classes.contactContentAddress}
                                    id="fullDetailsModal_address"
                                    component={"a"}
                                    href={`https://maps.google.com/maps?q=${encodeURIComponent(
                                        `${companyById?.generalData.placeName}, ${companyById?.generalData.streetName}, ${companyById?.generalData.homeNumber}`
                                    )}`}
                                    target="_blank"
                                >
                                    {companyById?.generalData.placeName},
                                    {companyById?.generalData.streetName},
                                    {companyById?.generalData.homeNumber}
                                </Typography>
                            </Grid>
                            {companyById?.phones?.map((phone: any, index: number) => (
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    display="flex"
                                    alignItems={"center"}
                                    sx={{ marginBottom: "5px" }}
                                >
                                    <Grid
                                        container
                                        key={index}
                                        alignItems="center"
                                        sx={{ marginBottom: "5px" }}
                                    >
                                        <LocalPhoneOutlinedIcon className={classes.icon} />
                                        <Typography variant="body1" className={classes.label}>
                                            {phone.typeName.charAt(0).toUpperCase() +
                                                phone.typeName.slice(1)}
                                            :
                                        </Typography>
                                        <Typography
                                            id="fullDetailsModal_value_phone-number"
                                            component="a"
                                            href={`tel:${phone.phoneNumber}`}
                                            variant="body1"
                                            className={classes.contactContent}
                                        >
                                            {phone.phoneNumber}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                            {companyById?.websites?.map((website: any, index: any) => (
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    display="flex"
                                    alignItems={"center"}
                                    sx={{ marginBottom: "5px" }}
                                >
                                    <Grid
                                        container
                                        key={index}
                                        alignItems="center"
                                        sx={{ marginBottom: "5px" }}
                                    >
                                        <OpenInNewOutlinedIcon className={classes.icon} />
                                        <Typography variant="body1" className={classes.label}>
                                            {t("detailModal.cardWeb")}:
                                        </Typography>
                                        <Typography
                                            id="fullDetailsModal_value_website"
                                            component="a"
                                            href={website.url}
                                            target="_blank"
                                            variant="body1"
                                            className={classes.contactContent}
                                        >
                                            {website.url}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                            {companyById?.emails?.map((email: any, index: number) => (
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    display="flex"
                                    alignItems={"center"}
                                    sx={{ marginBottom: "5px" }}
                                    key={index}
                                >
                                    <Grid
                                        container
                                        alignItems="center"
                                        sx={{ marginBottom: "5px" }}
                                    >
                                        <EmailOutlinedIcon className={classes.icon} />
                                        <Typography variant="body1" className={classes.label}>
                                            {email.typeName.charAt(0).toUpperCase() +
                                                email.typeName.slice(1)}
                                            :
                                        </Typography>
                                        <Link
                                            href={`mailto:${email.emailAddress}`}
                                            underline="none"
                                        >
                                            <Typography
                                                id="fullDetailsModal_value_email"
                                                variant="body1"
                                                className={classes.contactContent}
                                                component="a"
                                                href={`mailto:${email.emailAddress}`}
                                            >
                                                {email.emailAddress}
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item md={4} xs={12}>
                            {isMobile ? (
                                companyById?.workingTimes &&
                                [...companyById?.workingTimes]
                                    .sort((a: any, b: any) => a.dayOrderNumber - b.dayOrderNumber)
                                    .map((working: any, index: number) => (
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}
                                            display="flex"
                                            alignItems={"center"}
                                            mb={2}
                                        >
                                            <Grid
                                                container
                                                key={index}
                                                alignItems="center"
                                                justifyContent={"center"}
                                            >
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" className={classes.label}>
                                                        <TranslateDay day={working.dayName} />
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="body1"
                                                        style={{
                                                            display: "block",
                                                            marginBottom: "5px",
                                                        }}
                                                    >
                                                        {
                                                            working.isClosed
                                                                ? t("fullDetailsModal.cardWorkingTimesClosed")
                                                                : working.isOpen24Hours
                                                                    ? t("fullDetailsModal.cardWorkingTimesOpen")
                                                                    : `${formatTimeTo24Hours(working.fromTime)} ${t(
                                                                        "weekDays.hours"
                                                                    )} - ${formatTimeTo24Hours(working.toTime)} ${t(
                                                                        "weekDays.hours"
                                                                    )}`
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))
                            ) : (
                                <Grid container>
                                    {companyById?.workingTimes &&
                                        [...companyById?.workingTimes]
                                            .sort(
                                                (a: any, b: any) => a.dayOrderNumber - b.dayOrderNumber
                                            )
                                            .map((working: any, index: number) => (
                                                <>
                                                    {working.dayName && (
                                                        <Grid item md={6} key={index}>
                                                            <Typography
                                                                variant="body1"
                                                                className={classes.label}
                                                                style={{
                                                                    display: "block",
                                                                    marginBottom: "5px",
                                                                }}
                                                            >
                                                                <TranslateDay day={working.dayName} />
                                                            </Typography>
                                                        </Grid>
                                                    )}

                                                    {(working.fromTime ||
                                                        working.toTime ||
                                                        working.isClosed ||
                                                        working.isOpen24Hours) && (
                                                            <Grid item md={6} key={index}>
                                                                <Typography
                                                                    variant="body1"
                                                                    style={{
                                                                        display: "block",
                                                                        marginBottom: "5px",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant="body1"
                                                                        style={{
                                                                            display: "block",
                                                                            marginBottom: "5px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            working.isClosed
                                                                                ? t(
                                                                                    "detailModal.cardWorkingTimesClosed"
                                                                                )
                                                                                : working.isOpen24Hours
                                                                                    ? t("detailModal.cardWorkingTimesOpen")
                                                                                    : `${formatTimeTo24Hours(
                                                                                        working.fromTime
                                                                                    )}  ${t(
                                                                                        "common.hours"
                                                                                    )} - ${formatTimeTo24Hours(
                                                                                        working.toTime
                                                                                    )}  ${t("common.hours")}`
                                                                        }
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                </>
                                            ))}
                                </Grid>
                            )}
                        </Grid>
                        <Grid item md={12} display="flex">
                            <Typography>
                                {companyById?.description?.longDescription}
                            </Typography>
                        </Grid>
                        <Grid item md={12} display="flex">
                            <Gallery images={companyById?.photos} />
                        </Grid>
                        <Grid item md={12} display="flex">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: companyById?.altText?.text || "",
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            )}

            <DialogActions>
                <AppButton
                    onClick={handleClose}
                    color={colors.primary}
                    hover={colors.primary}
                    label={t("common.close")}
                />
            </DialogActions>
        </Dialog>
    );
}

export function formatTimeTo24Hours(time: string) {
    const timeParts = time.split(" ");
    const hoursMinutes = timeParts[0].split(":");
    let hours = parseInt(hoursMinutes[0]);
    const minutes = parseInt(hoursMinutes[1]);

    if (timeParts[1].toLowerCase() === "pm" && hours < 12) {
        hours += 12;
    } else if (timeParts[1].toLowerCase() === "am" && hours === 12) {
        hours = 0;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
};