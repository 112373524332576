import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery, useTheme } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useHeaderStyles } from "./headerStyles";
import { routes } from "../../app/router/routes";
import logo from "../../images/logoFull.png";
import { colors } from "../../app/theme/colors";
import { useAppDispatch } from "../../app/redux/hooks";
import { logout } from "../../slice/authSlice";
import { setSuccessNotification } from "../../slice/notificationSlice";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string | null;
}

type Anchor = "left";

export default function Header({ title }: Props) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { classes } = useHeaderStyles();
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleNavigation = {
    dashboard: () => navigate(routes.dashboard),
    clientManagement: () => navigate(routes.clientManagement),
    userManagement: () => navigate(routes.userManagement),
    statistic: () => navigate(routes.statistic),
    login: () => navigate(routes.login),
  };

  const onLogout = () => {
    dispatch(logout());
    dispatch(setSuccessNotification(t("messageKey.Logout_Success")));
    handleNavigation.login();
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ background: colors.primary, height: "100vh", width: "100%" }}
    >
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <List style={{ flexGrow: 1 }}>
          <ListItem button onClick={handleNavigation.dashboard}>
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardOutlinedIcon className={classes.sidebarIcon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
              Početna
            </ListItemText>
          </ListItem>
          <Divider variant="middle" className={classes.divider} />
          <ListItem button onClick={handleNavigation.clientManagement}>
            <ListItemIcon className={classes.listItemIcon}>
              <AccountCircleOutlinedIcon className={classes.sidebarIcon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
              Upravljanje klijentima
            </ListItemText>
          </ListItem>
          <Divider variant="middle" className={classes.divider} />

          <ListItem button onClick={handleNavigation.userManagement}>
            <ListItemIcon className={classes.listItemIcon}>
              <PermIdentityOutlinedIcon className={classes.sidebarIcon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
              Upravljanje korisnicima
            </ListItemText>
          </ListItem>
          <Divider variant="middle" className={classes.divider} />

          <ListItem button onClick={handleNavigation.statistic}>
            <ListItemIcon className={classes.listItemIcon}>
              <BarChartOutlinedIcon className={classes.sidebarIcon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
              Statistika
            </ListItemText>
          </ListItem>
        </List>
        <Divider variant="middle" className={classes.divider} />
        <ListItem button onClick={onLogout}>
          <ListItemIcon className={classes.listItemIcon}>
            <ExitToAppIcon className={classes.sidebarIcon} />
          </ListItemIcon>
          <ListItemText className={classes.listItemText}>Odjavi se</ListItemText>
        </ListItem>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {(["left"] as Anchor[]).map((anchor) => (
                <React.Fragment key={anchor}>
                  <MenuIcon
                    id="header_menu-icon"
                    onClick={toggleDrawer(anchor, true)}
                    className={classes.menuIcon}
                    style={{ marginRight: "16px" }}
                  />
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
              <Typography variant="h6">Administracija</Typography>
            </div>
            <img
              src={logo}
              alt="Logo"
              style={{
                height: isMobile ? "29px" : "46px",
                width: isMobile ? "170px" : "270px",
              }}
            />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
