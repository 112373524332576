import { EmptyGuid } from "../../features/stepper/initialData";
import { BannerForSaveCompany } from "../../models/companies/saveCompany/advertisement/bannerForSaveCompany";
import { TextAdvertisement } from "../../models/companies/saveCompany/advertisement/textAdvertisement";

export function AreAllTextAdvertisementsValid(
  textAdvertisements: TextAdvertisement[]
): boolean {
  for (let i = 0; i < textAdvertisements.length; i++) {
    if (!IsTextAdValid(textAdvertisements[i])) {
      return false;
    }
  }

  return true;
}

export function IsBannerUrlValid(banner: BannerForSaveCompany): boolean {
  return banner.url.trim().length > 0;
}

export function IsBannerResolutionValid(banner: BannerForSaveCompany): boolean {
  return banner.resolutionId.trim().length > 0
    && banner.resolutionId.trim() !== EmptyGuid;
}

export function IsBannerPhotoValid(banner: BannerForSaveCompany, bannerPhoto: string): boolean {
  return banner.bannerPhotoFile !== undefined || bannerPhoto.trim().length > 0;
}

export function IsBannerCompanyValid(banner: BannerForSaveCompany): boolean {
  return banner.companyId.trim().length > 0
    && banner.companyId.trim() !== EmptyGuid;
}

export function IsBannerValid(banner: BannerForSaveCompany, bannerPhoto: string): boolean {
  if ((!IsBannerUrlValid(banner) && !IsBannerResolutionValid(banner)
    && !IsBannerPhotoValid(banner, bannerPhoto))
    || (IsBannerUrlValid(banner) && IsBannerResolutionValid(banner)
      && IsBannerPhotoValid(banner, bannerPhoto))) {
    return true;
  }

  return false;
}

export function IsTextAdTitleValid(textAd: TextAdvertisement): boolean {
  return textAd.title.trim().length > 0;
}

export function IsTextAdDescriptionValid(textAd: TextAdvertisement): boolean {
  return textAd.description.trim().length > 0;
}

export function IsTextAdFromDateValid(textAd: TextAdvertisement): boolean {
  return textAd.fromDate !== null && textAd.fromDate.trim().length > 0;
}

export function IsTextAdToDateValid(textAd: TextAdvertisement): boolean {
  return textAd.toDate !== null && textAd.toDate.trim().length > 0;
}

export function IsTextAdValid(textAd: TextAdvertisement): boolean {
  const allValid = (IsTextAdTitleValid(textAd)
    && IsTextAdDescriptionValid(textAd)
    && IsTextAdFromDateValid(textAd)
    && IsTextAdToDateValid(textAd));

  const allEmpty = (!IsTextAdTitleValid(textAd)
    && !IsTextAdDescriptionValid(textAd)
    && !IsTextAdFromDateValid(textAd)
    && !IsTextAdToDateValid(textAd));

  return allValid || allEmpty;
}