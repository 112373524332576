import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../app/theme/colors";

export const useBasicInputStyles = makeStyles()((theme: Theme) => ({
  inputTitle: { textAlign: "start" },
  input: {
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
    alignContent: "center",
    alignItems: "center",
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 10px) scale(1)",
    },
    "& .MuiOutlinedInput-root": {
      display: "flex",
      alignItems: "center",
    },
  },
  formControl: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: colors.sectionTitle,
      },
    },
  },
  formControlError: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: colors.validationErrorMessage,
      },
    },
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderColor: colors.validationErrorMessage,
      },
    },
  },
  errorMessage: {
    textAlign: "start",
    color: colors.validationErrorMessage,
    fontSize: 12,
    marginLeft: 5,
  },
  requiredSymbolError: {
    color: colors.validationErrorMessage,
  },
  characterCounter: {
    position: "absolute",
    bottom: 0,
    right: 0,
    fontSize: "0.75rem",
    color: "#999",
    marginRight: "14px",
    marginBottom: "8px",
  },
}));
