import { useTranslation } from "react-i18next";

const TranslateDay = ({ day }: any) => {
  const { t } = useTranslation();

  const translateDay = (day: any) => {
    switch (day) {
      case "Monday":
        return t("common.monday");
      case "Tuesday":
        return t("common.tuesday");
      case "Wednesday":
        return t("common.wednesday");
      case "Thursday":
        return t("common.thursday");
      case "Friday":
        return t("common.friday");
      case "Saturday":
        return t("common.saturday");
      case "Sunday":
        return t("common.sunday");
      default:
        return day;
    }
  };

  return translateDay(day);
};

export default TranslateDay;