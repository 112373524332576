import { Delete } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { colors } from "../../app/theme/colors";

type Props = {
  onClick: any;
  disabled?: boolean;
  tooltipTitle?: string;
};

export default function RemoveIconButton({ onClick, disabled, tooltipTitle }: Props) {
  return (
    <Tooltip title={!disabled ? "" : tooltipTitle} hidden={!disabled}>
      <IconButton
        size="large"
        sx={{ p: 0.5, ml: 1, cursor: disabled ? "default" : "pointer" }}
        onClick={disabled ? () => { } : onClick}
      >
        <Delete
          sx={{
            fontSize: "1.1em",
            color: disabled ? "lightgray" : colors.red
          }}
        />
      </IconButton>
    </Tooltip>
  );
}
