import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../../app/theme/colors";

export const useDashboardClientsTableStyle = makeStyles()((theme: Theme) => ({
  titleWrapper: {
    bgcolor: "white",
    fontWeight: "bold",
    color: colors.primary,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  icon: {
    fontSize: "2.5rem",
  },
  title: {
    marginLeft: "10px",
    fontWeight: "bold",
  },
  leftSideTable:{
    paddingRight: "10px" 
  },
  rightSideTable:{
    paddingLeft: "10px"
  },
  divider:{
    background: colors.primary,
    opacity: 0.4
  }
}));
